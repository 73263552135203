import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "uikit";

import { useTypedSelector } from "../../redux/store";
import { NavigationLayout } from "../../components/common";
import { ROUTES } from "../../constants/routes";
import { hasAccess, generateAccessName, AccessKey } from "../../access";

const Settings: React.FC = () => {
	const ACCESS_SECTION = AccessKey.SETTINGS;
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const links: NavigationLayout.ILink[] = useMemo(
		() =>
			[
				{
					text: t(`settings.orders.title`),
					Image: () => <Icon id="order-settings" size={90} />,
					link: ROUTES.SETTINGS_ORDERS,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.SETTINGS_ORDERS,
					),
				},
				{
					text: t(`settings.tariffs.title`),
					Image: () => <Icon id="tariffs" size={90} />,
					link: ROUTES.SETTINGS_TARIFFS,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.SETTINGS_TARIFFS,
					),
				},
				{
					text: t(`settings.finances.title`),
					Image: () => <Icon id="finances" size={90} />,
					link: ROUTES.SETTINGS_FINANCES,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.SETTINGS_FINANCES,
					),
				},
				{
					text: t(`settings.messages.title`),
					Image: () => <Icon id="message-settings" size={90} />,
					link: ROUTES.SETTINGS_MESSAGES,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.SETTINGS_MESSAGES,
					),
				},
				{
					disabled: false,
					text: t(`settings.mobileApps.title`),
					Image: () => <Icon id="client-app-settings" size={90} />,
					link: ROUTES.SETTINGS_MOBILE_APPS,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.SETTINGS_MOBILE_APPS,
					),
				},
				{
					// disabled: true,
					text: t(`settings.parameters.title`),
					Image: () => <Icon id="settings" size={90} />,
					link: ROUTES.SETTINGS_PARAMETERS,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.SETTINGS_PARAMETERS,
					),
				},
				{
					disabled: true,
					text: t(`settings.map.title`),
					Image: () => <Icon id="map-settings" size={90} />,
					link: ROUTES.SETTINGS_MAP,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.SETTINGS_MAP,
					),
				},
				{
					// disabled: true,
					text: t(`settings.orderExchange.title`),
					Image: () => <Icon id="order-exchange" size={90} />,
					link: ROUTES.SETTINGS_ORDER_EXCHANGE,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.SETTINGS_ORDER_EXCHANGE,
					),
				},
				{
					text: t(`settings.paymentSystems.title`),
					Image: () => <Icon id="payment-systems" size={90} />,
					link: ROUTES.SETTINGS_PAYMENT_SYSTEMS,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.SETTINGS_PAYMENT_SYSTEMS,
					),
				},
				{
					text: t(`settings.interface.title`),
					Image: () => <Icon id="interface-settings" size={90} />,
					link: ROUTES.SETTINGS_INTERFACE,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.SETTINGS_INTERFACE,
					),
				},
				{
					disabled: true,
					text: t(`settings.voip.title`),
					Image: () => <Icon id="voip-settings" size={90} />,
					link: ROUTES.SETTINGS_VOIP,
					accessName: generateAccessName(
						ACCESS_SECTION,
						AccessKey.SETTINGS_VOIP,
					),
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[ACCESS_SECTION, personalRole, t],
	);

	return <NavigationLayout links={links} />;
};

export default Settings;
