import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useState,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { react } from "uikit";

import SelectWithModal from "../../../../../../../../../../components/SelectWithModal";
import { MultiSelectCarBaseTypeIdWithModal } from "../../../../../../../../../../components/common/Modal";

import InternalController from "./Controller";

const CarBaseTypeSelect = react.withController<
	CarBaseTypeSelect.Props,
	CarBaseTypeSelect.Controller
>(
	({
		controller,

		value,

		disabled,
		valueSearch,
		saveSearch,
		onChange,
		saveIds,
	}) => {
		const { t } = useTranslation();
		const [error, setError] = useState(false);

		const selectOnChange = useCallback(
			(newValue: SelectWithModal.Value<string | number>) => {
				setError(false);

				onChange(newValue as CarBaseTypeSelect.Value);
			},
			[onChange],
		);

		controller.setContext({ value, setError });

		return (
			<MultiSelectCarBaseTypeIdWithModal
				value={value}
				typeValue="label"
				disabled={disabled}
				error={disabled ? false : error}
				onChange={selectOnChange}
				titleText={
					t(
						"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.carBaseTypeId.str0",
					) ?? ""
				}
				subscribeOptions={undefined}
				modalSetting={{
					zIndex: 999,
					containerId: "root",
				}}
				sort={{ sortType: "asc" }}
				valueSearch={valueSearch}
				saveSearch={saveSearch}
				saveIds={saveIds}
			/>
		);
	},
	InternalController,
);

declare namespace CarBaseTypeSelect {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = string | undefined;

	interface PropsBase {
		value: Value;

		disabled: boolean;

		onChange: Dispatch<Value>;
		valueSearch: string;
		saveSearch: (value: string) => void;
		saveIds: (value: number[]) => void;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default CarBaseTypeSelect;
