import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { Button, MultiSelect, Icon, InputGroup, theme } from "uikit";
import { useDebouncedCallback } from "use-debounce/lib";

import searchService from "../../../../../../../../redux/services/Preferences/Services/searchService";
import { useTypedSelector } from "../../../../../../../../redux/store";
import SearchIconBorders from "../../../../../../../../components/SearchIconBorders";
import SearchTextBox from "../../../../../../../../components/SearchTextBox";
import {
	StyledRow,
	LanguageSelect,
} from "../../../../../../../../components/common";
import {
	generateAccessName,
	AccessKey,
	hasAccess,
} from "../../../../../../../../access";
import {
	TabKeys,
	ARR_ACCESS_PART_KEY,
} from "../../../../../../constants/access";

const StyledRoot = styled.div`
	padding: 20px 24px 0;
	width: 100%;
	display: flex;
	gap: 10px;
	justify-content: space-between;
	align-items: center;
`;

const StyledInputGroup = styled(InputGroup.InputGroup)`
	width: 280px !important;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomSelect = ({ title, value, onSelect, onUnselect, options }) => (
	<MultiSelect
		style={{ width: "200px" }}
		value={value}
		options={options}
		onUnselect={(option) => onUnselect(option?.value)}
		onSelect={(option) => {
			onSelect(option?.value);
		}}
	/>
);

export default function Header({
	handleOpenModal,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	setFilterWithActive,
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	filterWithActive,
	filter,
	setFilter,
	onDelete,
	onEdit,
	notSelected,
	setLanguageFilter,
	languageFilter,
}) {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const [query, setQuery] = useState("");

	const companies = useTypedSelector(
		(state) => state.ordersPageReducer.companies,
	);
	const companyWrap = useCallback(
		(company) => ({
			key: company?.id,
			value: company?.id,
			label: company?.name?.[languageFilter],
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[companies],
	);

	const companiesOptions = useMemo(
		() => companies.items.map((company) => companyWrap(company)),
		[companyWrap, companies],
	);
	const taxiServiceWrap = useCallback(
		(service) => ({
			key: service?.id,
			value: service?.id,
			label: service?.settlement?.[languageFilter],
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[companies],
	);
	const taxiServiceOptions = useMemo(() => {
		const getData = (data) =>
			data
				.map((item) =>
					companies.items
						.find((company) => item === company.id)
						?.taxiServices.map((service) =>
							taxiServiceWrap(service),
						),
				)
				.flat();

		return filter.companyIds[0] === -1
			? getData(companiesOptions.map((item) => item.value))
			: getData(filter.companyIds);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter.companyIds, companies]);

	const taxiServiceIds = filter.taxiServices;

	useEffect(() => {
		setFilter({ ...filter, taxiServices: taxiServiceIds });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taxiServiceIds]);

	useEffect(() => {
		setFilter({
			...filter,
			allCompanies: companiesOptions.map((item) => item.value),
			allTaxiServices: taxiServiceOptions.map((item) => item.value),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companies, filter.companyIds]);

	const debounceSearch = useDebouncedCallback((value) => {
		dispatch(searchService(value));
	}, 300);

	const AccessBtnGroup = useMemo(() => {
		const ACCESS_TAB = ARR_ACCESS_PART_KEY[TabKeys.SERVICES];

		const retval = {
			add: hasAccess(
				generateAccessName(...ACCESS_TAB, AccessKey.CONTROL_BUTTON_ADD),
				personalRole,
			),
			edit: hasAccess(
				generateAccessName(
					...ACCESS_TAB,
					AccessKey.CONTROL_BUTTON_EDIT,
				),
				personalRole,
			),
			delete: hasAccess(
				generateAccessName(
					...ACCESS_TAB,
					AccessKey.CONTROL_BUTTON_DELETE,
				),
				personalRole,
			),
		};

		return retval;
	}, [personalRole]);

	return (
		<StyledRoot>
			<StyledRow gap="10px">
				{AccessBtnGroup.add && (
					<Button.Button
						icon={
							<Icon
								id="plus"
								size={16}
								colors={[theme.colors.white]}
							/>
						}
						onClick={() => {
							handleOpenModal();
						}}
					/>
				)}
				{AccessBtnGroup.edit && (
					<Button.Button
						variant="secondary"
						icon={<Icon id="pencil" size={20} />}
						disabled={!notSelected}
						onClick={() => onEdit()}
					/>
				)}
				{AccessBtnGroup.delete && (
					<Button.Button
						variant="secondary"
						icon={<Icon id="trash" size={20} />}
						disabled={!notSelected}
						onClick={() => onDelete()}
					/>
				)}
			</StyledRow>
			<StyledRow gap="10px">
				<StyledRow gap="10px" justify="space-between">
					<CustomSelect
						title={
							t(
								"pages.preferencesPages.screenDirectory.services.header.str0",
							) ?? ""
						}
						options={[
							{
								key: -1,
								value: -1,
								label:
									t(
										"pages.preferencesPages.screenDirectory.services.header.str200",
									) ?? "",
							},
							...companiesOptions,
						]}
						value={
							filter.companyIds.length ? filter.companyIds : [-1]
						}
						onUnselect={(value) => {
							const filteredData = filter.companyIds.filter(
								(v) => v !== value,
							);
							setFilter({
								...filter,
								companyIds: filteredData.length
									? filteredData
									: [-1],
							});
						}}
						onSelect={(value) => {
							if (value === -1) {
								setFilter({
									...filter,
									companyIds: [-1],
								});
							} else {
								setFilter({
									...filter,
									companyIds: [
										...filter.companyIds,
										value,
									].filter((item) => item !== -1),
								});
							}
						}}
					/>
					<CustomSelect
						title={
							t(
								"pages.preferencesPages.screenDirectory.services.header.str1",
							) ?? ""
						}
						options={[
							{
								key: -1,
								value: -1,
								label:
									t(
										"pages.preferencesPages.screenDirectory.services.header.str200",
									) ?? "",
							},
							...taxiServiceOptions,
						]}
						value={taxiServiceIds.length ? taxiServiceIds : [-1]}
						onUnselect={(value) => {
							const filteredData = taxiServiceIds.filter(
								(v) => v !== value,
							);
							setFilter({
								...filter,
								taxiServices: filteredData.length
									? filteredData
									: [-1],
							});
						}}
						onSelect={(value) => {
							if (value === -1) {
								setFilter({
									...filter,
									taxiServices: [-1],
								});
							} else {
								setFilter({
									...filter,
									taxiServices: [
										...taxiServiceIds,
										value,
									].filter((item) => item !== -1),
								});
							}
						}}
					/>
				</StyledRow>
				<LanguageSelect
					onSelect={(value) => setLanguageFilter(value.key)}
					value={languageFilter}
				/>
				<StyledInputGroup sizes="auto 280px">
					<SearchIconBorders>
						<Icon
							id="search"
							size={16}
							colors={[theme.colors.disabled_text]}
						/>
					</SearchIconBorders>
					<SearchTextBox
						value={query}
						onChange={(value) => {
							setQuery(value);
							debounceSearch(value);
						}}
						placeholder={
							t(
								"pages.preferencesPages.screenDirectory.services.header.str204",
							) ?? ""
						}
					/>
				</StyledInputGroup>
				<Button.Button
					variant="secondary"
					icon={<Icon id="filters" size={20} />}
					onClick={() => {}}
				/>
			</StyledRow>
		</StyledRoot>
	);
}
