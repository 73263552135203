import React from "react";

import { Cell, CellProps, RowDataType } from "rsuite-table";

interface IProps extends CellProps<RowDataType> {
	[key: string]: any;
}

const MyCell = React.forwardRef((props: IProps) => {
	const {
		children,
		rowData,
		handleEdit,
		handleClick = () => {},
		...rest
	} = props;

	return (
		<Cell
			rowData={rowData}
			onClick={() => {
				handleClick(rowData);
			}}
			onDoubleClick={() => {
				handleEdit(rowData);
			}}
			{...rest}
		>
			{children}
		</Cell>
	);
});

export default MyCell;
