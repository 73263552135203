import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { LatLngLiteral } from "leaflet";
import { useInternal } from "uikit";
import { compact } from "lodash";

import { useTypedDispatch, useTypedSelector } from "../../../../../redux/store";
import orderPage from "../../../../../redux/reducers/OrdersPage";
import Search from "../../../../../services/Map";

export interface SearchQuery {
	street?: string;
	streetType?: string;
	settlement?: string;
	settlementType?: string;
	house?: string;
}

export interface UseGetCoordinatesByHouse {
	response: Search.Search.Object | null;
	setResponse: Dispatch<SetStateAction<Search.Search.Object | null>>;
	coordinates: LatLngLiteral | null;
	get: (
		data: Partial<Search.Search.Object> &
			SearchQuery & {
				coordinates: LatLngLiteral;
				houses?: Search.Search.Object["houses"];
			},
		houseQuery: string,
	) => Promise<any>;
	reset: () => void;
	house: string | null;
	setHouse: Dispatch<SetStateAction<string | null>>;
	searchHouse: (
		house: string,
		houses: Search.Search.Object["houses"],
	) => string | undefined;

	searchQuery: (data: SearchQuery) => string;
}

export type UseGetCoordinatesByHouseProps = () => UseGetCoordinatesByHouse;

/**
 *  `useGetCoordinatesByHouse`
 */
export const useGetCoordinatesByHouse: UseGetCoordinatesByHouseProps = () => {
	const dispatch = useTypedDispatch();
	const language = useTypedSelector((state) => state.session.language);
	const { searchRadiusMeters: searchRadius } = useTypedSelector(
		(state) => state.settings.map,
	);

	const [coordinates, setCoordinates] = useInternal<LatLngLiteral | null>(
		null,
	);
	const [response, setResponse] = useInternal<Search.Search.Object | null>(
		null,
	);
	const [house, setHouse] = useInternal<string | null>(null);

	const searchQuery = useCallback<UseGetCoordinatesByHouse["searchQuery"]>(
		(data) => {
			const settlement = data.settlement
				? `${data.settlement.trim()}, `
				: "";
			const settlementType = data.settlementType
				? `${data.settlementType.trim()} `
				: "";
			const street = data.street ? `${data.street.trim()}, ` : "";
			const streetType = data.streetType
				? `${data.streetType.trim()} `
				: "";
			const house = data.house ? ` ${data.house.trim()}` : "";

			return `${settlementType}${settlement}${streetType}${street}${house}`;
		},
		[],
	);

	const searchHouse = useCallback<UseGetCoordinatesByHouse["searchHouse"]>(
		(house: string, houses: Search.Search.Object["houses"]) => {
			// eslint-disable-next-line no-bitwise
			const evenOrOdd = (num: number) => (Number(num) & 1) === 0;

			const searchedItems = houses?.map((house) => house.number) || [];
			const number = compact([...searchedItems])
				.map((item) => {
					const exist = item.match(/\d+/);
					if (exist) return exist[0];
					return "";
				})
				.find((item) => {
					const exist = house.match(/\d+/);
					if (exist && exist[0]) {
						const home = Number(exist[0]);
						const isEvenOrOddHome = evenOrOdd(home);
						const isEvenOrOddItem = evenOrOdd(Number(item));

						if (isEvenOrOddHome === isEvenOrOddItem) {
							return Number(item) >= home;
						}

						return false;
					}
					return item.includes(house);
				});

			return number;
		},
		[],
	);

	const get = useCallback<UseGetCoordinatesByHouse["get"]>(
		async (data, houseQuery) => {
			const optionsType: Search.Search.Type[] = [
				"favorite",
				"localObject",
				"object",
			];

			if (data && houseQuery) {
				setHouse(houseQuery);
				if (data.type && optionsType.includes(data.type)) {
					setResponse(data as Search.Search.Object);
					return;
				}

				const exist = data.houses?.find(
					(house) => house.number === houseQuery,
				);

				const searchOptions: Search.Search.Options = {
					language,
					searchType: "address",
					country: "ua",
					// country: "md",
					near: {
						point: data.coordinates,
						radius: searchRadius,
					},
				};
				setCoordinates(data.coordinates);
				if (!exist) {
					const nextHouse = searchHouse(houseQuery, data.houses);
					const query = searchQuery({
						...data,
						house: nextHouse || houseQuery,
					});
					const result = await Search.search(query, searchOptions);

					if (result.objects.length) {
						const houseData = result.objects.find(
							(item) => item.number === nextHouse,
						);

						const item = result.objects[0];

						setResponse(houseData || item);
						setCoordinates(
							houseData?.coordinates || item.coordinates,
						);
					}
				} else {
					const query = searchQuery({
						...data,
						house: houseQuery,
					});
					const result = await Search.search(query, searchOptions);

					if (result.objects.length) {
						const houseData = result.objects.find(
							(item) => item.number === houseQuery,
						);

						if (houseData) {
							setResponse(houseData);
							setCoordinates(houseData.coordinates);
						}
					}
				}
			}

			if (data?.type && optionsType.includes(data.type) && !houseQuery) {
				setResponse(data as Search.Search.Object);
				setCoordinates(data.coordinates);
			}
		},

		[
			language,
			searchHouse,
			searchQuery,
			searchRadius,
			setCoordinates,
			setHouse,
			setResponse,
		],
	);

	useMemo(() => {
		if (coordinates) dispatch(orderPage.actions.setMapCenter(coordinates));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [coordinates]);

	const reset = useCallback(() => {
		setResponse(null);
	}, [setResponse]);

	return useMemo<UseGetCoordinatesByHouse>(
		() => ({
			house,
			response,
			coordinates,
			reset,
			get,
			setHouse,
			setResponse,
			searchHouse,
			searchQuery,
		}),
		[
			house,
			response,
			coordinates,
			reset,
			get,
			setHouse,
			setResponse,
			searchHouse,
			searchQuery,
		],
	);
};
