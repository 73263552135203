/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import { LatLngLiteral } from "leaflet";
import { clone, defaults, isEqual } from "lodash";

import ModelService from "../../redux/services/ModelService";
import sectors from "../../redux/reducers/sectors";
import { getPRPC } from "../../hooks/usePRPC";
import createRPCQuery from "../../utils/createRPCQuery.util";
import createLogger from "../../utils/logger.util";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import { Language } from "..";

import { destroyOne } from "./utils";

const logger = createLogger({ name: "Sector" });

class Sector extends ModelService<
	Sector.SubscribeOptions,
	Sector.Model,
	"sectors"
>(sectors, (state) => state.sectors) {
	static defaultSharedOptions: Sector.SharedOptions = {
		deprecate: true,
	};

	static fromResponse(data: any): Sector.Model {
		return {
			id: data.id,

			taxiServiceId: data.taxiService.id,

			position: new Date(data.position),
			name: data.name,
			vertices: data.vertices[0].slice(0, -1),
			isMaxSpeedEnabled: data.isMaxSpeedEnabled,
			maxSpeed: data.maxSpeed,

			active: data.active,
			availableForExecutor: data.availableForExecutor,
			isParking: data.isParking,
			isPriceZone: data.isPriceZone,

			isAverageSpeedEnabled: data.isAverageSpeedEnabled || false,
			averageSpeed: data.averageSpeed
				? data.averageSpeed?.map((item, i) => {
						const id = i + 1;
						return { ...(item || {}), id };
				  })
				: [],

			isBasicAverageSpeedEnabled:
				data.additionalFields?.isBasicAverageSpeedEnabled || false,
			basicAverageSpeed: data.basicAverageSpeed
				? data.basicAverageSpeed?.map((item, i) => {
						const id = i + 1;
						return { ...(item || {}), id };
				  })
				: [],

			createdAt: "",
			updatedAt: "",
			deletedAt: null,
		};
	}

	static toRequest(model: Sector.Model.New | Sector.Model.Modified): any {
		const vertices = clone(model.vertices);

		if (vertices && !isEqual(vertices[vertices.length - 1], vertices[0]))
			vertices.push(vertices[0]);

		const averageSpeed = model.averageSpeed?.map(
			({ start, end, speed, dow }) => ({
				start,
				end,
				speed,
				dow,
			}),
		);

		const data = {
			taxiServiceId: model.taxiServiceId,

			position: model.position,
			name: model.name,
			vertices,
			isMaxSpeedEnabled: model.isMaxSpeedEnabled,
			maxSpeed: model.maxSpeed <= 0 ? 60 : model.maxSpeed,

			active: model.active,
			availableForExecutor: model.availableForExecutor,
			isParking: model.isParking,
			isPriceZone: model.isPriceZone,

			isAverageSpeedEnabled: model.isAverageSpeedEnabled || false,
			averageSpeed,
			additionalFields: {
				isBasicAverageSpeedEnabled:
					model.isBasicAverageSpeedEnabled || false,
			},
		};
		logger.info("[Sector] toRequest", { data });

		return data;
	}

	static async store(
		object: Sector.Model.New,
		options?: Sector.StoreOptions,
	) {
		options = defaults(options, Sector.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		logger.info("[Sector] store", object);

		await createRPCQuery(() =>
			prpc.theirsModel.sector.create(Sector.toRequest(object)),
		);

		if (options.deprecate) Sector.deprecateAll();
	}

	static async update(
		object: Sector.Model.Modified,
		options?: Sector.UpdateOptions,
	) {
		options = defaults(options, Sector.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		logger.info("[Sector] update", object);
		await createRPCQuery(() =>
			prpc.theirsModel.sector.update(object.id, Sector.toRequest(object)),
		);

		if (options.deprecate) Sector.deprecateAll();
	}

	static async destroy(
		id: number[] | number,
		options?: Sector.DestroyOptions,
	) {
		options = defaults(options, Sector.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		if (Array.isArray(id))
			await Promise.all(id.map((id) => destroyOne(id)));
		else await destroyOne(id);

		if (options.deprecate) Sector.deprecateAll();
	}

	static async getByPoint(point: LatLngLiteral) {
		const prpc = getPRPC();

		if (!prpc) return null;

		const [sector] = await createRPCQuery(() =>
			prpc?.theirsModel.sector.getByPoint(point),
		);

		return sector;
	}

	static async getAll(options: Sector.SubscribeOptions) {
		const prpc = getPRPC();

		if (!prpc) return [];
		try {
			const res = await createRPCQuery(() =>
				prpc?.theirsModel.sector.getAll(options),
			);

			if (!res) return [];
			logger.info("[Sector] getAll", res);
			return res.items?.map(Sector.fromResponse);
		} catch (error) {
			return [];
		}
	}

	static Global = {
		async index(options: Sector.SubscribeOptions) {
			const prpc = getPRPC();

			if (!prpc) return null;

			const result = await createRPCQuery(() =>
				prpc.theirsModel.sector.getAll({
					limit: options.limit,
					offset: options.offset,
					query: options.query,

					taxiServiceIds: options.taxiServiceIds,

					active: options.active,
					availableForExecutor: options.availableForExecutor,
					isParking: options.isParking,

					lang: options.language,

					order: options.order,
				}),
			);
			logger.info("[Sector] SubscribeOptions", result);
			return {
				cache: result.items.map(Sector.fromResponse),
				offset: result.pagination.offset,
				limit: result.pagination.count,
				total: result.pagination.count,
				deprecated: false,
			};
		},
	};
}

declare namespace Sector {
	interface Model {
		id: number;

		taxiServiceId: number;

		position: Date | number;
		name: Record<Language, string>;
		vertices: LatLngLiteral[];
		isMaxSpeedEnabled: boolean;
		maxSpeed: number;

		active: boolean;
		availableForExecutor: boolean;
		isParking: boolean;
		isPriceZone: boolean;

		isAverageSpeedEnabled?: boolean;
		averageSpeed?: AverageSpeed[];
		isBasicAverageSpeedEnabled?: boolean;
		basicAverageSpeed?: AverageSpeed[];

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<Sector.Model> {
		language?: Language;
		taxiServiceIds?: number[];

		active?: boolean;
		availableForExecutor?: boolean;
		isParking?: boolean;
	}

	interface SharedOptions {
		deprecate?: boolean;
	}

	interface AverageSpeed {
		// milliseconds (where 0 is the start of the day and 86400000 is the end of the day)
		start: number;
		end: number;
		// Days of the week (Sunday -- Saturday: 0 - 6)
		dow: number[];
		// km/h
		speed: number;
		id: number;
	}

	interface StoreOptions extends SharedOptions {}
	interface UpdateOptions extends SharedOptions {}
	interface DestroyOptions extends SharedOptions {}

	namespace Model {
		type New = Omit<Model, "id" | "createdAt" | "updatedAt" | "deletedAt">;
		type Modified = Omit<Model, "createdAt" | "updatedAt" | "deletedAt">;
	}
}

export default Sector;
