import React, { useMemo } from "react";
import moment from "moment";
import { theme } from "uikit";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";
import useFormatDate from "../../../../../../../hooks/useFormatDate";

const OrderTimeCellContent: React.FC<OrderTimeCellContent.Props> = ({
	item,
}) => {
	const formatDate = useFormatDate();

	const isPreliminary = useMemo(
		() =>
			item.isPreliminary ||
			item?.additionalFields?.transfer?.lastReceivedOrder?.isPreliminary,
		[
			item?.additionalFields?.transfer?.lastReceivedOrder?.isPreliminary,
			item.isPreliminary,
		],
	);

	const content = useMemo(
		() =>
			isPreliminary
				? formatDate(
						moment(item.orderDate).set({
							second: 0,
							millisecond: 0,
						}),
						// .format("HH:mm:ss"),
						"HH:mm:ss",
				  )
				: "",
		[formatDate, isPreliminary, item.orderDate],
	);

	const backgroundColor = useMemo(
		() => (isPreliminary ? "#7B0078" : undefined),
		[isPreliminary],
	);

	const textColor = useMemo(
		() => (isPreliminary ? theme.colors.white : undefined),
		[isPreliminary],
	);

	return (
		<CellContentRoot
			align="center"
			backgroundColor={backgroundColor}
			textColor={textColor}
			maxedHeight
			maxedWidth
		>
			{content}
		</CellContentRoot>
	);
};

declare namespace OrderTimeCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default OrderTimeCellContent;
