import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "uikit";
import { isUndefined } from "lodash";

import { SIPToDispatcher } from "../../../../../../../../../../services";
import { MessageTemplateActions } from "../../../../../../../../../../types/MessageTemplateEnums";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import OrderStatus from "../../../../../../../../../../types/OrderStatus";
import Divider from "../../../../../../../../../../components/Divider";
import {
	Popup,
	StyledRow,
} from "../../../../../../../../../../components/common";
import {
	generateAccessName,
	AccessKey,
	hasAccess,
} from "../../../../../../../../../../access";

import LeftButton from "./components/LeftButton";
import RightButton from "./components/RightButton";
import PopupContent from "./components/PopupContent";

export const ROBOT_CALL_TEMPLATES = [
	MessageTemplateActions.WILL_BE_TIME,
	MessageTemplateActions.CAR_NOT_FOUND,
	MessageTemplateActions.AT_ADDRESS,
];

const AutoCallClientButton: React.FC<AutoCallClientButton.Props> = () => {
	const ACCESS_SECTION = AccessKey.ORDERS;
	const ACCESS_NAME = AccessKey.AUTO_CALL_CLIENT_BUTTON;

	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);
	const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);

	const activeOrder = useTypedSelector(
		(state) => state.ordersPageReducer.activeOrder,
	);

	const isOwn = useMemo(() => activeOrder?.isOwn, [activeOrder]);

	const popupOffset: Popup.Props["offset"] = useMemo(
		() => ({ x: 0, y: 37 }),
		[],
	);

	const disabled = useMemo(() => "id" in activeOrder, [activeOrder]);

	const data = useMemo(() => {
		const isExecutor =
			activeOrder.passengers?.length &&
			activeOrder?.executorToOrder?.length &&
			activeOrder?.status === OrderStatus.EXECUTING;

		const isPassenger = "id" in activeOrder && !activeOrder?.closedAt;

		if (isExecutor) {
			return [
				{
					label: t(
						`pages.mainPage.pages.orders.orderHeader.secondRow.autoCallClientButton.willBeTime`,
					),
					value: MessageTemplateActions.WILL_BE_TIME,
				},
				{
					label: t(
						`pages.mainPage.pages.orders.orderHeader.secondRow.autoCallClientButton.atAddress`,
					),
					value: MessageTemplateActions.AT_ADDRESS,
				},
			];
		}

		if (isPassenger) {
			return [
				{
					label: t(
						"pages.mainPage.pages.orders.orderHeader.secondRow.autoCallClientButton.carNotFound",
					),
					value: MessageTemplateActions.CAR_NOT_FOUND,
				},
			];
		}

		return [];
	}, [activeOrder, t]);

	const onSelect = useCallback(
		async (
			callMessageAction: MessageTemplateActions = MessageTemplateActions.WILL_BE_TIME,
		) => {
			if (isUndefined(activeOrder.id)) return;

			SIPToDispatcher.callMessageToCustomer({
				orderId: activeOrder.id,
				callMessageAction,
			});

			setIsPopupOpen(false);
		},
		[activeOrder],
	);

	const isNotAccess = useMemo(
		() =>
			!hasAccess(
				generateAccessName(ACCESS_SECTION, ACCESS_NAME),
				personalRole,
			),
		[ACCESS_SECTION, ACCESS_NAME, personalRole],
	);

	if (isNotAccess) return null;

	return (
		<Popup
			open={isPopupOpen}
			useClickControl={disabled || isOwn}
			tracker={
				<StyledRow>
					<LeftButton
						variant="secondary"
						title={
							t(
								"pages.mainPage.pages.orders.orderHeader.secondRow.str12",
							) ?? ""
						}
						disabled={!disabled || !isOwn}
						active={isPopupOpen}
						tabIndex={-1}
					>
						<Icon id="orders-robot-call" size={20} />
					</LeftButton>
					<Divider orientation="vertical" size={28} color="#DEE0E2" />
					<RightButton
						variant="secondary"
						title={
							t(
								"pages.mainPage.pages.orders.orderHeader.secondRow.str12",
							) ?? ""
						}
						disabled={!disabled || !isOwn}
						active={isPopupOpen}
						tabIndex={-1}
					>
						<Icon id="orders-sms-arrow" size={12} />
					</RightButton>
				</StyledRow>
			}
			containerId="page-content"
			trackerId="order-page-header-robot-call-button"
			offset={popupOffset}
			onChangeOpen={setIsPopupOpen}
		>
			<PopupContent data={data} onSelect={onSelect} />
		</Popup>
	);
};

declare namespace AutoCallClientButton {
	interface Props {}
}

export default AutoCallClientButton;
