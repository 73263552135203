import React, { memo, useMemo, useCallback } from "react";
import { Controller, Control, FieldPath } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Option } from "uikit";

import { Schema } from "../../../../../../../../redux/constants/OrdersPage/order";
import {
	Style,
	StyledGridItem,
	StyledRow,
	StyledWrapIcon,
	Select,
} from "../../../../../../../../components/common";

const GridSelect: React.FC<GridSelect.Props> = ({
	area,
	control,
	name,
	icon,
	options,
	disabled,
	onSelect,
	styles,
}) => {
	const { t } = useTranslation();
	const wrap = useCallback<GridSelect.WrapCallback>(
		(option) => ({
			key: option.id,
			value: option.id,
			label: t([option?.name || ""]),
			active: option?.active,
		}),
		[t],
	);

	const wrappedOptions = useMemo(() => options.map(wrap), [options, wrap]);

	// TODO: is not correct , the Controller must be removed
	return (
		<StyledGridItem area={area} {...styles?.elem}>
			<Controller
				name={name}
				control={control}
				render={({ field }) => {
					const value = options.find((item) => {
						if (item.id === field.value) return true;
						return false;
					});

					const selected = wrappedOptions
						.map((item, i) => {
							if (field?.value === item?.value) return i + 1;
							return null;
						})
						.find((item) => item !== null);

					return (
						<StyledRow flex={{ wrap: "nowrap" }} {...styles?.wrap}>
							{icon && (
								<StyledWrapIcon {...styles?.icon}>
									{icon}
								</StyledWrapIcon>
							)}

							<Select
								selected={selected || 1}
								disabled={Boolean(disabled)}
								value={
									field.value
										? value && wrap(value).key
										: undefined
								}
								options={wrappedOptions}
								onSelect={(option) => {
									if (onSelect) onSelect(option);
									else {
										field.onChange({
											target: { value: option?.value },
										} as unknown as React.ChangeEvent<Element>);
									}
								}}
							/>
						</StyledRow>
					);
				}}
			/>
		</StyledGridItem>
	);
};

declare namespace GridSelect {
	interface Props {
		area: string;
		control: Control<Schema, any>;
		name: FieldPath<Schema>;
		icon?: React.ReactNode;
		options: readonly any[];
		disabled?: boolean;
		onSelect?: (option: any) => void;
		styles?: {
			elem?: Partial<Style.GridItemType> &
				Style.DistributeType &
				Style.BaseType;
			icon?: Style.FlexType & Style.DistributeType & Style.BaseType;
			wrap?: Style.FlexType & Style.DistributeType & Style.BaseType;
		};
	}

	type Selected = Option<number | string>;

	type WrapCallback = (option: {
		name: string;
		id: number | string;
		active?: boolean;
	}) => Option<number | string>;
}

export const GridSelectMemo = memo(GridSelect);
export default GridSelect;
