import React, { memo, useMemo } from "react";
import { isFunction, isNil, isUndefined } from "lodash";
import PropsOf from "uikit/src/types/PropsOf";

import { useTypedSelector } from "../../../redux/store";
import { BaseKeybindEntries } from "../../../redux/reducers/settings/keybinds";
import { useKeyBindSettings } from "../../../hooks/useKeyBindSettings";
import keyNames from "../../../utils/keyNames";

import Root from "./components/Root";

const ButtonBase: React.FC<ButtonBase.Props> = ({
	settingsKeyId,
	ref,
	...props
}) => {
	useKeyBindSettings(settingsKeyId, (event) => {
		if (!props.disabled) props.onClick?.(event);

		event.preventDefault();
		event.stopPropagation();
	});

	const keys = useTypedSelector((state) =>
		isNil(settingsKeyId)
			? []
			: state.settings.keybinds[settingsKeyId].map(
					(key) => keyNames[key],
			  ),
	);

	const keysString = useMemo(
		() => (keys.length ? `(${keys.join("+")})` : ""),
		[keys],
	);

	const title = useMemo(() => {
		if (isFunction(props?.title)) return props.title(keys);

		if (isUndefined(props?.title)) return undefined;

		return `${props.title}${
			!isUndefined(props.title) ? " " : ""
		}${keysString}`;
	}, [keys, keysString, props]);

	return <Root {...props} ref={ref} title={title} />;
};

declare namespace ButtonBase {
	type Variant = Root.Variant;

	interface Props extends Omit<PropsOf<typeof Root>, "title"> {
		settingsKeyId?: keyof BaseKeybindEntries<"meta">;

		title?: ((keys: string[]) => string) | string;
	}

	namespace Variant {
		type Props = Root.Variant.Props;
	}
}

export const Button = memo(ButtonBase);

export default ButtonBase;
