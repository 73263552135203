import * as ModelEvent from "@node-elion/syncron";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import { ModelId } from "../../types/ModelId";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import {
	MessageTemplateActions,
	MessageTemplateTypes,
} from "../../types/MessageTemplateEnums";
import fromJsonToString from "../../pages/Settings/pages/Message/Templates/components/Modal/components/Content/components/TemplateBody/helpers/fromJsonToString";
import { Base, Language as Lang, Card, TaxiServiceOld } from "..";

const logger = createLogger({ name: "SMSTemplate" });

class SMSTemplate extends Base {
	private static _Card: Card | null = null;

	public static get Card() {
		if (this._Card) return this._Card;

		this._Card = new Card((prpc) => prpc.theirsModel.sms.template.card);

		return this._Card;
	}

	public static fromResponse(data: any): SMSTemplate.Model {
		const taxiServices = data.smsTemplateToTaxiServices.map(
			({ taxiService }) => taxiService,
		);

		return {
			id: data.id,

			name: data.name,
			templateText: data.handlebars,
			active: data.active,
			automaticSending: data.automaticSending,
			autoSendOrderChanges: data.autoSendOrderChanges,
			disableAutoSendForPreOrder: data.disableAutoSendForPreOrder,
			disableAutoSendForOnlineOrder: data.disableAutoSendForOnlineOrder,
			isAutoSendDelay: data.isAutoSendDelay,
			autoSendDelay: data.autoSendDelay / 1000,
			excludeOrderChanges: data.excludeOrderChanges,
			taxiServices,
			taxiServiceIds: taxiServices.map(({ id }) => id),
			isDefault: data.isDefault,
			lang: data.lang,
			type: data.type,
			action: data.action,
			isValidityPeriod: data.isValidityPeriod,
			validityPeriod: data.validityPeriod / 1000 / 60,
			isTransliteration: data.isTransliteration,
			orderTemplate: data.orderTemplate,
			isMaxSmsPartCount: data.isMaxSmsPartCount,
			maxSmsPartCount: data.maxSmsPartCount,
			isMinTimeoutSendingSms: data.isMinTimeoutSendingSms,
			minTimeoutSendingSms: data.minTimeoutSendingSms / 1000,

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	public static toRequest(
		model: SMSTemplate.New | SMSTemplate.Modified,
	): any {
		let handlebars = "";
		if (model.templateText) {
			handlebars =
				typeof model.templateText === "string"
					? model.templateText
					: fromJsonToString(model.templateText);
		}

		return {
			name: model.name,
			handlebars,
			active: model.active,
			automaticSending: model.automaticSending,
			disableAutoSendForPreOrder: model.disableAutoSendForPreOrder,
			disableAutoSendForOnlineOrder: model.disableAutoSendForOnlineOrder,
			isAutoSendDelay: model.isAutoSendDelay,
			autoSendDelay: model.autoSendDelay && model.autoSendDelay * 1000,
			excludeOrderChanges: model.excludeOrderChanges,
			autoSendOrderChanges: model.autoSendOrderChanges,
			taxiServiceIds: model.taxiServiceIds,
			lang: model.lang,
			type: model.type,
			isValidityPeriod: model.isValidityPeriod,
			validityPeriod:
				model.validityPeriod && model.validityPeriod * 1000 * 60,
			isTransliteration: model.isTransliteration,
			orderTemplate: model.orderTemplate,
			isMaxSmsPartCount: model.isMaxSmsPartCount,
			maxSmsPartCount: model.maxSmsPartCount,
			isMinTimeoutSendingSms: model.isMinTimeoutSendingSms,
			minTimeoutSendingSms:
				model.minTimeoutSendingSms && model.minTimeoutSendingSms * 1000,
			action: model.action,
		};
	}

	public static async store(object: SMSTemplate.New, force: boolean) {
		try {
			await this.request((prpc) =>
				prpc.theirsModel.sms.template.create(
					SMSTemplate.toRequest(object),
					force,
				),
			);
		} catch (err: any) {
			if (err.status === 403) {
				return false;
			}
		}
		return true;
	}

	public static async copy(id) {
		try {
			await this.request((prpc) =>
				prpc.theirsModel.sms.template.copy(id),
			);
		} catch (err: any) {
			throw new Error("Error in copy methor:", err);
		}
	}

	public static async update(object: SMSTemplate.Modified, force: boolean) {
		try {
			await this.request((prpc) =>
				prpc.theirsModel.sms.template.update(
					object.id,
					SMSTemplate.toRequest(object),
					force,
				),
			);
		} catch (err: any) {
			logger.error("This is error:", err);
			if (err.status === 403) {
				return false;
			}
		}
		return true;
	}

	public static async destroy(id: number[] | number) {
		this.request((prpc) => prpc.theirsModel.sms.template.delete(id));
	}

	public static async subscribe(
		options: SMSTemplate.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<SMSTemplate.Model>,
	): Promise<Subscription<SMSTemplate.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.sms.template.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: async (events) => {
						await modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						logger.error(error);
					},
				}),
			{ name: "SMSTemplate.subscribe", metadata: options },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (subOptions: SMSTemplate.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(subOptions)),
		} as Subscription<SMSTemplate.SubscribeOptions>;
	}

	public static async getParams() {
		const res = await this.request((prpc) =>
			prpc.theirsModel.sms.template.getTemplateParams(),
		);

		return res;
	}

	private static optionsToRequest(optns: SMSTemplate.SubscribeOptions) {
		return {
			lang: optns.language,
			active: optns.active,
			limit: optns.limit,
			offset: optns.offset,
			order: optns.order,
			actions: optns.actions,
		};
	}
}

declare namespace SMSTemplate {
	interface Model extends NonEditableProperties {
		name: string;
		templateText: string;
		automaticSending: boolean;
		autoSendOrderChanges?: boolean;
		disableAutoSendForPreOrder: boolean;
		disableAutoSendForOnlineOrder: boolean;
		isAutoSendDelay: boolean;
		autoSendDelay: number;
		excludeOrderChanges: boolean;
		isValidityPeriod: boolean;
		validityPeriod: number;
		lang: Lang;
		isTransliteration: boolean;
		orderTemplate: boolean;
		isMaxSmsPartCount: boolean;
		maxSmsPartCount: number;
		isMinTimeoutSendingSms: boolean;
		minTimeoutSendingSms: number;
		active: boolean;
		taxiServices: TaxiServiceOld.Model[];
		taxiServiceIds: number[];

		readonly action: MessageTemplateActions;
		readonly type: MessageTemplateTypes;
		readonly isDefault: boolean;
	}

	type New = Omit<Model, NonEditablePropertyNames>;
	type Modified = Partial<New> & NonEditableProperties;

	interface NonEditableProperties {
		readonly id: ModelId;
		readonly createdAt: string;
		readonly updatedAt: string;
		readonly deletedAt: string | null;
	}

	type NonEditablePropertyNames =
		| "id"
		| "createdAt"
		| "updatedAt"
		| "deletedAt";

	interface SubscribeOptions
		extends Omit<ServiceSubscribeOptionsBase<SMSTemplate.Model>, "query"> {
		active?: boolean;
		language?: Lang;
		taxiServiceIds?: number[];
		actions?: MessageTemplateActions;
	}
}

export default SMSTemplate;
