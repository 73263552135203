import React, {
	Dispatch,
	memo,
	useCallback,
	useMemo,
	useState,
	useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CarModel } from "../../../../services";
import useModelSubscribe from "../../../../hooks/useModelSubscribe2";
import MultiSelectWithModalBase, {
	MultiSelectWithModal,
} from "../MultiSelectWithModal";
import { StyledP, StyledRow, StyledColumn } from "../..";

const EqualWidthColumn = styled(StyledColumn)`
	flex: 1;
`;

const MultiSelectCarModelIdWithModal: React.FC<
	MultiSelectCarModelIdWithModal.Props
> = ({
	value,
	typeValue = "key",
	onChange,
	disabled,
	titleText,
	required,
	modalSetting,
	sort,
	error = "",
	subscribeOptions = {},
	valueSearch,
	saveSearch,
	saveIds,
}) => {
	const { t } = useTranslation();

	const _subscribeOptions = useMemo(
		() => subscribeOptions,
		[subscribeOptions],
	);

	const modelData = useModelSubscribe(_subscribeOptions, CarModel);

	const [errorInternal, setErrorInternal] = useState<string | boolean>("");
	useEffect(() => {
		setErrorInternal(error);
	}, [error]);

	const items = useMemo(() => modelData.models, [modelData.models]);

	const selectOptions = useMemo(() => {
		const arr = items.map((item) => ({
			key: item.id,
			label: item.name ?? "Unknown",
			value: item.id,
			name: item.name ?? "Unknown",
			data: item,
		}));

		const searchElement = {
			key: -1,
			label: valueSearch ?? "Unknown",
			value: -1,
			name: valueSearch ?? "Unknown",
			data: {} as CarModel.Model,
		};

		if (
			valueSearch &&
			typeValue === "label" &&
			value &&
			!arr.some((item) => value === item[typeValue])
		)
			arr.push(searchElement);

		return arr;
	}, [items, typeValue, value, valueSearch]);

	const selected = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			value
				? selectOptions.filter((item) => value === item[typeValue])
				: [],

		[selectOptions, typeValue, value],
	);

	const selectOnChange = useCallback(
		(newValue) => {
			setErrorInternal("");
			const ids = newValue.map((item) => item[typeValue]);
			onChange(ids?.length > 0 ? ids[0] : undefined);
			if (saveIds) {
				const idsForSave = newValue.map((item) => item.key);
				saveIds(idsForSave);
			}
		},
		[onChange, saveIds, typeValue],
	);

	return (
		<StyledRow gap="7px" alignItems="center">
			<EqualWidthColumn gap="7px">
				<StyledP>{t(titleText) ?? ""}</StyledP>
				<MultiSelectWithModal
					value={selected}
					onChange={selectOnChange}
					options={selectOptions}
					error={disabled ? false : errorInternal}
					disabled={disabled}
					title={t(titleText) ?? ""}
					selectOnlyOne={true}
					required={required}
					pullUpItemInsideArray
					hiddenButton={false}
					modalSetting={modalSetting}
					sort={{
						show: true,
						active: true,
						...(sort || {}),
					}}
					valueSearch={valueSearch}
					saveSearch={saveSearch}
				/>
			</EqualWidthColumn>
		</StyledRow>
	);
};

declare namespace MultiSelectCarModelIdWithModal {
	interface Props extends MultiSelectWithModalBase.Setting {
		titleText: string;
		modalSetting?: MultiSelectWithModalBase.ListSelectProps["modalSetting"];

		value: Value;
		onChange: Dispatch<Value>;
		disabled: boolean;
		error?: string | boolean;
		subscribeOptions?: CarModel.SubscribeOptions;
		typeValue?: TypeValue;
		valueSearch?: string;
		saveSearch?: (value: string) => void;
		saveIds?: (value: number[]) => void;
	}

	type TypeValue = "key" | "label";
	type Value = number | string | undefined;
}

export default memo(MultiSelectCarModelIdWithModal);
