/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isNumber } from "lodash";
import styled from "styled-components";
import { Button, Icon, InputGroup, theme, useReactiveRef } from "uikit";

import TaxiService from "../../../../../../../../services/TaxiService";
import { useTypedSelector } from "../../../../../../../../redux/store";
import useModelSubscribe from "../../../../../../../../hooks/useModelSubscribe";
import SearchIconBorders from "../../../../../../../../components/SearchIconBorders";
import SearchTextBox from "../../../../../../../../components/SearchTextBox";
import CompaniesAndTaxiServicesFilter from "../../../../../../../../components/CompaniesAndTaxiServicesFilter";
import {
	StyledRow,
	StyledGrid,
	LanguageSelect,
} from "../../../../../../../../components/common";
import {
	generateAccessName,
	AccessKey,
	hasAccess,
} from "../../../../../../../../access";
import {
	TabKeys,
	ARR_ACCESS_PART_KEY,
} from "../../../../../../constants/access";

const StyledInputGroup = styled(InputGroup.InputGroup)`
	width: 280px !important;
`;
const StyledRoot = styled.div`
	padding: 20px 24px 0;
	width: 100%;
	display: flex;
	gap: 10px;
	justify-content: space-between;
	align-items: center;
`;

export default function Header({
	handleOpenModal,
	setTaxiServiceFilter,
	onDelete,
	onEdit,
	notSelected,
	setLanguageFilter,
	languageFilter,
	query,
	onChangeQuery,
}) {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const AccessBtnGroup = useMemo(() => {
		const ACCESS_TAB = ARR_ACCESS_PART_KEY[TabKeys.COLORS];

		const retval = {
			add: hasAccess(
				generateAccessName(...ACCESS_TAB, AccessKey.CONTROL_BUTTON_ADD),
				personalRole,
			),
			edit: hasAccess(
				generateAccessName(
					...ACCESS_TAB,
					AccessKey.CONTROL_BUTTON_EDIT,
				),
				personalRole,
			),
			delete: hasAccess(
				generateAccessName(
					...ACCESS_TAB,
					AccessKey.CONTROL_BUTTON_DELETE,
				),
				personalRole,
			),
		};

		return retval;
	}, [personalRole]);

	const taxiServices = useModelSubscribe({}, TaxiService)?.cache;

	const [selectedCompaniesRef, setSelectedCompaniesRef] = useReactiveRef<
		number[] | ["all"]
	>(["all"]);
	const [selectedTaxiServicesRef, setSelectedTaxiServicesRef] =
		useReactiveRef<number[] | ["all"]>(["all"]);

	const calculateAndSendTaxiServiceIdsOnChange = useCallback(() => {
		const data =
			selectedTaxiServicesRef.current[0] !== "all"
				? (selectedTaxiServicesRef.current as number[])
				: selectedCompaniesRef.current[0] !== "all"
				? taxiServices
						?.filter(
							(taxiService) =>
								isNumber(taxiService.company?.id) &&
								(selectedCompaniesRef.current as Array<number>)
									// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
									.includes(taxiService.company!.id),
						)
						.map((taxiService) => taxiService.id)
				: undefined;
		setTaxiServiceFilter(data);
	}, [
		setTaxiServiceFilter,
		selectedCompaniesRef,
		selectedTaxiServicesRef,
		taxiServices,
	]);

	const CTSFOnChangeCompanies = useCallback(
		(ids: number[] | ["all"]) => {
			setSelectedCompaniesRef(ids);

			calculateAndSendTaxiServiceIdsOnChange();
		},
		[calculateAndSendTaxiServiceIdsOnChange, setSelectedCompaniesRef],
	);

	const CTSFOnChangeTaxiServices = useCallback(
		(ids: number[] | ["all"]) => {
			setSelectedTaxiServicesRef(ids);

			calculateAndSendTaxiServiceIdsOnChange();
		},
		[calculateAndSendTaxiServiceIdsOnChange, setSelectedTaxiServicesRef],
	);

	return (
		<StyledRoot>
			<StyledRow gap="10px">
				{AccessBtnGroup.add && (
					<Button.Button
						icon={
							<Icon
								id="plus"
								size={16}
								colors={[theme.colors.white]}
							/>
						}
						onClick={() => {
							handleOpenModal();
						}}
					/>
				)}
				{AccessBtnGroup.edit && (
					<Button.Button
						variant="secondary"
						icon={<Icon id="pencil" size={20} />}
						disabled={!notSelected}
						onClick={() => {
							onEdit();
						}}
					/>
				)}
				{AccessBtnGroup.delete && (
					<Button.Button
						variant="secondary"
						icon={<Icon id="trash" size={20} />}
						disabled={!notSelected}
						onClick={() => onDelete()}
					/>
				)}
			</StyledRow>
			<StyledRow gap="20px">
				<StyledGrid
					areas=""
					gap="10px"
					alignItems="center"
					columns="200px 200px 130px 270px"
				>
					<CompaniesAndTaxiServicesFilter
						companies={selectedCompaniesRef.current}
						taxiServices={selectedTaxiServicesRef.current}
						language={languageFilter}
						onChangeCompanies={CTSFOnChangeCompanies}
						onChangeTaxiServices={CTSFOnChangeTaxiServices}
					/>
					<LanguageSelect
						onSelect={(value) => setLanguageFilter(value.key)}
						value={languageFilter}
					/>
					<StyledInputGroup sizes="auto 280px">
						<SearchIconBorders>
							<Icon
								id="search"
								size={16}
								colors={[theme.colors.disabled_text]}
							/>
						</SearchIconBorders>
						<SearchTextBox
							value={query}
							onChange={onChangeQuery}
							placeholder={`${
								t(
									"pages.preferencesPages.screenDirectory.colors.header.str202",
								) ?? ""
							}...`}
						/>
					</StyledInputGroup>
				</StyledGrid>
				<Button.Button
					variant="secondary"
					icon={<Icon id="filters" size={20} />}
					onClick={() => {}}
				/>
			</StyledRow>
		</StyledRoot>
	);
}
