import * as ModelEvent from "@node-elion/syncron";
import { isNumber } from "lodash";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import emailToRequest from "../../utils/emailToRequest";
import stringToUndefined from "../../utils/stringToUndefined";
import { ModelId } from "../../types/ModelId";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import {
	NonEditableProperties,
	NonEditablePropertyNames,
} from "../../types/NonEditableProperties";
import TripLimit from "../../pages/MainPage/pages/Customers/tabs/Counterparties/components/Modal/components/TripLimit";
import Addresses from "../../pages/MainPage/pages/Customers/tabs/Counterparties/components/tabs/Employees/components/Modal/components/Content/tabs/Main/components/Addresses";
import { GenderOptions } from "../../pages/MainPage/pages/Customers/tabs/Counterparties/components/tabs/Employees/components/Modal/components/Content/tabs/Main";
import Accounts from "../../pages/MainPage/pages/Customers/tabs/Counterparties/components/Modal/components/Content/components/Accounts";
import { Base, File } from "..";

const logger = createLogger({ name: "Employee" });

class Employee extends Base {
	public static fromResponse(data: any): Employee.Model {
		const defaultTaxiServiceId = data?.customer?.defaultTaxiService?.id;

		return {
			id: data.id,

			active: data.active,
			phones: data.phones.length
				? data.phones.map((phone) => {
						const autoDiscoverCheckCardId =
							phone?.autoDiscoverCheckCard?.id || null;
						return {
							id: phone.id,
							value: phone.value,
							group: phone.group,
							isCounterparty: phone.isCounterparty,
							autoDiscoverCheckCardId,
						};
				  })
				: [
						{
							value: "",
							group: 0,
							isCounterparty: false,
							autoDiscoverCheckCardId: null,
						},
				  ],
			additionalFields: {
				...data.additionalFields,
				addresses: data?.additionalFields?.addresses?.length
					? data?.additionalFields?.addresses
					: [
							{
								group: 0,
								isCounterparty: false,
								value: "",
							},
					  ],
				tripLimit: data.additionalFields?.tripLimit
					? data.additionalFields.tripLimit
					: {
							active: false,
							amountByPeriod: {
								active: false,
								amount: 0,
								customPeriod: 0,
							},
							amountByTrip: {
								active: false,
								maxAmountOfTrip: 0,
							},
							countByPeriod: {
								active: false,
								customPeriod: 0,
								numberOfTrips: 0,
							},
							distanceByPeriod: {
								active: false,
								customPeriod: 0,
								distance: 0,
							},
					  },
			},
			defaultTaxiServiceId:
				data.defaultTaxiServiceId || defaultTaxiServiceId,
			isCode: data.isCode,
			isOneTimePassword: data.isOneTimePassword,
			code: data?.code || { value: "", keyword: "" },
			personalFiles: data.personalFiles?.map(File.fromResponse),
			otherFiles: data.otherFiles?.map(File.fromResponse),
			isAccessToCorporateApp: data.isAccessToCorporateApp,
			isAccessToCorporateAccount: data.isAccessToCorporateAccount,
			login: data.login,
			password: data.isPassword ? "********" : undefined,
			oneTimePassword: data.oneTimePassword,
			rideCounter: data.rideCounter?.total || { total: 0 },
			customer: {
				id: data.customer.id,
				emails: data.customer.person.emails ?? [
					{ value: "", group: 0 },
				],
				personPhone:
					data.customer.person?.phones?.[0]?.number &&
					data.customer.person.phones[0].number
						? data.customer.person.phones[0].number
						: "",
				gender: data.customer.gender,
				name: data.customer.person.name,
				surname: data.customer.person.surname,
				birthday: data.customer.person.birthday,
				fatherName: data.customer.person.fatherName,
			},
			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	public static toRequest(e: Employee.New | Employee.Modified) {
		const code = {
			id: e.code?.id,
			value: stringToUndefined(e.code?.value),
			keyword: stringToUndefined(e.code?.keyword),
		};

		const modifyPhones = (phones?: Employee.Model["phones"]) => {
			if (!phones?.length) return [];
			const payload = phones
				.filter(({ value }) => value?.trim().length)
				?.map((phone) => {
					const isNull = !phone.isCounterparty;

					const autoDiscoverCheckCardId = isNumber(
						phone?.autoDiscoverCheckCardId,
					)
						? phone?.autoDiscoverCheckCardId
						: true;

					return {
						id: isNumber(phone.id) ? phone.id : undefined,
						value: phone.value,
						group: phone.group,
						isCounterparty: phone.isCounterparty,
						autoDiscoverCheckCardId: isNull
							? null
							: autoDiscoverCheckCardId,
					};
				});

			return payload;
		};

		return {
			id: Number.isInteger(e.id) ? e.id : undefined,
			defaultTaxiServiceId: e.defaultTaxiServiceId,
			active: e.active,
			additionalFields: {
				addresses: e.additionalFields?.addresses.filter(
					({ value }) => value?.trim().length,
				),
				tripLimit: e.additionalFields?.tripLimit,
			},
			personalFileIds: e.personalFiles?.map(({ id }) => id),
			otherFileIds: e.otherFiles?.map(({ id }) => id),
			customerId: e.customerId,
			phones: modifyPhones(e.phones),
			isCode: e.isCode,
			isOneTimePassword: e.isOneTimePassword,
			oneTimePassword: e.oneTimePassword?.length
				? e.oneTimePassword
				: undefined,
			code: Object?.values(code).every((el) => el === undefined)
				? undefined
				: code,
			isAccessToCorporateApp: e.isAccessToCorporateApp,
			isAccessToCorporateAccount: e.isAccessToCorporateAccount,
			login: e.login ?? undefined,
			password: e.password ?? undefined,
			customer: {
				id: e.customer?.id,
				emails: e.customer?.emails[0]?.value?.trim().length
					? e.customer?.emails.map(emailToRequest)
					: undefined,
				gender: e.customer?.gender,
				name: e.customer?.name,
				surname: e.customer?.surname,
				fatherName: e.customer?.fatherName,
				birthday: e.customer?.birthday ?? undefined,
			},
		};
	}

	public static async store(object: Employee.New) {
		try {
			await this.request((prpc) =>
				prpc.theirsModel.employee.create(Employee.toRequest(object)),
			);
			return true;
		} catch (err: any) {
			return false;
		}
	}

	public static async update(object: Employee.Modified) {
		try {
			await this.request((prpc) =>
				prpc.theirsModel.employee.update(
					object.id,
					Employee.toRequest(object),
				),
			);
			return true;
		} catch (err: any) {
			return false;
		}
	}

	public static async delete(ids: number[] | number) {
		try {
			const res = await this.request(
				(prpc) => prpc.theirsModel.employee.delete(ids),
				{ silent: false, error: true },
			);
			logger.info("[Employee] delete", {
				ids,
				res,
			});
			return res;
		} catch (err: any) {
			logger.error("[Employee] delete error", err);
			if (err.message.includes("force")) return false;
			return true;
		}
	}

	public static async subscribe(
		options: Employee.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<Employee.Model>,
	): Promise<Subscription<Employee.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,
					models: state.models.map(this.fromResponse),
				});
			},
		});
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.employee.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: async (events) => {
						await modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						logger.error(error);
					},
				}),
			{ name: "Employee.subscribe", metadata: options },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (subOptions: Employee.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(subOptions)),
		} as Subscription<Employee.SubscribeOptions>;
	}

	private static optionsToRequest(options: Employee.SubscribeOptions) {
		return {
			counterpartyId: options.counterpartyId,
			checkIds: options.checkIds,
			checkCardIds: options.checkCardIds,
		};
	}
}

declare namespace Employee {
	interface CustomerFoEmployee {
		id?: ModelId;
		gender: GenderOptions;
		name: string;
		surname: string;
		fatherName: string;
		birthday?: Date;
		personPhone: string;
		emails: {
			value: string;
			group: number;
		}[];
	}

	interface Model extends NonEditableProperties {
		defaultTaxiServiceId?: number;
		active: boolean;
		phones: ({
			value: string;
			group: number;
			isCounterparty: boolean;
			id?: string | number;
			autoDiscoverCheckCardId?: boolean | number | null;
			autoDiscoverCheckCard?: Accounts.Check["checkCards"][0] | null;
		} & Partial<Omit<NonEditableProperties, "id">>)[];
		isCode: boolean;
		isOneTimePassword?: boolean;
		oneTimePassword?: string;
		code: Partial<NonEditableProperties> & {
			value: string;
			keyword: string;
		};
		additionalFields: {
			addresses: Addresses.Value;
			tripLimit: TripLimit.Value;
		};
		personalFiles: File.Model[];
		otherFiles: File.Model[];
		checkCardId?: number;
		customerId?: ModelId;
		isAccessToCorporateApp: boolean;
		isAccessToCorporateAccount: boolean;
		login?: string;
		password?: string;
		rideCounter: { total: number };
		customer: CustomerFoEmployee;
	}

	type PreNew = Omit<Model, NonEditablePropertyNames> & { id: string };
	type New = Omit<PreNew, "customer" | "rideCounter"> & {
		customer: Omit<CustomerFoEmployee, "personPhone">;
	};
	type Modified = Omit<Partial<New>, "id"> & NonEditableProperties;

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<Employee.Model> {
		counterpartyId?: number;
		checkIds?: number[];
		checkCardIds?: number[];
	}
}

export default Employee;
