/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import * as ModelEvent from "@node-elion/syncron";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import { createObjectLanguageNames } from "../../assets/languages/langs";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import { SortingOrder } from "../../types/SortingOrder";
import { Base, Card, Language, CarBaseType, CarModelToCarBodyType } from "..";

const logger = createLogger({ name: "CarBodyType" });

class CarBodyType extends Base {
	// Its needed due to typescript bundler conflict
	private static _Card: Card | null = null;

	public static get Card() {
		if (this._Card) return this._Card;

		this._Card = new Card((prpc) => prpc.theirsModel.carBodyType.card);

		return this._Card;
	}

	static fromResponse(data: any): CarBodyType.Model {
		return {
			id: data.id,

			carBaseTypes: data.bodyTypeToBaseTypes?.map((bodyTypeToBaseType) =>
				CarBaseType.fromResponse(bodyTypeToBaseType.baseType),
			),
			carBodyTypeToCarModels: data.bodyTypeToModels?.map(
				(bodyTypeToModel) =>
					CarModelToCarBodyType.fromResponse(bodyTypeToModel),
			),
			// TODO: is not correct
			name: data.name.uk,

			updatedAt: data.updatedAt,
			createdAt: data.createdAt,
			deletedAt: data.deletedAt,
		};
	}

	static toRequest(
		model: CarBodyType.Model.New | CarBodyType.Model.Modified,
	): any {
		return {
			carBaseTypeIds: model.carBaseTypeIds,
			name: createObjectLanguageNames(model.name),
			// name: {
			// 	uk: model.name,
			// 	az: model.name,
			// 	tr: model.name,
			// 	en: model.name,
			// 	ru: model.name,
			// },
		};
	}

	public static async store(object: CarBodyType.Model.New) {
		this.request((prpc) =>
			prpc.theirsModel.carBodyType.create(CarBodyType.toRequest(object)),
		);
	}

	public static async update(object: CarBodyType.Model.Modified) {
		this.request((prpc) =>
			prpc.theirsModel.carBodyType.update(
				object.id,
				CarBodyType.toRequest(object),
			),
		);
	}

	public static async destroy(id: number[] | number) {
		if (Array.isArray(id))
			await Promise.all(id.map((id) => this.destroyOne(id)));
		else await this.destroyOne(id);
	}

	public static async subscribe(
		options: CarBodyType.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<CarBodyType.Model>,
	): Promise<Subscription<CarBodyType.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.carBodyType.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						logger.error(error);
					},
				}),
			{ name: "CarBodyType.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: CarBodyType.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<CarBodyType.SubscribeOptions>;
	}

	private static async destroyOne(id: number) {
		this.request((prpc) => prpc.theirsModel.carBodyType.delete(id));
	}

	private static optionsToRequest(options: CarBodyType.SubscribeOptions) {
		return {
			query: options.query,
			offset: options.offset,
			limit: options.limit,

			ids: options.ids,
			carModelIds: options.carModelIds,

			active: options.active,
			native: options.native,
			lang: options.language,

			order: options.order,
		};
	}
}

declare namespace CarBodyType {
	interface Model {
		id: number;

		carBaseTypes?: CarBaseType.Model[];
		carBodyTypeToCarModels?: CarModelToCarBodyType.Model[];

		name: string;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<CarBodyType.Model> {
		ids?: number[];
		carModelIds?: number[];

		active?: boolean;
		native?: boolean;
		language?: Language;

		order?: Partial<
			Record<
				| "id"
				| "name"
				| "company"
				| "taxiService"
				| "active"
				| "native"
				| "position"
				| "carBodyType",
				SortingOrder
			>
		>;
	}

	namespace Model {
		interface New {
			carBaseTypeIds?: number[];

			name: string;
		}

		type Modified = Partial<New> & Pick<Model, "id">;
	}
}

export default CarBodyType;
