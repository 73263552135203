import React, { RefAttributes, useCallback, useState, memo } from "react";
import { react, useRefWithSetter } from "uikit";
import { isEmpty, isNil, trim } from "lodash";

import { StyledColumn } from "../../../../../../../../components/common";

import {
	SelectModal,
	CarBaseTypeSelect,
	CarBrandSelect,
	CarModelSelect,
	CarBodyTypeSelect,
	CarClassSelect,
} from "./components";
import InternalController from "./Controller";

const EntityEditModal = react.withController<
	EntityEditModal.PropsBase,
	EntityEditModal.Controller
>(({ controller, value, onCancel, onSave }) => {
	const [carBaseTypeSelectRef, setCarBaseTypeSelectRef] =
		useRefWithSetter<CarBaseTypeSelect.Ref | null>(null);
	const [carBrandSelectRef, setCarBrandSelectRef] =
		useRefWithSetter<CarBrandSelect.Ref | null>(null);
	const [carModelSelectRef, setCarModelSelectRef] =
		useRefWithSetter<CarModelSelect.Ref | null>(null);
	const [carBodyTypeSelectRef, setCarBodyTypeSelectRef] =
		useRefWithSetter<CarBodyTypeSelect.Ref | null>(null);
	const [carClassSelectRef, setCarClassSelectRef] =
		useRefWithSetter<CarClassSelect.Ref | null>(null);

	const [internalValue, setInternalValue] = useState(value);
	const [searchValue, setSearchValue] = useState(value);
	const [idsValue, setIdsValue] = useState<EntityEditModal.ValueIds>({
		carBaseTypeIds: [],
		carBrandIds: [],
		carModelIds: [],
		carBodyTypeIds: [],
	});

	const validateForDisabled = useCallback(
		(value: string | undefined): boolean => {
			if (isNil(value)) return true;
			if (typeof value === "string" && isEmpty(trim(value))) return true;
			return false;
		},
		[],
	);

	const handleSelectChange = (
		str: EntityEditModal.HandleSelectString,
		nameValue: keyof typeof searchValue,
	) => {
		const isNotValueChoise = validateForDisabled(str);

		setInternalValue((prev) => ({
			...prev,
			[nameValue]: isNotValueChoise ? searchValue[nameValue] : str ?? "",
		}));
	};

	/* \\\\\\\\\\\\\\\\\\\\ */

	const handleCarBaseTypeName = (str: EntityEditModal.HandleSelectString) =>
		handleSelectChange(str, "carBaseTypeName");

	const handleCarBrandName = (str: EntityEditModal.HandleSelectString) =>
		handleSelectChange(str, "carBrandName");

	const handleCarModelName = (str: EntityEditModal.HandleSelectString) =>
		handleSelectChange(str, "carModelName");

	const handleCarBodyTypeName = (str: EntityEditModal.HandleSelectString) =>
		handleSelectChange(str, "carBodyTypeName");

	const handleCarClassIds = (arr: number[]) => {
		setInternalValue((prev) => ({
			...prev,
			carClassIds: arr ?? [],
		}));
	};

	/* \\\\\\\\\\\\\\\\\\\\ */

	const writeSearchCarBaseTypeName = (
		str: EntityEditModal.HandleSelectString,
	) => {
		setSearchValue((prev) => ({
			...prev,
			carBaseTypeName: str ?? "",
		}));
	};

	const writeSearchCarBrandName = (
		str: EntityEditModal.HandleSelectString,
	) => {
		setSearchValue((prev) => ({
			...prev,
			carBrandName: str ?? "",
		}));
	};

	const writeSearchCarModelName = (
		str: EntityEditModal.HandleSelectString,
	) => {
		setSearchValue((prev) => ({
			...prev,
			carModelName: str ?? "",
		}));
	};

	const writeSearchCarBodyTypeName = (
		str: EntityEditModal.HandleSelectString,
	) => {
		setSearchValue((prev) => ({
			...prev,
			carBodyTypeName: str ?? "",
		}));
	};

	/* \\\\\\\\\\\\\\\\\\\\ */

	const removeElementSearch = (arr: number[]): number[] =>
		arr?.filter((num) => num !== -1) || [];

	const writeIdsCarBaseTypeName = (arr: number[]) => {
		setIdsValue((prev) => ({
			...prev,
			carBaseTypeIds: removeElementSearch(arr),
		}));
	};

	const writeIdsCarBrandName = (arr: number[]) => {
		setIdsValue((prev) => ({
			...prev,
			carBrandIds: removeElementSearch(arr),
		}));
	};

	const writeIdsCarModelName = (arr: number[]) => {
		setIdsValue((prev) => ({
			...prev,
			carModelIds: removeElementSearch(arr),
		}));
	};

	const writeIdsCarBodyTypeName = (arr: number[]) => {
		setIdsValue((prev) => ({
			...prev,
			carBodyTypeIds: removeElementSearch(arr),
		}));
	};

	/* \\\\\\\\\\\\\\\\\\\\ */

	controller.setContext({
		carBaseTypeSelectRef,
		carBrandSelectRef,
		carModelSelectRef,
		carBodyTypeSelectRef,
	});

	const handleSubmit = useCallback(() => {
		const isCarBaseTypeSelectValue =
			carBaseTypeSelectRef.current?.validate();
		const isCarBrandSelectValue = carBrandSelectRef.current?.validate();
		const isCarModelSelectValue = carModelSelectRef.current?.validate();
		const isCarBodyTypeSelectValue =
			carBodyTypeSelectRef.current?.validate();
		const isCarClassSelectValue = carClassSelectRef.current?.validate();

		const isValidAll =
			isCarBaseTypeSelectValue &&
			isCarBrandSelectValue &&
			isCarModelSelectValue &&
			isCarBodyTypeSelectValue &&
			isCarClassSelectValue;

		if (isValidAll) onSave(internalValue);
	}, [
		carBaseTypeSelectRef,
		carBodyTypeSelectRef,
		carBrandSelectRef,
		carClassSelectRef,
		carModelSelectRef,
		internalValue,
		onSave,
	]);

	const handleClose = useCallback(() => {
		onCancel();
	}, [onCancel]);

	return (
		<SelectModal
			onSubmit={handleSubmit}
			onClose={handleClose}
			loading={false}
		>
			<StyledColumn gap="20px" h="100%">
				<CarBaseTypeSelect
					ref={setCarBaseTypeSelectRef}
					value={internalValue.carBaseTypeName}
					disabled={false}
					onChange={handleCarBaseTypeName}
					valueSearch={internalValue.carBaseTypeName}
					saveSearch={writeSearchCarBaseTypeName}
					saveIds={writeIdsCarBaseTypeName}
				/>
				<CarBrandSelect
					ref={setCarBrandSelectRef}
					value={internalValue.carBrandName}
					disabled={validateForDisabled(
						internalValue.carBaseTypeName,
					)}
					onChange={handleCarBrandName}
					valueSearch={internalValue.carBrandName}
					saveSearch={writeSearchCarBrandName}
					saveIds={writeIdsCarBrandName}
					valueIds={idsValue}
				/>
				<CarModelSelect
					ref={setCarModelSelectRef}
					value={internalValue.carModelName}
					disabled={validateForDisabled(internalValue.carBrandName)}
					onChange={handleCarModelName}
					valueSearch={internalValue.carModelName}
					saveSearch={writeSearchCarModelName}
					saveIds={writeIdsCarModelName}
					valueIds={idsValue}
				/>
				<CarBodyTypeSelect
					ref={setCarBodyTypeSelectRef}
					value={internalValue.carBodyTypeName}
					disabled={validateForDisabled(internalValue.carModelName)}
					onChange={handleCarBodyTypeName}
					valueSearch={internalValue.carBodyTypeName}
					saveSearch={writeSearchCarBodyTypeName}
					saveIds={writeIdsCarBodyTypeName}
					valueIds={idsValue}
				/>
				<CarClassSelect
					ref={setCarClassSelectRef}
					value={internalValue.carClassIds}
					disabled={validateForDisabled(
						internalValue.carBodyTypeName,
					)}
					onChange={handleCarClassIds}
					valueIds={idsValue}
				/>
			</StyledColumn>
		</SelectModal>
	);
}, InternalController);

declare namespace EntityEditModal {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value {
		carBaseTypeName: string;
		carBrandName: string;
		carModelName: string;
		carBodyTypeName: string;
		carClassIds: number[];
	}

	interface ValueIds {
		carBaseTypeIds: number[];
		carBrandIds: number[];
		carModelIds: number[];
		carBodyTypeIds: number[];
	}

	namespace Value {
		type Validated = Value;
	}

	interface PropsBase {
		value: Value;

		onCancel: () => void;
		onSave: (value: Value.Validated) => void;
	}

	type Props = PropsBase & RefAttributes<Ref>;

	type HandleSelectString = string | undefined;
}

export default memo(EntityEditModal);

export { EntityEditModal };
