/* eslint-disable no-shadow */

import { Base, Dispatcher } from "..";

class Card extends Base {
	private prpcModel: (prpc: any) => any;

	constructor(prpcModel: (prpc: any) => any) {
		super();

		this.prpcModel = prpcModel;
	}

	async open(id: number) {
		await Card.request((prpc) => this.prpcModel(prpc).open(id));
	}

	async focus(id: number) {
		await Card.request((prpc) => this.prpcModel(prpc).focus(id));
	}

	async close(id: number): Promise<void>;

	async close(ids: number[]): Promise<void>;

	async close(arg1: number | number[]): Promise<void> {
		if (Array.isArray(arg1))
			await Card.request((prpc) => this.prpcModel(prpc).close(arg1));
		else this.close([arg1]);
	}

	subscribe(onUpdate: Card.OnUpdate): Promise<Card.Subscription> | null {
		return Card.request((prpc) =>
			this.prpcModel(prpc).subscribe({
				ping: () => true,
				onUpdate: (session) =>
					onUpdate({
						...session,
						cards: session.cards.map((card) =>
							card.locked
								? {
										...card,
										lockedBy: Dispatcher.fromResponse(
											card.lockedBy,
										),
								  }
								: card,
						),
					}),
			}),
		);
	}
}

declare namespace Card {
	interface Session {
		open: boolean;
		dispatcherId: number;
		activeCardId: number;
		cards: Session.Card[];
	}

	namespace Session {
		interface CardBase {
			id: number;
			openedAt: number;
			position: number;
		}

		type LockedCardPart = {
			locked: true;
			lockedBy: Dispatcher.Model;
		};

		type OpenCardPart = {
			locked: false;
			lockedBy: undefined;
		};

		type Card = CardBase & (LockedCardPart | OpenCardPart);
	}

	interface Subscription {
		unsubscribe(): Promise<void>;
	}

	type OnUpdate = (data: Session) => void | Promise<void>;

	type Open = Card["open"];
	type Focus = Card["focus"];
	type Close = Card["close"];
	type Subscribe = Card["subscribe"];
}

export default Card;
