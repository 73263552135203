import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useRefSize } from "uikit";
import { isBoolean, isUndefined } from "lodash";

import Language from "../../../../../services/Language";
import { useTypedSelector } from "../../../../../redux/store";
import { useCurrencyGlobalSettings } from "../../../../../hooks";
import Divider from "../../../../Divider";

import useBottomData from "./hooks/useBottomData";
import useTopData from "./hooks/useTopData";
import Root from "./components/Root";
import Top from "./components/Top";
import Field from "./components/Field";
import Bottom from "./components/Bottom";
import EditButton from "./components/EditButton";
import PriceLabel from "./components/PriceLabel";
import AdditionalPriceLabel from "./components/AdditionalPriceLabel";

const Info: React.FC<Info.Props> = ({ language }) => {
	const { t } = useTranslation();
	const currencyGlobalSettings = useCurrencyGlobalSettings();

	const { ref: rootRef, size: rootSize } = useRefSize();

	const compactLevel = useMemo(() => {
		if (rootSize.width > 730) {
			return 0;
		}

		if (rootSize.width > 600) {
			return 1;
		}

		return 2;
	}, [rootSize.width]);

	const activeOrder = useTypedSelector(
		(state) => state.ordersPageReducer.activeOrder,
	);

	const hasHourlyTimeField =
		!!activeOrder?.additionalFields?.rateSettings?.enableHourlyService;

	const isOwn = useMemo(() => {
		if (!isBoolean(activeOrder?.isOwn)) return true;
		return activeOrder?.isOwn;
	}, [activeOrder?.isOwn]);

	const lastReceivedOrder = useMemo(
		() => activeOrder?.additionalFields?.transfer?.lastReceivedOrder,
		[activeOrder?.additionalFields?.transfer?.lastReceivedOrder],
	);

	const hasExecutor = useMemo(() => {
		const main = activeOrder?.executorToOrder?.length;
		const transfer = lastReceivedOrder?.executorToOrder?.length;
		return !!main || !!transfer;
	}, [
		activeOrder?.executorToOrder?.length,
		lastReceivedOrder?.executorToOrder?.length,
	]);

	const isShowBtn = useMemo(() => {
		if (isOwn) return true;
		return hasExecutor;
	}, [hasExecutor, isOwn]);

	const {
		created,
		feeding,
		completion,
		status,
		client,
		executor,
		classes,
		parameters,
		services,
		notes,
	} = useTopData(activeOrder, language);

	const {
		distance,

		outOfTownDistanceValue,
		outOfTownDistanceLabel,
		updateOutOfTownDistance,

		idleTimeValue,
		idleTimeLabel,
		updateIdleTime,

		priceValue,
		priceLabel,
		updatePrice,

		discountLabel,

		additionalPriceValue,
		additionalPriceLabel,
		updateAdditionalCost,

		awaitingTimeValue,
		awaitingTimeLabel,
		updateAwaitingTime,

		hourlyTimeValue,
		hourlyTimeLabel,
		updateHourlyTime,
	} = useBottomData(activeOrder);

	return (
		<Root ref={rootRef} gaps="8px*">
			<Top compactLevel={compactLevel}>
				<Field
					area="created"
					label={t("orderPageWidgets.orderDetails.info.str0") ?? ""}
				>
					{created}
				</Field>
				<Field
					area="feeding"
					label={t("orderPageWidgets.orderDetails.info.str1") ?? ""}
				>
					{feeding}
				</Field>
				<Field
					area="completion"
					label={t("orderPageWidgets.orderDetails.info.str2") ?? ""}
				>
					{completion}
				</Field>
				<Field
					area="status"
					label={t("orderPageWidgets.orderDetails.info.str3") ?? ""}
				>
					{status}
				</Field>
				<Field
					area="client"
					label={t("orderPageWidgets.orderDetails.info.str4") ?? ""}
				>
					{client}
				</Field>
				<Field
					area="executor"
					label={t("orderPageWidgets.orderDetails.info.str5") ?? ""}
				>
					{executor}
				</Field>
				<Field
					area="classes"
					label={t("orderPageWidgets.orderDetails.info.str6") ?? ""}
				>
					{classes}
				</Field>

				<Field
					area="parameters"
					label={t("orderPageWidgets.orderDetails.info.str7") ?? ""}
				>
					{parameters}
				</Field>

				<Field
					area="services"
					label={t("orderPageWidgets.orderDetails.info.str8") ?? ""}
				>
					{services}
				</Field>

				<Field
					area="notes"
					label={t("orderPageWidgets.orderDetails.info.str9") ?? ""}
				>
					{notes}
				</Field>
			</Top>
			<Divider
				orientation="horizontal"
				size="max"
				color="rgb(33, 51, 63, 0.15)"
			/>
			<Bottom
				hasHourlyTimeField={hasHourlyTimeField}
				compactLevel={compactLevel}
			>
				<Field
					area="distance"
					label={t("orderPageWidgets.orderDetails.info.str10") ?? ""}
				>
					{distance}
				</Field>
				<Field
					area="out-of-town-distance"
					label={t("orderPageWidgets.orderDetails.info.str200") ?? ""}
				>
					<span>{outOfTownDistanceLabel}</span>
					{outOfTownDistanceLabel === "-" ||
					isUndefined(outOfTownDistanceValue)
						? null
						: isShowBtn && (
								<EditButton
									value={outOfTownDistanceValue}
									title={
										t(
											"orderPageWidgets.orderDetails.info.str200",
										) ?? ""
									}
									units={t(`units.km`) ?? "km"}
									onSubmit={updateOutOfTownDistance}
								/>
						  )}
				</Field>
				<Field
					area="idle-time"
					label={t("orderPageWidgets.orderDetails.info.str11") ?? ""}
				>
					<span>{idleTimeLabel}</span>
					{idleTimeLabel === "-" || isUndefined(idleTimeValue)
						? null
						: isShowBtn && (
								<EditButton
									value={idleTimeValue}
									title={
										t(
											"orderPageWidgets.orderDetails.info.str11",
										) ?? ""
									}
									units={t(`units.minute`) ?? "min"}
									onSubmit={updateIdleTime}
								/>
						  )}
				</Field>
				<Field
					area="price"
					label={t("orderPageWidgets.orderDetails.info.str13") ?? ""}
				>
					{priceLabel === "-" || isUndefined(priceValue)
						? priceLabel
						: isShowBtn && (
								<>
									<PriceLabel>{priceLabel}</PriceLabel>
									<EditButton
										value={priceValue}
										title={
											t(
												"orderPageWidgets.orderDetails.info.str13",
											) ?? ""
										}
										units={currencyGlobalSettings}
										onSubmit={updatePrice}
										useMinSet
									/>
									<AdditionalPriceLabel>
										{discountLabel && `${discountLabel}`}
									</AdditionalPriceLabel>
								</>
						  )}
				</Field>
				<Field
					area="additional-price"
					label={t("orderPageWidgets.orderDetails.info.str209") ?? ""}
				>
					<span>{additionalPriceLabel}</span>
					{additionalPriceLabel === "-" ||
					isUndefined(additionalPriceValue)
						? null
						: isShowBtn && (
								<EditButton
									value={additionalPriceValue}
									title={
										t(
											"orderPageWidgets.orderDetails.info.str209",
										) ?? ""
									}
									units={currencyGlobalSettings}
									onSubmit={updateAdditionalCost}
								/>
						  )}
				</Field>
				<Field
					area="awaiting-time"
					label={t("orderPageWidgets.orderDetails.info.str15") ?? ""}
				>
					<span>{awaitingTimeLabel}</span>
					{awaitingTimeLabel === "-" || isUndefined(awaitingTimeValue)
						? null
						: isShowBtn && (
								<EditButton
									value={awaitingTimeValue}
									title={
										t(
											"orderPageWidgets.orderDetails.info.str15",
										) ?? ""
									}
									units={t(`units.minute`) ?? "min"}
									onSubmit={updateAwaitingTime}
								/>
						  )}
				</Field>
				{hasHourlyTimeField && (
					<Field
						area="hourly-time"
						label={
							t("orderPageWidgets.orderDetails.info.str17") ?? ""
						}
					>
						<span>{hourlyTimeLabel}</span>
						{awaitingTimeLabel === "-" ||
						isUndefined(hourlyTimeValue)
							? null
							: isShowBtn && (
									<EditButton
										value={hourlyTimeValue}
										title={
											t(
												"orderPageWidgets.orderDetails.info.str17",
											) ?? ""
										}
										units={t(`units.minute`) ?? "min"}
										onSubmit={updateHourlyTime}
									/>
							  )}
					</Field>
				)}
			</Bottom>
		</Root>
	);
};

declare namespace Info {
	interface Props {
		language: Language;
	}
}

export default Info;
