import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";

import TaxiService from "../../services/TaxiService2";

interface State {
	models: TaxiService.Model[];
	transferModels: TaxiService.TransferModel[];
}

type Reducer<Payload> = CaseReducer<State, PayloadAction<Payload>>;

const setModels: Reducer<State["models"]> = (state, { payload }) => {
	state.models = payload;
};

const setTransferModels: Reducer<State["transferModels"]> = (
	state,
	{ payload },
) => {
	state.transferModels = payload;
};

const initialState: State = {
	models: [],
	transferModels: [],
};

const taxiServices = createSlice({
	name: "taxiServices",
	initialState,
	reducers: {
		setModels,
		setTransferModels,
	},
});

export default taxiServices;
