import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useState,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { react } from "uikit";
import { isString } from "lodash";

import SelectWithModal from "../../../../../../../../../../components/SelectWithModal";
import { MultiSelectCarModelIdWithModal } from "../../../../../../../../../../components/common/Modal";
import { EntityEditModal } from "../..";

import InternalController from "./Controller";

const CarModelSelect = react.withController<
	CarModelSelect.Props,
	CarModelSelect.Controller
>(
	({
		controller,

		value,

		disabled,
		valueSearch,
		saveSearch,
		onChange,
		saveIds,
		valueIds,
	}) => {
		const { t } = useTranslation();
		const [error, setError] = useState(false);

		const subscribeOptions = useMemo(() => {
			const retval = {
				...(valueIds.carBaseTypeIds?.length > 0
					? { carBaseTypeIds: valueIds.carBaseTypeIds }
					: {}),
				...(valueIds.carBrandIds?.length > 0
					? { carBrandIds: valueIds.carBrandIds }
					: {}),
			};
			return retval;
		}, [valueIds.carBaseTypeIds, valueIds.carBrandIds]);

		const selectOnChange = useCallback(
			(newValue: SelectWithModal.Value<number | string>) => {
				setError(false);

				onChange(newValue as CarModelSelect.Value);
			},
			[onChange],
		);

		controller.setContext({ value, setError });

		return (
			<MultiSelectCarModelIdWithModal
				value={value}
				typeValue="label"
				disabled={disabled}
				error={disabled ? false : error}
				onChange={selectOnChange}
				titleText={
					t(
						"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.carModelId.str0",
					) ?? ""
				}
				subscribeOptions={subscribeOptions}
				modalSetting={{
					zIndex: 999,
					containerId: "root",
				}}
				sort={{ sortType: "asc" }}
				valueSearch={valueSearch}
				saveSearch={saveSearch}
				saveIds={saveIds}
			/>
		);
	},
	InternalController,
);

declare namespace CarModelSelect {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = string | undefined;

	interface PropsBase {
		value: Value;

		disabled: boolean;
		valueSearch: string;
		saveSearch: (value: string) => void;
		saveIds: (value: number[]) => void;
		onChange: Dispatch<Value>;
		valueIds: EntityEditModal.ValueIds;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default CarModelSelect;
