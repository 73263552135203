/* eslint-disable no-shadow */

import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useState,
	useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { react } from "uikit";
import { isString } from "lodash";

import SelectWithModal from "../../../../../../../../../../components/SelectWithModal";
import { MultiSelectCarBodyTypeIdWithModal } from "../../../../../../../../../../components/common/Modal";
import { EntityEditModal } from "../..";

import InternalController from "./Controller";

const CarBodyTypeSelect = react.withController<
	CarBodyTypeSelect.Props,
	CarBodyTypeSelect.Controller
>(
	({
		controller,

		value,

		disabled,

		valueSearch,
		saveSearch,
		onChange,
		saveIds,
		valueIds,
	}) => {
		const { t } = useTranslation();
		const [error, setError] = useState(false);

		const subscribeOptions = useMemo(() => {
			const retval = {
				...(valueIds.carModelIds?.length > 0
					? { carModelIds: valueIds.carModelIds }
					: {}),
			};
			return retval;
		}, [valueIds.carModelIds]);

		const selectOnChange = useCallback(
			(newValue: SelectWithModal.Value<number | string>) => {
				setError(false);

				onChange(newValue as CarBodyTypeSelect.Value);
			},
			[onChange],
		);

		controller.setContext({ value, setError });

		return (
			<MultiSelectCarBodyTypeIdWithModal
				value={value}
				typeValue="label"
				disabled={disabled}
				error={disabled ? false : error}
				onChange={selectOnChange}
				subscribeOptions={subscribeOptions}
				titleText={
					t(
						"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.carBodyTypeId.str0",
					) ?? ""
				}
				modalSetting={{
					zIndex: 999,
					containerId: "root",
				}}
				sort={{ sortType: "asc" }}
				valueSearch={valueSearch}
				saveSearch={saveSearch}
				saveIds={saveIds}
			/>
		);
	},
	InternalController,
);

declare namespace CarBodyTypeSelect {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = string | undefined;

	interface PropsBase {
		value: Value;

		disabled: boolean;

		valueSearch: string;
		saveSearch: (value: string) => void;
		onChange: Dispatch<Value>;
		saveIds: (value: number[]) => void;
		valueIds: EntityEditModal.ValueIds;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default CarBodyTypeSelect;
