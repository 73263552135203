import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { isUndefined } from "lodash";

import { RoundingMethod } from "../../../../../../../../../redux/constants/OrdersPage/order";
import useObjectEditor from "../../../../../../../../../hooks/useObjectEditor";
import { EditIcon } from "../../../../../../../../../icons/edit";
import { useTypedSelector } from "../../../../../../../../../redux/store";
import { CurrencyDisplayStyle } from "../../../../../../../../Settings/pages/Tariffs/tabs/Additional/components/Modal/components/Content/tabs/Main/components/Currency/constants";
import {
	NumberInput,
	NumberInputMemo,
} from "../../../../../../../../../components/Orders";
import { StyledRow } from "../../../../../../../../../components/common";
import { OrderFormProps } from "../../../types/FormProps";
import { Green, Primary, Red } from "../../styled";

import Clickable from "./Clickable";

interface Props extends OrderFormProps {
	edit: boolean;
	setEdit: (edit: boolean) => void;
}

const Price: React.FC<Props> = ({ disabled, edit, setEdit, form, tab }) => {
	const { t } = useTranslation();

	const [value, onChangeValue] = useState<NumberInput.Value>({
		action: "add",
		value: 0,
	});

	const additionalTariffSettings = useTypedSelector((state) =>
		state.additionalTariffs?.models?.at?.(0),
	);

	const displayStyle = useMemo(() => {
		const displayStyle =
			additionalTariffSettings?.additionalFields.general.currency
				.displayStyle ?? CurrencyDisplayStyle.NAME;
		return displayStyle;
	}, [
		additionalTariffSettings?.additionalFields?.general?.currency
			?.displayStyle,
	]);

	const valueEditor = useObjectEditor(value, onChangeValue);

	const setAction = valueEditor.useSetter("action");
	const valueCost = valueEditor.useGetter("value");
	const setValue = valueEditor.useSetter("value");

	const watchedPrice = useMemo(() => tab.form?.price ?? 0, [tab.form?.price]);

	const roundingMultipleValue = useMemo(
		() => tab.form?.priceSettings?.rounding?.multiple?.value ?? 0,
		[tab.form?.priceSettings?.rounding?.multiple?.value],
	);
	const roundingMultipleActive = useMemo(
		() => tab.form?.priceSettings?.rounding?.multiple?.active ?? false,
		[tab.form?.priceSettings?.rounding?.multiple?.active],
	);
	const roundingMethod = useMemo(
		() =>
			tab.form?.priceSettings?.rounding?.method ?? RoundingMethod.GENERAL,
		[tab.form?.priceSettings?.rounding?.method],
	);

	const precision = useMemo(
		() => tab.form?.priceSettings?.rounding?.precision ?? 2,
		[tab.form?.priceSettings?.rounding?.precision],
	);

	const additionalCost = useMemo(
		() => tab.form?.additionalCost || 0,
		[tab.form?.additionalCost],
	);

	const currencyName = useMemo(
		() => tab.form?.currency?.settings?.[displayStyle] || "",
		[tab.form?.currency, displayStyle],
	);

	const discountValue = useMemo(() => {
		const bonusAmount = tab.form?.discountCost?.bonusAmount || 0;
		return bonusAmount;
	}, [tab.form?.discountCost?.bonusAmount]);

	const stringifyPrice = useCallback(
		(price: number | undefined) => {
			if (isUndefined(price)) return "-";

			let processedPrice = price;

			if (roundingMultipleActive) {
				processedPrice /= roundingMultipleValue;
			} else {
				processedPrice *= 10 ** precision;
			}

			switch (roundingMethod) {
				case RoundingMethod.GENERAL:
					processedPrice = Math.round(processedPrice);
					break;
				case RoundingMethod.CEIL:
					processedPrice = Math.ceil(processedPrice);
					break;
				case RoundingMethod.FLOOR:
					processedPrice = Math.floor(processedPrice);
					break;
				default:
					break;
			}

			if (roundingMultipleActive) {
				processedPrice *= roundingMultipleValue;
			} else {
				processedPrice /= 10 ** precision;
			}

			return `${processedPrice.toFixed(precision)}`.trim();
		},
		[
			precision,
			roundingMethod,
			roundingMultipleActive,
			roundingMultipleValue,
		],
	);

	useLayoutEffect(() => {
		if (valueCost !== watchedPrice) {
			setValue(Number(stringifyPrice(watchedPrice)));
		}
	}, [watchedPrice, setValue, valueCost, stringifyPrice]);

	const onChange = useCallback(
		(data: NumberInput.Value) => {
			setAction(data.action);

			if (data.action === "set") {
				const dataValue = data.value;

				setValue(Number(stringifyPrice(dataValue)));
				if (dataValue >= 0) {
					form.setValue(
						"additionalCost",
						dataValue -
							Number(watchedPrice) +
							Number(additionalCost),
					);
					setEdit(false);
					return;
				}

				if (dataValue < 0) {
					const prevPrice =
						Number(watchedPrice) - Number(additionalCost);
					const cost = -(prevPrice - dataValue);

					if (cost + prevPrice <= 0) {
						form.setValue(
							"additionalCost",
							-Number(stringifyPrice(prevPrice)),
						);
						setEdit(false);
						return;
					}

					form.setValue(
						"additionalCost",
						Number(stringifyPrice(cost)),
					);
				}
			} else {
				const dataValue = data.value;
				const payload = Number(additionalCost) + dataValue;
				const prevPrice = Number(watchedPrice) - Number(additionalCost);
				const cost = dataValue + Number(additionalCost);

				if (cost + prevPrice <= 0) {
					form.setValue(
						"additionalCost",
						-Number(stringifyPrice(prevPrice)),
					);
					setEdit(false);
					return;
				}

				if (prevPrice + payload >= 0) {
					form.setValue(
						"additionalCost",
						Number(stringifyPrice(payload)),
					);
					setEdit(false);
					return;
				}

				setValue(payload);
				form.setValue(
					"additionalCost",
					Number(stringifyPrice(payload)),
				);
				setEdit(false);
			}
			setEdit(false);
		},
		[
			setAction,
			additionalCost,
			watchedPrice,
			setEdit,
			setValue,
			stringifyPrice,
			form,
		],
	);

	const priseDiscount = useMemo(
		() =>
			`${discountValue > 0 ? discountValue : ""} ${
				discountValue > 0 ? currencyName : ""
			}`,
		[discountValue, currencyName],
	);

	return (
		<StyledRow alignItems="end" gap="0 6px" m="0 0 6px 0">
			<Primary textAlign="start">{`${t([
				`orderPage.order_form.price`,
				"Price",
			])}: `}</Primary>
			<Red>{`${stringifyPrice(watchedPrice)} ${currencyName}`}</Red>

			{!disabled && (
				<Clickable onClick={() => setEdit(true)}>
					<EditIcon />
				</Clickable>
			)}

			{edit && (
				<NumberInputMemo
					shadow
					title={t([`orderPage.order_form.price`, "Price"]) ?? ""}
					unit={currencyName ?? ""}
					value={value}
					onSubmit={onChange}
					onClose={() => setEdit(false)}
					useMinSet
				/>
			)}

			<Green>{priseDiscount}</Green>
		</StyledRow>
	);
};

export default Price;
