import { Table as TableBase, TableProps } from "rsuite";
import styled from "styled-components";
import { theme } from "uikit";

const Table = styled(TableBase)<TableProps<any, any>>`
	.rs-table-row-header {
		box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
		background-color: ${theme.colors.button_secondary};
	}

	.rs-table-row:not(.rs-table-row-header) {
		&.selected {
			& .rs-table-cell {
				background-color: #eaf8fe !important;
			}
		}

		&.not-active {
			& .rs-table-cell,
			& .rs-table-cell-content {
				color: ${theme.colors.disabled_text} !important;
			}
		}
	}
` as typeof TableBase;

export default Table;
