import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import JSPDF from "jspdf";
import JSZip from "jszip";
import autoTable, { RowInput, UserOptions } from "jspdf-autotable";
import { DatePicker } from "uikit";

import { getExcelCurrencyFormat, createLogger } from "../../utils";
import { DateRange } from "../../types/DataRange";
import IResponseWithItems from "../../types/IResponse";
import {
	formatDate,
	formatMoney,
} from "../../pages/Reports/pages/AccountingReports/utils/formatForFile";
import {
	TransactionAction,
	TransactionMetadataContext,
} from "../Transaction/types";
import { Base, Language } from "..";

const logger = createLogger({ name: "AgentBillReport" });

class AgentBillReport extends Base {
	static fromResponse(data: any): AgentBillReport.Model {
		return data || [];
	}

	public static async getAgentBillReport(
		params: AgentBillReport.SearchOptions,
	): Promise<AgentBillReport.GetAgentBillReportResponse | null> {
		try {
			if (!params.dateRange) return null;

			const response = await this.request((prpc) =>
				prpc.theirsModel.report.getAgentBillReport(params),
			);

			logger.info("[getAgentBillReport] getAgentBillReport res", {
				response,
			});
			if (!response) return null;

			return {
				...response,
				items: response?.items?.map(this.fromResponse),
			};
		} catch (error) {
			logger.error("[getAgentBillReport] Error", error);

			return null;
		}
	}

	public static exportPDF = async (
		data: AgentBillReport.Model.Item[],
		options: {
			tableColumn: string[];
			columnStyles: UserOptions["columnStyles"];
			width: number;
			height: number;

			ColumnId: any;
			columnIds: any;
			textTranslations: Record<string, string>;
			headerTitle: string;
		},
	) => {
		if (!data?.length) return;
		const {
			tableColumn = [],
			width = 3500,
			height = 780,

			columnStyles,
			ColumnId,
			columnIds,
			textTranslations,
			headerTitle,
		} = options;

		const totalWidth = columnStyles
			? Object.values(columnStyles).reduce(
					(sum, { cellWidth }) => sum + +(cellWidth || 0),
					0,
			  ) +
			  Object.keys(columnStyles).length * (5.1 / 3.56) +
			  0.1 / 3
			: width;

		const realWidth = totalWidth || width;
		const doc = new JSPDF({
			orientation: "landscape",
			format: [realWidth, height],
		});

		let currentY = 10;
		let _dateFrom;
		let _dateTo;

		data.forEach(({ transactions, dateRange, counter }) => {
			doc.setFont("Roboto-Regular", "normal");

			const textName = transactions?.[0]?.name || "";

			const dateFrom = formatDate({
				date: dateRange.from,
				isTitleFormat: true,
			});

			const dateTo = formatDate({
				date: dateRange.to,
				isTitleFormat: true,
			});

			if (!_dateFrom) _dateFrom = dateFrom;
			if (!_dateTo) _dateTo = dateTo;

			const pageWidth = doc.internal.pageSize.getWidth();
			const centerX = pageWidth / 2;

			const textArray = [
				`${textName} ${textTranslations.forPeriod} ${textTranslations.from} ${dateFrom} ${textTranslations.to} ${dateTo}`,
			];

			textArray.forEach((text) => {
				const textWidth = doc.getTextWidth(text);

				const textX = centerX - textWidth / 2;

				doc.text(text, textX, currentY);

				currentY += 10;
			});

			const tableRows = transactions.map((order) => {
				const columnIdSet = new Set(columnIds);

				const retval: RowInput = [];

				if (columnIdSet.has(ColumnId.Date)) {
					retval.push({
						content: formatDate({ date: order.createdAt }),
					});
				}

				if (columnIdSet.has(ColumnId.OperationType)) {
					retval.push({
						content: `${order.type ?? " "}`,
					});
				}

				if (columnIdSet.has(ColumnId.Source)) {
					retval.push({
						content: `${order.targetAction ?? " "}`,
					});
				}

				if (columnIdSet.has(ColumnId.Order)) {
					retval.push({
						content: `${order.orderNumber ?? " "}`,
					});
				}

				if (columnIdSet.has(ColumnId.OperationDescription)) {
					retval.push({
						content: `${order.description ?? " "}`,
					});
				}

				if (columnIdSet.has(ColumnId.User)) {
					retval.push({
						content: `${order.name ?? " "}`,
					});
				}

				if (columnIdSet.has(ColumnId.AmountUah)) {
					retval.push({
						content: `${formatMoney(order.amount)}`,
					});
				}

				if (columnIdSet.has(ColumnId.BalanceUah)) {
					retval.push({
						content: `${formatMoney(order.accountAmountAfter)}`,
					});
				}

				return [...retval] as RowInput;
			});

			autoTable(doc, {
				head: [tableColumn],
				body: tableRows,
				startY: currentY,
				styles: {
					fontSize: 12,
					cellPadding: 5,
					lineWidth: 0.1,
					lineColor: [0, 0, 0],
					font: "Roboto-Regular",
					fontStyle: "normal",
				},
				columnStyles,
				headStyles: {
					fillColor: [41, 128, 185],
					textColor: [255, 255, 255],
					fontSize: 10,
				},
				margin: { top: 20 },
				tableLineWidth: 0,
				tableLineColor: [0, 0, 0],
				theme: "grid",
			});

			currentY = doc?.lastAutoTable?.finalY;

			doc.setFont("Roboto-Regular", "normal");
			doc.text(
				[`${textTranslations.feeForPeriod}: ${counter.amount ?? ""}`],
				30,
				currentY + 20,
			);
			currentY += 50;
		});

		const namefile = `${headerTitle ?? ""} - ${_dateFrom ?? ""} - ${
			_dateTo ?? ""
		}.pdf`;

		doc.save(namefile);
	};

	public static exportExcel = async (
		_data: AgentBillReport.Model.Item[],
		options: {
			tableColumn: string[];
			columnStyles: UserOptions["columnStyles"];
			width: number;
			height: number;
			ColumnId: any;
			columnIds: any;
			textTranslations: Record<string, string>;
			headerTitle: string;
			isZip: boolean;
			dateFrom: DatePicker.Value;
			dateTo: DatePicker.Value;
		},
	) => {
		if (!_data?.length) return;

		const {
			tableColumn = [],

			columnStyles,
			ColumnId,
			columnIds,
			textTranslations,
			headerTitle,
			isZip,
			dateFrom,
			dateTo,
		} = options;

		const _dateFrom = formatDate({
			date: dateFrom ?? new Date(),
			isTitleFormat: true,
		});
		const _dateTo = formatDate({
			date: dateTo ?? new Date(),
			isTitleFormat: true,
		});

		const namefile = `${headerTitle ?? ""} - ${_dateFrom ?? ""} - ${
			_dateTo ?? ""
		}`;

		const zip = new JSZip();
		const workbook = new ExcelJS.Workbook();

		const clearWorksheets = () => {
			workbook.worksheets.forEach((sheet) =>
				workbook.removeWorksheet(sheet.name),
			);
		};

		const createReport = async (data: AgentBillReport.Model.Item[]) => {
			const CURRENCY_DEFAULT = "₴"; // ! Here we need a dynamic currency. We need to get the currency value from the back
			const worksheet = workbook.addWorksheet(namefile);

			tableColumn.forEach((colIndex, index) => {
				const cellWidth: number = +(
					columnStyles?.[index]?.cellWidth ?? 80
				);

				if (typeof cellWidth === "number" && cellWidth > 0) {
					worksheet.getColumn(index + 1).width = Math.round(
						cellWidth / 2.5,
					);
				} else {
					logger.warn(
						`2 Width for column ${colIndex} is undefined or invalid: cellWidth`,
						cellWidth,
					);
				}
			});

			data.forEach(({ transactions, dateRange, counter }) => {
				const textName = transactions?.[0]?.name || "";

				const dateFrom = formatDate({
					date: dateRange.from,
					isTitleFormat: true,
				});
				const dateTo = formatDate({
					date: dateRange.to,
					isTitleFormat: true,
				});

				const addHeaderRow = (text: string) => {
					const headerRow = worksheet.addRow([text]);
					headerRow.font = {
						size: 10,
						name: "Arial",
					};
					headerRow.alignment = {
						horizontal: "left",
						vertical: "middle",
						wrapText: false,
					};

					headerRow.height = 30;

					worksheet.mergeCells(
						`A${headerRow.number}:F${headerRow.number}`,
					);

					headerRow.getCell(1).alignment = {
						horizontal: "center",
						vertical: "middle",
					};
				};

				const textheaderTable = [
					`${textName} ${textTranslations.forPeriod} ${textTranslations.from} ${dateFrom} ${textTranslations.to} ${dateTo}`,
				];

				textheaderTable.forEach((text) => {
					addHeaderRow(text);
				});

				const titleRow = worksheet.addRow(tableColumn);
				titleRow.font = {
					name: "Calibri",
					size: 11,
					color: { argb: "ffffff" },
				};
				titleRow.alignment = {
					horizontal: "center",
					vertical: "middle",
					wrapText: true,
				};
				titleRow.fill = {
					type: "pattern",
					pattern: "solid",
					fgColor: { argb: "4baac6" },
				};
				titleRow.border = {
					top: { style: "thin", color: { argb: "FF000000" } },
					bottom: { style: "thin", color: { argb: "FF000000" } },
				};

				const maxHeight = Math.min(
					3,
					Math.max(
						...tableColumn.map((cell) => {
							if (typeof cell === "string") {
								return Math.ceil(cell.length / 10);
							}
							return 1;
						}),
					),
				);

				titleRow.height = maxHeight * 13;

				transactions.forEach((order, index) => {
					const row: (string | number | undefined | Date | null)[] =
						[];

					const columnIdSet = new Set(columnIds);

					if (columnIdSet.has(ColumnId.Date)) {
						const whenDate = order.createdAt
							? new Date(order.createdAt)
							: null;
						row.push(whenDate);
					}

					if (columnIdSet.has(ColumnId.OperationType))
						row.push(order.type || "");

					if (columnIdSet.has(ColumnId.Source))
						row.push(order.targetAction || "");

					if (columnIdSet.has(ColumnId.Order))
						row.push(order.orderNumber || "");

					if (columnIdSet.has(ColumnId.OperationDescription))
						row.push(order.description || "");

					if (columnIdSet.has(ColumnId.User))
						row.push(order.name || "");

					if (columnIdSet.has(ColumnId.AmountUah)) {
						const formattedPrice = formatMoney(order.amount);
						row.push(parseFloat(formattedPrice));
					}

					if (columnIdSet.has(ColumnId.BalanceUah)) {
						const formattedPrice = formatMoney(
							order.accountAmountAfter,
						);
						row.push(parseFloat(formattedPrice));
					}

					function isOdd(num: number): boolean {
						return num % 2 !== 0;
					}

					const newRow = worksheet.addRow(row);
					newRow.alignment = {
						horizontal: "center",
						vertical: "middle",
						wrapText: true,
					};
					newRow.font = { name: "Arial", size: 10 };

					const getColumnIndex = (columnId) =>
						Array.from(columnIdSet).indexOf(columnId) + 1;

					if (columnIdSet.has(ColumnId.Date)) {
						const createdCell = newRow.getCell(
							getColumnIndex(ColumnId.Date),
						);

						createdCell.numFmt = "DD.MM.YYYY HH:mm:ss";
						createdCell.alignment = { wrapText: true };
					}

					if (columnIdSet.has(ColumnId.AmountUah)) {
						const amountCell = newRow.getCell(
							getColumnIndex(ColumnId.AmountUah),
						);

						amountCell.numFmt =
							getExcelCurrencyFormat(CURRENCY_DEFAULT);
						amountCell.alignment = { wrapText: true };
					}

					if (columnIdSet.has(ColumnId.BalanceUah)) {
						const amountCell = newRow.getCell(
							getColumnIndex(ColumnId.BalanceUah),
						);

						amountCell.numFmt =
							getExcelCurrencyFormat(CURRENCY_DEFAULT);
						amountCell.alignment = { wrapText: true };
					}

					if (isOdd(index)) {
						newRow.fill = {
							type: "pattern",
							pattern: "solid",
							fgColor: { argb: "d9d9d9" },
						};
					}
					if (transactions.length - 1 === index) {
						newRow.border = {
							bottom: {
								style: "thin",
								color: { argb: "FF000000" },
							},
						};
					}
					const maxHeight = Math.min(
						3,
						Math.max(
							...row.map((cell) => {
								if (typeof cell === "string") {
									return Math.ceil(cell.length / 10);
								}
								return 1;
							}),
						),
					);

					newRow.height = maxHeight * 13;

					newRow.alignment = {
						horizontal: "center",
						vertical: "middle",
						wrapText: true,
					};
				});

				const footerThree = worksheet.addRow([
					`${textTranslations.feeForPeriod}:`,
					counter.amount || 0,
				]);

				footerThree.eachCell((cell) => {
					cell.border = {
						top: { style: "thin" },
						left: { style: "thin" },
						bottom: { style: "thin" },
						right: { style: "thin" },
					};
					cell.alignment = {
						horizontal: "center",
						vertical: "middle",
					};
					cell.font = { name: "Calibri", size: 11 };
					cell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "F9F9F9" },
					};
				});

				worksheet.addRow([]);
				worksheet.addRow([]);
			});

			const buffer = await workbook.xlsx.writeBuffer();

			if (isZip) {
				const agentName = data?.[0]?.transactions?.[0]?.name || "";

				zip.file(`${agentName ?? "Report"}.xlsx`, buffer);
			} else {
				const blob = new Blob([buffer], {
					type: "application/octet-stream",
				});
				saveAs(blob, nameFileExcelOnly);
			}
		};

		const nameFileZip = `${namefile}.zip`;
		const nameFileExcelOnly = `${namefile}.xlsx`;

		if (isZip) {
			for (let index = 0; index < _data.length; index++) {
				clearWorksheets();
				await createReport([_data[index]]);
			}

			const zipContent = await zip.generateAsync({ type: "blob" });
			saveAs(zipContent, nameFileZip);
		} else {
			createReport(_data);
		}
	};
}

declare namespace AgentBillReport {
	type Model = AgentBillReport.Model.Item;

	interface GetAgentBillReportResponse extends IResponseWithItems<Model> {}

	interface SearchOptions {
		agentIds?: number[];
		taxiServiceIds?: number[];
		dateRange: DateRange;
		lang: Language;
	}

	namespace Model {
		interface Counter {
			amount: number;
		}
		interface TransactionItem {
			id: number;
			agentId: number;
			orderNumber: number;
			createdAt: string | Date;
			type: TransactionMetadataContext | string;
			amount: number;
			comment: string;
			accountAmountAfter: number;
			accountAmountBefore: number;
			description: string;
			targetAction: TransactionAction | string;
			name: string;
		}
		interface Item {
			dateRange: DateRange;
			transactions: TransactionItem[];
			counter: Counter;
		}
	}
}

export default AgentBillReport;
