import { Dispatch } from "react";
import { react } from "uikit";

import CarBrandSelect from ".";

interface Context {
	value: CarBrandSelect.Value;

	setError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const value = this.context?.value;
		const isValueValid =
			typeof this.context?.value !== "undefined" && value !== "";

		if (!isValueValid) this.context?.setError(true);

		return isValueValid;
	}
}
