import React, {
	Dispatch,
	memo,
	useCallback,
	useMemo,
	useState,
	useEffect,
} from "react";
import { useTranslation } from "react-i18next";

import { CarClass, Service } from "../../../../services";
import { useTypedSelector } from "../../../../redux/store";
import MultiSelectCompatibleServicesWithModal, {
	MultiSelectWithModal,
} from "../MultiSelectWithModal";

const MultiSelectCarParkWithModalBase: React.FC<
	MultiSelectCarParkWithModalBase.Props
> = ({
	value,
	text = "",
	onChange,
	startingValueOriginId,
	disabled,
	titleText,
	required,
	modalSetting,
	sort,
	items = [],
	...props
}) => {
	const { t } = useTranslation();

	const language = useTypedSelector((state) => state.session.language);

	const [error, setError] = useState("");

	const selectOptions = useMemo<MultiSelectCompatibleServicesWithModal.Value>(
		() =>
			items.map((item) => ({
				key: item.id,
				label: item.name?.[language] ?? "Unknown",
				value: item.id,
				name: item.name?.[language] ?? "Unknown",
				data: item,
			})),
		[items, language],
	);

	const selected = useMemo<MultiSelectCompatibleServicesWithModal.Value>(
		() =>
			selectOptions.filter((item) => {
				if (
					value?.length === 0 &&
					typeof startingValueOriginId !== "number"
				) {
					return text === item.label;
				}

				// value.find((data) => data === item.value),
				return value?.length === 0
					? undefined
					: value.find((data) => data === item.value);
			}),

		[selectOptions, startingValueOriginId, text, value],
	);

	useEffect(() => {
		if (value?.length === 0 && typeof startingValueOriginId !== "number") {
			const foundPreChoise = selectOptions.find(
				(item) => item.label === text,
			);
			if (foundPreChoise) {
				onChange([foundPreChoise.value]);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const selectOnChange = useCallback(
		(newValue) => {
			setError("");
			const Ids = newValue.map((item) => item.value);
			onChange(Ids);
		},
		[onChange],
	);

	const texts = useMemo(
		() => ({
			title: t(titleText),
			allText: "",
			allActiveText: "",
		}),
		[t, titleText],
	);

	return (
		<MultiSelectWithModal
			error={error}
			disabled={disabled}
			value={selected}
			title={texts.title}
			allText={texts.allText}
			allActiveText={texts.allActiveText}
			options={selectOptions}
			onChange={selectOnChange}
			required={required}
			pullUpItemInsideArray
			hiddenButton={false}
			showSelectAll
			modalSetting={modalSetting}
			sort={{
				show: true,
				active: true,
				...(sort || {}),
			}}
			{...props}
		/>
	);
};

export const MultiSelectCarParkWithModal = memo(
	MultiSelectCarParkWithModalBase,
);

declare namespace MultiSelectCarParkWithModalBase {
	interface Props extends MultiSelectCompatibleServicesWithModal.Setting {
		value: Value;
		startingValueOriginId?: number;
		onChange: Dispatch<Value>;
		titleText: string | string[];
		modalSetting?: MultiSelectCompatibleServicesWithModal.ListSelectProps["modalSetting"];
		items: (CarClass.Model | Service.Model)[];
		text: string;
		heightSelect?: string;
	}

	type Value = number[];
}

export default MultiSelectCarParkWithModalBase;
