import React, { useCallback, CSSProperties, useMemo } from "react";
import { Option, useInternal, Key, theme, Button } from "uikit";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

import {
	StyledRow,
	StyledColumn,
	SuspenseLoader,
} from "../../../../../../../../../../components/common";
import {
	CardModal,
	CardModalBase,
} from "../../../../../../../../../../components/common/Modal";

const Root = styled(StyledColumn)`
	overflow: hidden;
	border-radius: 5px;
	background-color: ${theme.colors.white};
	width: 500px;
	max-height: 90vh;
	display: flex;
	flex-direction: column;
`;

const ContentWrapper = styled.div`
	flex: 1;
	overflow-y: auto;
	padding: 20px;
	min-height: 600px;
	gap: 20px;
`;

const SelectModal = <OptionValue, ValueType extends Key>({
	value,
	options,
	onClose,
	onSubmit,
	pullUpItemInsideArray,
	children,
	canSave = true,
	dimmed = true,
	loading,
	...props
}: SelectModal.Props<OptionValue, ValueType>) => {
	const { t } = useTranslation();

	const submitText: string = useMemo(() => t("ok"), [t]);

	const footerOnCancel = useCallback(() => onClose?.(), [onClose]);
	const footerOnSubmit = useCallback(() => onSubmit?.(), [onSubmit]);

	return (
		<CardModal
			{...props}
			onClose={footerOnCancel}
			onSave={footerOnSubmit}
			textSave={submitText}
			dimmed={true}
			draggable={false}
		>
			<Root>
				<ContentWrapper>{children}</ContentWrapper>
			</Root>
		</CardModal>
	);
};

declare namespace SelectModal {
	type Value<Type extends Key> = Type | undefined;

	interface Props<OptionValue, ValueType extends Key>
		extends Omit<CardModalBase.Props, "onSave"> {
		pullUpItemInsideArray?: boolean;
		value?: Value<ValueType>;
		options?: Option<OptionValue>[];

		onClose: () => void;
		onSubmit: () => void;
		footerJustify?: CSSProperties["justifyContent"];
		dimmed?: boolean;
		canSave?: boolean;
		loading: boolean;
	}
}

export default SelectModal;
