import React, { useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { Gray, Primary } from "../../styled";
import { StyledRow } from "../../../../../../../../../components/common";
import { OrderFormProps } from "../../../types/FormProps";

const ArrivalTime: React.FC<ArrivalTime.Props> = ({ tab }) => {
	const { t } = useTranslation();
	const lastReceivedOrder = useMemo(
		() => tab?.form?.transfer?.lastReceivedOrder,
		[tab?.form?.transfer?.lastReceivedOrder],
	);

	const arrivalTransfer = useMemo(
		() =>
			lastReceivedOrder?.executorToOrder?.at(0)?.additionalFields
				?.arrivalTime,
		[lastReceivedOrder?.executorToOrder],
	);

	const arrival = useMemo(
		() => tab?.form?.arrivalTime || arrivalTransfer,
		[tab?.form?.arrivalTime, arrivalTransfer],
	);
	const arrivalTime = useMemo(() => {
		if (arrival) {
			const time = moment(arrival).format("HH:mm:ss");
			const date = moment(arrival).format("DD.MM.YYYY");
			return { time, date };
		}
		return { time: "-", date: "" };
	}, [arrival]);

	return (
		<StyledRow gap="0 6px">
			<Primary>
				{`${t([`orderPage.order_form.is_feed`, "Innings"])}:`}
			</Primary>
			<Gray>{arrivalTime.date}</Gray>
			<Primary>{arrivalTime.time}</Primary>
		</StyledRow>
	);
};

declare namespace ArrivalTime {
	interface Props extends OrderFormProps {}
}
export const ArrivalTimeMemo = memo(ArrivalTime);
export default ArrivalTime;
