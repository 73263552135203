import { useMemo, useState } from "react";

import Service from "../../../../../../../services/PriceZone";
import { useTypedSelector } from "../../../../../../../redux/store";
import Header from "../components/Header";

function useSubscribeOptions() {
	function useLanguage() {
		return useTypedSelector((state) => state.session.language);
	}

	const language = useLanguage();

	const [filters, setFilters] = useState<Header.Filters.Value>({
		taxiServiceIds: undefined,
		language,
		search: "",
	});

	const subscribeOptions = useMemo<Service.SubscribeOptions>(
		() => ({
			query: filters.search,
			taxiServiceIds: filters.taxiServiceIds,
			language: filters.language,
		}),
		[filters.language, filters.search, filters.taxiServiceIds],
	);

	return [subscribeOptions, filters, setFilters] as [
		subscribeOptions: typeof subscribeOptions,
		filters: typeof filters,
		setFilters: typeof setFilters,
	];
}

export default useSubscribeOptions;
