/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import * as ModelEvent from "@node-elion/syncron";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import { Base, CarBaseTypeToCarBrand } from "..";

const logger = createLogger({ name: "CarBrand" });

class CarBrand extends Base {
	static fromResponse(data: any): CarBrand.Model {
		return {
			id: data.id,

			carBaseTypeToCarBrands: data.brandToBaseTypes?.map(
				(brandToBaseType) =>
					CarBaseTypeToCarBrand.fromResponse(brandToBaseType),
			),

			name: data.name,
			native: data.native,

			updatedAt: data.updatedAt,
			createdAt: data.createdAt,
			deletedAt: data.deletedAt,
		};
	}

	static toRequest(model: CarBrand.Model.New | CarBrand.Model.Modified): any {
		return {
			name: model.name,
			active: model.active,
		};
	}

	public static async store(object: CarBrand.Model.New) {
		const data = await this.request((prpc) =>
			prpc.theirsModel.carBrand.create(CarBrand.toRequest(object)),
		);
		return data;
	}

	public static async update(object: CarBrand.Model.Modified) {
		const data = await this.request((prpc) =>
			prpc.theirsModel.carBrand.update(
				object.id,
				CarBrand.toRequest(object),
			),
		);
		return data;
	}

	public static async destroy(id: number) {
		this.request((prpc) => prpc.theirsModel.carBrand.delete(id));
	}

	public static async subscribe(
		options: CarBrand.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<CarBrand.Model>,
	): Promise<Subscription<CarBrand.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.carBrand.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (event) => {
						modelEventConstructor.onEvent(event);
					},
					onError: (error) => {
						logger.error(error);
					},
				}),
			{ name: "CarBrand.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: CarBrand.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<CarBrand.SubscribeOptions>;
	}

	private static optionsToRequest(options: CarBrand.SubscribeOptions) {
		return {
			offset: options.offset,
			limit: options.limit,
			query: options.query,

			ids: options.ids,
			carBaseTypeIds: options.carBaseTypeIds,
			carModelIds: options.carModelIds,

			active: options.active,
			favorite: options.favorite,
			native: options.native,

			order: options.order,
		};
	}
}

declare namespace CarBrand {
	export interface Model {
		id: number;

		carBaseTypeToCarBrands?: CarBaseTypeToCarBrand.Model[];

		name: string;
		native: boolean;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<CarBrand.Model> {
		ids?: number[];
		carBaseTypeIds?: number[];
		carModelIds?: number[];

		active?: boolean;
		favorite?: boolean;
		native?: boolean;
	}

	namespace Model {
		interface New {
			name: string;
			active?: boolean;
		}

		type Modified = Partial<New> & Pick<Model, "id">;
	}
}

export default CarBrand;
