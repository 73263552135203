import React, {
	PropsWithChildren,
	createContext,
	memo,
	useCallback,
	useContext,
	useMemo,
	useState,
} from "react";

import OrderSettings from "../../../../services/OrderSettings";
import Order from "../../../../services/Order";
import {
	IOrderPoint,
	Schema,
	ThresholdPaymentStatus,
	crateAdditionalFields,
	formatOrder,
	prepareOrderByForm,
} from "../../../../redux/constants/OrdersPage/order";
import { useTypedDispatch, useTypedSelector } from "../../../../redux/store";
import focusOrderCard from "../../../../redux/services/Order/card/focusOrderCard";
import orderPage, { Companies } from "../../../../redux/reducers/OrdersPage";
import { OrderType } from "../../../../redux/constants/OrdersPage";
import { Orders } from "../../../../redux/reducers/Orders";
import OrderMap from "../../../../services/Map";
import {
	validateOrderForm,
	ShouldValidateOrderForm,
} from "../../../../utils/validateOrderForm";
import { StyledRow } from "../../../../components/common";
import { Tab } from "../Orders/components/OrderModal/types/Tab";
import prepareValues from "../Orders/components/OrderModal/utils/prepareValues";
import { Language } from "../../../../services";

export const OrderModalContext = createContext<Provider.Context | null>(null);

export const useOrderModalContext = (): Provider.Context => {
	const store = useContext<Provider.Context | null>(OrderModalContext);
	if (!store) {
		throw new Error("Missing OrderModalContext.Provider in the tree");
	}
	return store;
};

export const OrderModalProvider: React.FC<Provider.Props> = ({
	children,
	tab,
}): JSX.Element => {
	const dispatch = useTypedDispatch();
	const [isChangTaxiService, setIsChangTaxiService] =
		useState<boolean>(false);

	const language = useTypedSelector((state) => state.session.language);
	const { ordersType: ordersTypeTab } = useTypedSelector(
		(state) => state.ordersPageReducer,
	);
	const settings = useTypedSelector((state) => state.settings.order.general);

	const companies = useTypedSelector(
		(state) => state.ordersPageReducer.companies,
	);

	const btnOnFooter = useTypedSelector(
		(state) => state.orders.mapFromAddModal.toggles.btnOnFooter,
	);
	const { rates } = useTypedSelector(
		(state) => state.preferencesReducer.rates,
	);
	const { models: serviceModels } = useTypedSelector(
		(state) => state.preferencesReducer.services.data,
	);

	const [orderMapState] = useState<Map<number, boolean>>(new Map());

	const searchTypeKeys = useMemo(
		() =>
			Object.keys(btnOnFooter).filter(
				(key) => btnOnFooter[key],
			) as OrderMap.Search.Type[],
		[btnOnFooter],
	);
	const [searchTypes, setSearchTypes] =
		useState<OrderMap.Search.Type[]>(searchTypeKeys);

	const [searchToggle, setSearchToggle] =
		useState<Orders.MapFromAddModal.BtnOnFooterToggles>(btnOnFooter);

	const phone = useMemo(() => tab.form?.phone, [tab.form?.phone]);
	const points = useMemo(() => {
		const orderPoints = tab.form?.points;
		const normalacyArr = [...orderPoints].filter((item) => {
			if (!item) return false;
			return true;
		});
		return normalacyArr;
	}, [tab.form?.points]);
	const fullName = useMemo(() => tab.form?.fullName, [tab.form?.fullName]);
	const orderType = useMemo(() => tab.form?.orderType, [tab.form?.orderType]);
	const orderStatus = useMemo(() => tab.form?.status, [tab.form?.status]);
	const orderSource = useMemo(() => tab.form?.source, [tab.form?.source]);
	const activeOrderId = useMemo(() => tab.form?.id, [tab.form?.id]);
	const orderSave = useMemo(() => tab.form?.orderSave, [tab.form?.orderSave]);
	const scheduledTime = useMemo(
		() => tab.form?.scheduledTime,
		[tab.form?.scheduledTime],
	);
	const showCustomerPhone = useMemo(
		() => tab.form?.displaySettings?.showCustomerPhone,
		[tab.form?.displaySettings?.showCustomerPhone],
	);
	const orderPointSave = useMemo(
		() => tab.form?.orderPointSave,
		[tab.form?.orderPointSave],
	);
	const companyBillMetadataStatus = useMemo(
		() => tab.form?.companyBillMetadata?.status,
		[tab.form?.companyBillMetadata?.status],
	);
	const paymentType = useMemo(
		() => tab.form?.paymentType,
		[tab.form?.paymentType],
	);
	const companyID = useMemo(
		() => tab?.form?.companyID,
		[tab?.form?.companyID],
	);
	const taxiClass = useMemo(() => tab.form.taxiClass, [tab.form.taxiClass]);
	const taxiServiceId = useMemo(
		() => tab?.form?.taxiServiceId,
		[tab?.form?.taxiServiceId],
	);
	const customerId = useMemo(
		() => tab.state?.customerId || undefined,
		[tab.state?.customerId],
	);
	const phones = useMemo(
		() => tab.form?.additionalPhones || [],
		[tab.form?.additionalPhones],
	);
	const executorPhone = useMemo(
		() => tab.form?.executorPhone,
		[tab.form?.executorPhone],
	);
	const executorPhones = useMemo(() => {
		const payload: string[] = [];
		if (tab.form?.executorPhones && tab.form?.executorPhones.length) {
			payload.push(...tab.form.executorPhones);
		}
		return payload;
	}, [tab.form?.executorPhones]);

	const isOwn = useMemo(() => tab?.form?.isOwn, [tab?.form?.isOwn]);

	const executorCallSign = useMemo(
		() => tab?.form?.executorSip,
		[tab?.form?.executorSip],
	);

	const executorCompany = useMemo(
		() => tab?.form?.executorCompany,
		[tab?.form?.executorCompany],
	);
	const executorTaxiService = useMemo(
		() => tab?.form?.executorTaxiService,
		[tab?.form?.executorTaxiService],
	);

	const executorTaxiServiceId = useMemo(
		() => tab?.form?.executorTaxiServiceId,
		[tab?.form?.executorTaxiServiceId],
	);

	const taxiServiceName = useMemo(
		() => tab?.form?.taxiServiceName,
		[tab?.form?.taxiServiceName],
	);

	const companyName = useMemo(
		() => tab?.form?.companyName,
		[tab?.form?.companyName],
	);

	const canWorkingWithOrder = useMemo(() => {
		if (!isOwn) {
			const list = [
				!!executorCallSign,
				taxiServiceId === executorTaxiServiceId,
				taxiServiceName === executorTaxiService,
				companyName === executorCompany,
			];
			return !list.every((item) => item === true);
		}
		return false;
	}, [
		companyName,
		executorCallSign,
		executorCompany,
		executorTaxiService,
		executorTaxiServiceId,
		isOwn,
		taxiServiceId,
		taxiServiceName,
	]);

	const orderServices = useMemo(
		() => tab?.form?.services || [],
		[tab?.form?.services],
	);

	const allTaxiServices = useMemo(
		() =>
			companies.items?.flatMap((item) =>
				item.taxiServices?.map((service) => ({
					...service,
					companyID: item.id,
				})),
			),
		[companies],
	);

	const nextElementById = useCallback<Provider.Context["nextElementById"]>(
		(elemId: string) => {
			const elem = document.getElementById(elemId);
			if (elem) {
				elem.focus();
				if (elem?.nodeName === "INPUT") {
					const input = elem as HTMLInputElement;
					input?.select();
				}
			}
			return elem;
		},
		[],
	);

	const setOrdersTypeTabs = useCallback(
		(orderType: OrderType) => {
			if (ordersTypeTab === "all") return;
			dispatch(orderPage.actions.setOrdersType(orderType));
		},
		[dispatch, ordersTypeTab],
	);

	const getAdditionalFields = useCallback(
		() => crateAdditionalFields(tab.form),
		[tab.form],
	);

	const prepareFormData = useCallback(() => {
		const values = prepareValues(tab.form);
		return prepareOrderByForm(values, customerId);
	}, [customerId, tab.form]);

	const validateOrder = useCallback<Provider.Context["validateOrder"]>(
		(schema: Schema) => {
			const options = settings.requiredFields;
			if (options.phone && !options.points) {
				if (!phone) return false;
				if (phone.length < 12) return false;
			}

			if (options.points && !options.phone) {
				if (points.length === 0) return false;
				if (points.length < options.points) return false;
			}

			if (options.phone && options.points) {
				if (!phone) return false;
				if (phone.length < 12) return false;
				if (points.length === 0) return false;
				if (points.length < options.points) return false;
			}

			const validator = validateOrderForm(schema, options);
			const valid = Object.values(validator).every(Boolean);

			return valid;
		},
		[phone, points.length, settings.requiredFields],
	);

	const getRateId = useCallback(
		(companyId: number) => {
			const carClassIds = taxiClass?.map((item) => item?.value) || [];

			const foundRates = rates.filter((rate) => {
				if (!rate?.active) return false;

				const cars = carClassIds
					.map((item) => rate.carClassIds.includes(item))
					.every((item) => item);

				const company = rate.taxiServiceIds?.includes(companyId);

				if (company && cars) return true;
				return false;
			});

			let rateId: number | undefined = foundRates?.at(0)?.id;

			if (!rateId) {
				rateId = rates?.find((item) => item.root === true)?.id;
			}

			return rateId;
		},
		[rates, taxiClass],
	);

	const getDefaultRateId = useCallback(() => {
		const rate =
			rates.find((item) => item.default && item.active) ||
			rates.find((item) => item.root === true);

		return rate?.id || rates?.[0]?.id;
	}, [rates]);

	const getDefaultCity = useCallback(
		(taxiServiceId: number) => {
			const foundedOption = allTaxiServices.find(
				(item) => item.id === (taxiServiceId || 1),
			);

			const defaultCity = {
				id: foundedOption?.id,
				value: foundedOption.settlement?.[language],
				settlement: foundedOption.settlement?.[language],
				coordinates: foundedOption.settlementPoint,
			};

			return defaultCity;
		},
		[allTaxiServices, language],
	);

	const getServices = useCallback(
		(taxiServiceId: number) => {
			const serviceIdsForSelectedTaxiService = serviceModels
				?.filter(
					(item) =>
						item.active &&
						item.taxiServices
							?.map((item) => item?.id)
							?.includes(taxiServiceId) === true,
				)
				.map((item) => item.id);

			const modifyServices = orderServices?.filter((item) =>
				serviceIdsForSelectedTaxiService.includes(item.value),
			);

			return modifyServices;
		},
		[serviceModels, orderServices],
	);

	const updateOrder = useCallback<Provider.Context["updateOrder"]>(
		async ({ form = {}, id = null }) => {
			if (orderStatus !== "creating") return null;
			if (!tab.form?.id || !form.id) return null;
			const values = prepareValues(tab.form);
			const schema = { ...values, ...form };

			const validator = validateOrderForm(
				schema,
				settings.requiredFields,
			);
			const valid = Object.values(validator).every(Boolean);

			if (valid) {
				const payload: Order.Model.Modified = prepareOrderByForm(
					schema,
					id || customerId,
				);
				const orderId = form.id || tab.form?.id;
				const order = await Order.update({ ...payload, id: orderId });

				return order;
			}
			return null;
		},
		[orderStatus, tab.form, settings.requiredFields, customerId],
	);

	const createOrder = useCallback(
		async (options: ShouldValidateOrderForm = {}) => {
			if (orderStatus !== "creating") return;
			if (
				companyBillMetadataStatus ===
					ThresholdPaymentStatus.THRESHOLD_FAILED &&
				paymentType === "company_bill"
			) {
				return;
			}
			if (!tab.form?.id) return;
			const exist = orderMapState.get(tab.form.id);
			if (exist) return;
			const validator = validateOrderForm(tab.form, options);
			const valid = Object.values(validator).every(Boolean);
			if (valid) {
				console.log("[ OrderModal ] createOrder start", {
					form: tab.form,
				});
				if (tab.form?.id) {
					orderMapState.set(tab.form.id, true);
				}
				const params = prepareFormData();
				const order = await Order.update({
					id: tab.form.id,
					...params,
				});

				if (order?.id) {
					const formatNewOrder = formatOrder(order);
					dispatch(orderPage.actions.setActiveOrder(formatNewOrder));
					dispatch(focusOrderCard(order.id));
				}
			}
		},
		[
			orderStatus,
			tab.form,
			orderMapState,
			companyBillMetadataStatus,
			paymentType,
			prepareFormData,
			dispatch,
		],
	);

	// When to save an order.
	useMemo(() => {
		if (orderStatus === "creating" && settings.enableQuickDistribution) {
			const createOrderOption = settings.requiredFields;

			if (
				createOrderOption.phone &&
				orderSave &&
				!createOrderOption.points
			) {
				if (!phone) return;
				if (phone.length < 11) return;
				if (phone.length > 12) return;
				createOrder(createOrderOption);
			}

			if (
				createOrderOption.points &&
				orderPointSave &&
				!createOrderOption.phone
			) {
				if (points.length === 0) return;
				if (points.length < createOrderOption.points) return;
				createOrder(createOrderOption);
			}

			if (createOrderOption.phone && createOrderOption.points) {
				if (!phone) return;
				if (phone.length < 11) return;
				if (phone.length > 12) return;
				if (points.length === 0) return;
				if (points.length < createOrderOption.points) return;
				createOrder(createOrderOption);
			}
		}
	}, [
		orderStatus,
		settings.enableQuickDistribution,
		settings.requiredFields,
		orderSave,
		orderPointSave,
		phone,
		createOrder,
		points.length,
	]);

	return (
		<OrderModalContext.Provider
			value={useMemo(
				() => ({
					nextElementById,
					settings,
					companyID,
					points,
					phone,
					phones,
					executorPhone,
					executorPhones,
					customerId,
					companyName,
					executorCallSign,
					executorCompany,
					executorTaxiService,
					executorTaxiServiceId,
					isOwn,
					taxiServiceId,
					taxiServiceName,
					fullName,
					tab,
					activeOrderId,
					orderSource,
					orderStatus,
					orderType,
					companies,
					showCustomerPhone,
					getAdditionalFields,
					createOrder,
					updateOrder,
					validateOrder,
					searchTypes,
					setSearchTypes,
					searchToggle,
					setSearchToggle,
					setOrdersTypeTabs,
					scheduledTime,
					language,
					getRateId,
					getServices,
					getDefaultRateId,
					getDefaultCity,
					isChangTaxiService,
					setIsChangTaxiService,
					canWorkingWithOrder,
				}),
				[
					scheduledTime,
					nextElementById,
					settings,
					companyID,
					points,
					phone,
					phones,
					executorPhone,
					executorPhones,
					customerId,
					companyName,
					executorCallSign,
					executorCompany,
					executorTaxiService,
					executorTaxiServiceId,
					isOwn,
					taxiServiceId,
					taxiServiceName,
					fullName,
					tab,
					activeOrderId,
					orderSource,
					orderStatus,
					orderType,
					companies,
					showCustomerPhone,
					getAdditionalFields,
					createOrder,
					updateOrder,
					validateOrder,
					searchTypes,
					searchToggle,
					setOrdersTypeTabs,
					language,
					getRateId,
					getServices,
					getDefaultRateId,
					getDefaultCity,
					isChangTaxiService,
					setIsChangTaxiService,
					canWorkingWithOrder,
				],
			)}
		>
			<StyledRow
				position="relative"
				zi={1}
				w="100%"
				h="100%"
				id="order_modal_provider_id"
			>
				{children}
			</StyledRow>
		</OrderModalContext.Provider>
	);
};

export declare namespace Provider {
	export interface Props extends PropsWithChildren {
		tab: Tab;
	}
	interface Value {
		disabled: boolean;
	}

	interface OrderSearchToggle {
		street: boolean;
		object: boolean;
		localObject: boolean;
	}

	interface Context {
		language: Language;
		scheduledTime: Schema["scheduledTime"];
		settings: OrderSettings.Model["settings"]["general"];
		companyID: number | undefined;
		taxiServiceId: number | undefined;
		points: IOrderPoint[];
		customerId: number | undefined;
		phone: Schema["phone"];
		phones: string[];
		executorPhone: Schema["executorPhone"];
		executorPhones: Schema["executorPhones"];
		fullName: Schema["fullName"];
		activeOrderId: Schema["id"];
		orderSource: Schema["source"];
		orderStatus: Schema["status"];
		orderType: Schema["orderType"];
		companies: Companies;
		showCustomerPhone: boolean | undefined;

		companyName: Schema["companyName"];
		executorCallSign: Schema["executorSip"];
		executorCompany: Schema["executorCompany"];
		executorTaxiService: Schema["executorTaxiService"];
		executorTaxiServiceId: Schema["executorTaxiServiceId"];
		isOwn: Schema["isOwn"];
		taxiServiceName: Schema["taxiServiceName"];

		canWorkingWithOrder: boolean;

		isChangTaxiService: boolean;
		setIsChangTaxiService: React.Dispatch<React.SetStateAction<boolean>>;

		searchTypes: OrderMap.Search.Type[];
		setSearchTypes: React.Dispatch<
			React.SetStateAction<OrderMap.Search.Type[]>
		>;

		searchToggle: OrderSearchToggle;
		setSearchToggle: React.Dispatch<
			React.SetStateAction<OrderSearchToggle>
		>;
		nextElementById: (elemId: string) => HTMLElement | null;
		getAdditionalFields: () => Order.IAdditionalFields;
		createOrder: (options?: ShouldValidateOrderForm) => void;
		validateOrder: (schema: Schema) => boolean;
		updateOrder: (data: {
			form?: Partial<Schema>;
			id?: number | null;
		}) => Promise<Order.Model | null>;

		setOrdersTypeTabs: (orderType: OrderType) => void;
		getRateId: (companyId: number) => number | undefined;
		getServices: (taxiServiceId: number) => {
			name: string;
			value: number;
		}[];
		getDefaultRateId: () => number | undefined;
		getDefaultCity: (taxiServiceId: number) => {
			id: any;
			value: any;
			settlement: any;
			coordinates: any;
		};
	}
}

export const OrderModalProviderMemo = memo(OrderModalProvider);
