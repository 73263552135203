import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import JSPDF from "jspdf";
import JSZip from "jszip";
import autoTable, { RowInput, UserOptions } from "jspdf-autotable";
import { DatePicker } from "uikit";

import { getExcelCurrencyFormat, createLogger } from "../../utils";
import { DateRange } from "../../types/DataRange";
import IResponseWithItems from "../../types/IResponse";
import { СurrencyDefinitions } from "../../types";
import {
	formatDate,
	formatDistance,
	formatMoney,
	formatTimeToMinutes,
} from "../../pages/Reports/pages/AccountingReports/utils/formatForFile";
import { Base, Language } from "..";

const logger = createLogger({ name: "CashlessOrdersReport" });

class CashlessOrdersReport extends Base {
	static fromResponse(data: any): CashlessOrdersReport.Model {
		return data || [];
	}

	public static async getCashlessOrdersReport(
		params: CashlessOrdersReport.SearchOptions,
	): Promise<CashlessOrdersReport.GetCashlessOrdersReportResponse | null> {
		try {
			logger.info(
				"[CashlessOrdersReport] getCashlessOrdersReport params",
				{
					params,
				},
			);
			if (!params.dateRange) return null;

			const response = await this.request((prpc) =>
				prpc.theirsModel.report.getCashlessOrdersReport(params),
			);

			logger.info(
				"[CashlessOrdersReport] getCashlessOrdersReport response",
				{
					response,
				},
			);
			if (!response) return null;

			return {
				...response,
				items: response?.items?.map(this.fromResponse),
			};
		} catch (error) {
			logger.error("[CashlessOrdersReport] Error", error);

			return null;
		}
	}

	public static exportPDF = async (
		data: CashlessOrdersReport.Model.Item[],
		options: {
			tableColumn: string[];
			columnStyles: UserOptions["columnStyles"];
			width: number;
			height: number;
			general: boolean;
			ColumnId: any;
			columnIds: any;
			textTranslations: Record<string, string>;
			headerTitle: string;
		},
	) => {
		if (!data?.length) return;
		const {
			tableColumn = [],
			width = 3500,
			height = 780,
			general = true,
			columnStyles,
			ColumnId,
			columnIds,
			textTranslations,
			headerTitle,
		} = options;

		const totalWidth = columnStyles
			? Object.values(columnStyles).reduce(
					(sum, { cellWidth }) => sum + +(cellWidth || 0),
					0,
			  ) +
			  Object.keys(columnStyles).length * (5.1 / 3.56) +
			  0.1 / 3
			: width;

		const realWidth = totalWidth || width;
		const doc = new JSPDF({
			orientation: "landscape",
			format: [realWidth, height],
		});

		let currentY = 10;
		let _dateFrom;
		let _dateTo;

		data.forEach(({ taxiService, orders, dateRange, counter }) => {
			doc.setFont("Roboto-Regular", "normal");

			const textCompany = taxiService?.company?.name ?? "";
			const textFill = taxiService?.name ? `, ${taxiService?.name}` : "";
			const textCounterparty = taxiService?.counterpartyName
				? taxiService?.counterpartyName
				: "";

			const dateFrom = formatDate({
				date: dateRange.from,
				isTitleFormat: true,
			});

			const dateTo = formatDate({
				date: dateRange.to,
				isTitleFormat: true,
			});

			if (!_dateFrom) _dateFrom = dateFrom;
			if (!_dateTo) _dateTo = dateTo;

			const pageWidth = doc.internal.pageSize.getWidth();
			const centerX = pageWidth / 2;

			const textArray = [
				`${textCompany} ${textFill}`,

				`${textCounterparty} ${textTranslations.forPeriod} ${textTranslations.from} ${dateFrom} ${textTranslations.to} ${dateTo}`,
			];

			textArray.forEach((text) => {
				const textWidth = doc.getTextWidth(text);

				const textX = centerX - textWidth / 2;

				doc.text(text, textX, currentY);

				currentY += 10;
			});

			const tableRows = orders.map((order) => {
				const payload: RowInput = [];
				const columnIdSet = new Set(columnIds);

				const retval: RowInput = [];

				if (!general) {
					if (columnIdSet.has(ColumnId.Order)) {
						payload.push({
							content: `${order.orderNumber}` || "",
						});
					}
				}

				if (columnIdSet.has(ColumnId.Name)) {
					retval.push({ content: `${order.counterpartyName}` });
				}

				if (columnIdSet.has(ColumnId.Card)) {
					retval.push({ content: `${order.checkCard}` });
				}
				if (!general) {
					if (columnIdSet.has(ColumnId.Phone)) {
						payload.push({
							content: `${order.phone}` || "",
						});
					}
				}
				if (columnIdSet.has(ColumnId.Passenger)) {
					retval.push({ content: `${order.fullName}` });
				}
				if (columnIdSet.has(ColumnId.From)) {
					retval.push({ content: `${order.addressFrom}` });
				}
				if (columnIdSet.has(ColumnId.To)) {
					retval.push({ content: `${order.addressTo}` });
				}
				if (columnIdSet.has(ColumnId.Note)) {
					retval.push({ content: `${order.orderNotes}` });
				}
				if (columnIdSet.has(ColumnId.OrderNote)) {
					retval.push({
						content: `${order.executorNotes}`,
					});
				}
				if (columnIdSet.has(ColumnId.Created)) {
					retval.push({
						content: formatDate({ date: order.createdAt }),
					});
				}
				if (columnIdSet.has(ColumnId.When)) {
					retval.push({
						content: formatDate({ date: order.arrivalTime }),
					});
				}
				if (columnIdSet.has(ColumnId.Completed)) {
					retval.push({
						content: formatDate({ date: order.closedAt }),
					});
				}
				if (columnIdSet.has(ColumnId.CallSign)) {
					retval.push({
						content: `${order.executorCallSign}`,
					});
				}
				if (columnIdSet.has(ColumnId.LicensePlate)) {
					retval.push({
						content: `${order.registrationNumber}`,
					});
				}
				if (columnIdSet.has(ColumnId.Model)) {
					retval.push({ content: `${order.carType}` });
				}
				if (columnIdSet.has(ColumnId.Color)) {
					retval.push({ content: `${order.carColor}` });
				}
				if (columnIdSet.has(ColumnId.DistanceKm)) {
					retval.push({
						content: formatDistance(order.distance),
					});
				}
				if (columnIdSet.has(ColumnId.ExecutionTimeMin)) {
					retval.push({
						content: `${formatTimeToMinutes(order.executingTime)}`,
					});
				}
				if (columnIdSet.has(ColumnId.IdleTimeMin)) {
					retval.push({ content: order.delay });
				}
				if (columnIdSet.has(ColumnId.AmountUah)) {
					retval.push({
						content: `${formatMoney(order.price)}`,
					});
				}
				if (columnIdSet.has(ColumnId.Status)) {
					retval.push({
						content: order.status
							? `${textTranslations.completed}`
							: `${textTranslations.pending}`,
					});
				}

				return [...payload, ...retval] as RowInput;
			});

			autoTable(doc, {
				head: [tableColumn],
				body: tableRows,
				startY: currentY,
				styles: {
					fontSize: 12,
					cellPadding: 5,
					lineWidth: 0.1,
					lineColor: [0, 0, 0],
					font: "Roboto-Regular",
					fontStyle: "normal",
				},
				columnStyles,
				headStyles: {
					fillColor: [41, 128, 185],
					textColor: [255, 255, 255],
					fontSize: 10,
				},
				margin: { top: 20 },
				tableLineWidth: 0,
				tableLineColor: [0, 0, 0],
				theme: "grid",
			});

			currentY = doc?.lastAutoTable?.finalY;

			const currency = orders?.[0]?.currency?.name || "";

			doc.setFont("Roboto-Regular", "normal");
			doc.text(
				[
					`${textTranslations.successful}: ${counter.success.count}    ${textTranslations.unsuccessful}: ${counter.filed.count}    ${textTranslations.totalOrders}: ${counter.orders.count}`,
					`${textTranslations.amount}, ${currency}: ${counter.success.amount}   ${textTranslations.amount}, ${currency}: ${counter.filed.amount}   ${textTranslations.total}, ${currency}: ${counter.orders.amount}`,
				],
				30,
				currentY + 20,
			);
			currentY += 50;
		});

		const namefile = `${headerTitle ?? ""} - ${_dateFrom ?? ""} - ${
			_dateTo ?? ""
		}.pdf`;

		doc.save(namefile);
	};

	public static exportExcel = async (
		_data: CashlessOrdersReport.Model.Item[],
		options: {
			tableColumn: string[];
			columnStyles: UserOptions["columnStyles"];
			width: number;
			height: number;
			general: boolean;
			ColumnId: any;
			columnIds: any;
			textTranslations: Record<string, string>;
			headerTitle: string;
			isZip: boolean;
			dateFrom: DatePicker.Value;
			dateTo: DatePicker.Value;
		},
	) => {
		if (!_data?.length) return;

		const {
			tableColumn = [],

			general = true,
			columnStyles,
			ColumnId,
			columnIds,
			textTranslations,
			headerTitle,
			isZip,
			dateFrom,
			dateTo,
		} = options;

		const _dateFrom = formatDate({
			date: dateFrom ?? new Date(),
			isTitleFormat: true,
		});
		const _dateTo = formatDate({
			date: dateTo ?? new Date(),
			isTitleFormat: true,
		});

		const namefile = `${headerTitle ?? ""} - ${_dateFrom ?? ""} - ${
			_dateTo ?? ""
		}`;

		const zip = new JSZip();
		const workbook = new ExcelJS.Workbook();

		const clearWorksheets = () => {
			workbook.worksheets.forEach((sheet) =>
				workbook.removeWorksheet(sheet.name),
			);
		};

		const createReport = async (
			data: CashlessOrdersReport.Model.Item[],
		) => {
			const worksheet = workbook.addWorksheet(namefile);

			tableColumn.forEach((colIndex, index) => {
				const cellWidth: number = +(
					columnStyles?.[index]?.cellWidth ?? 80
				);

				if (typeof cellWidth === "number" && cellWidth > 0) {
					worksheet.getColumn(index + 1).width = Math.round(
						cellWidth / 2.5,
					);
				} else {
					logger.warn(
						`2 Width for column ${colIndex} is undefined or invalid: cellWidth`,
						cellWidth,
					);
				}
			});

			data.forEach(({ taxiService, orders, dateRange, counter }) => {
				const textCompany = taxiService?.company?.name ?? "";
				const textFill = taxiService?.name
					? `, ${taxiService?.name}`
					: "";
				const textCounterparty = taxiService?.counterpartyName || "";

				const dateFrom = formatDate({
					date: dateRange.from,
					isTitleFormat: true,
				});
				const dateTo = formatDate({
					date: dateRange.to,
					isTitleFormat: true,
				});

				const addHeaderRow = (text: string) => {
					const headerRow = worksheet.addRow([text]);
					headerRow.font = {
						size: 10,
						name: "Arial",
					};
					headerRow.alignment = {
						horizontal: "left",
						vertical: "middle",
						wrapText: false,
					};

					headerRow.height = 30;

					worksheet.mergeCells(
						`A${headerRow.number}:F${headerRow.number}`,
					);

					headerRow.getCell(1).alignment = {
						horizontal: "center",
						vertical: "middle",
					};
				};

				const textheaderTable = [
					`${textCompany} ${textFill}`,
					`${textCounterparty} ${textTranslations.forPeriod} ${textTranslations.from} ${dateFrom} ${textTranslations.to} ${dateTo}`,
				];

				textheaderTable.forEach((text) => {
					addHeaderRow(text);
				});

				const titleRow = worksheet.addRow(tableColumn);
				titleRow.font = {
					name: "Calibri",
					size: 11,
					color: { argb: "ffffff" },
				};
				titleRow.alignment = {
					horizontal: "center",
					vertical: "middle",
					wrapText: true,
				};
				titleRow.fill = {
					type: "pattern",
					pattern: "solid",
					fgColor: { argb: "4baac6" },
				};
				titleRow.border = {
					top: { style: "thin", color: { argb: "FF000000" } },
					bottom: { style: "thin", color: { argb: "FF000000" } },
				};

				const maxHeight = Math.min(
					3,
					Math.max(
						...tableColumn.map((cell) => {
							if (typeof cell === "string") {
								return Math.ceil(cell.length / 10);
							}
							return 1;
						}),
					),
				);

				titleRow.height = maxHeight * 13;

				orders.forEach((order, index) => {
					const row: (string | number | undefined | Date | null)[] =
						[];

					const columnIdSet = new Set(columnIds);

					if (!general) {
						if (columnIdSet.has(ColumnId.Order))
							row.push(order.orderNumber || "");
					}
					if (columnIdSet.has(ColumnId.Name))
						row.push(order.counterpartyName);
					if (columnIdSet.has(ColumnId.Card))
						row.push(order.checkCard);
					if (!general) {
						if (columnIdSet.has(ColumnId.Phone))
							row.push(order.phone || "");
					}
					if (columnIdSet.has(ColumnId.Passenger))
						row.push(order.fullName);
					if (columnIdSet.has(ColumnId.From))
						row.push(order.addressFrom);
					if (columnIdSet.has(ColumnId.To)) row.push(order.addressTo);
					if (columnIdSet.has(ColumnId.Note))
						row.push(order.orderNotes);
					if (columnIdSet.has(ColumnId.OrderNote))
						row.push(order.executorNotes);

					if (columnIdSet.has(ColumnId.Created)) {
						const createdDate = order.createdAt
							? new Date(order.createdAt)
							: null;
						row.push(createdDate);
					}
					if (columnIdSet.has(ColumnId.When)) {
						const whenDate = order.arrivalTime
							? new Date(order.arrivalTime)
							: null;
						row.push(whenDate);
					}
					if (columnIdSet.has(ColumnId.Completed)) {
						const completedDate = order.closedAt
							? new Date(order.closedAt)
							: null;
						row.push(completedDate);
					}
					if (columnIdSet.has(ColumnId.CallSign))
						row.push(order.executorCallSign);
					if (columnIdSet.has(ColumnId.LicensePlate))
						row.push(order.registrationNumber);
					if (columnIdSet.has(ColumnId.Model))
						row.push(order.carType);
					if (columnIdSet.has(ColumnId.Color))
						row.push(order.carColor);
					if (columnIdSet.has(ColumnId.DistanceKm)) {
						const distance = parseFloat(
							formatDistance(order.distance),
						);
						row.push(distance);
					}

					if (columnIdSet.has(ColumnId.ExecutionTimeMin))
						row.push(formatTimeToMinutes(order.executingTime));
					if (columnIdSet.has(ColumnId.IdleTimeMin))
						row.push(formatTimeToMinutes(order.delay));

					if (columnIdSet.has(ColumnId.AmountUah)) {
						const formattedPrice = formatMoney(order.price);
						row.push(parseFloat(formattedPrice));
					}

					if (columnIdSet.has(ColumnId.Status)) {
						row.push(
							order.status
								? textTranslations.completed
								: textTranslations.pending,
						);
					}

					function isOdd(num: number): boolean {
						return num % 2 !== 0;
					}

					const newRow = worksheet.addRow(row);
					newRow.alignment = {
						horizontal: "center",
						vertical: "middle",
						wrapText: true,
					};
					newRow.font = { name: "Arial", size: 10 };

					const getColumnIndex = (columnId) =>
						Array.from(columnIdSet).indexOf(columnId) + 1;

					if (columnIdSet.has(ColumnId.DistanceKm)) {
						const distanceCell = newRow.getCell(
							getColumnIndex(ColumnId.DistanceKm),
						);
						distanceCell.numFmt = "0.000";
					}

					if (columnIdSet.has(ColumnId.ExecutionTimeMin)) {
						const executionCell = newRow.getCell(
							getColumnIndex(ColumnId.ExecutionTimeMin),
						);
						executionCell.numFmt = "0";
					}

					if (columnIdSet.has(ColumnId.IdleTimeMin)) {
						const idleCell = newRow.getCell(
							getColumnIndex(ColumnId.IdleTimeMin),
						);
						idleCell.numFmt = "0";
					}

					if (columnIdSet.has(ColumnId.AmountUah)) {
						const amountCell = newRow.getCell(
							getColumnIndex(ColumnId.AmountUah),
						);
						const currency = orders?.[0]?.currency?.symbol || "₴";

						amountCell.numFmt = getExcelCurrencyFormat(currency);
						amountCell.alignment = { wrapText: true };
					}

					if (columnIdSet.has(ColumnId.Created)) {
						const createdCell = newRow.getCell(
							getColumnIndex(ColumnId.Created),
						);

						createdCell.numFmt = "DD.MM.YYYY HH:mm:ss";
						createdCell.alignment = { wrapText: true };
					}
					if (columnIdSet.has(ColumnId.When)) {
						const whenCell = newRow.getCell(
							getColumnIndex(ColumnId.When),
						);

						whenCell.numFmt = "DD.MM.YYYY HH:mm:ss";
						whenCell.alignment = { wrapText: true };
					}
					if (columnIdSet.has(ColumnId.Completed)) {
						const completedCell = newRow.getCell(
							getColumnIndex(ColumnId.Completed),
						);

						completedCell.numFmt = "DD.MM.YYYY HH:mm:ss";
						completedCell.alignment = { wrapText: true };
					}

					if (isOdd(index)) {
						newRow.fill = {
							type: "pattern",
							pattern: "solid",
							fgColor: { argb: "d9d9d9" },
						};
					}
					if (orders.length - 1 === index) {
						newRow.border = {
							bottom: {
								style: "thin",
								color: { argb: "FF000000" },
							},
						};
					}
					const maxHeight = Math.min(
						3,
						Math.max(
							...row.map((cell) => {
								if (typeof cell === "string") {
									return Math.ceil(cell.length / 10);
								}
								return 1;
							}),
						),
					);

					newRow.height = maxHeight * 13;

					newRow.alignment = {
						horizontal: "center",
						vertical: "middle",
						wrapText: true,
					};
				});

				const currency = orders?.[0]?.currency?.name || "";

				const footerOne = worksheet.addRow([
					textTranslations.successful,
					counter.success.count || 0,
					textTranslations.unsuccessful,
					counter.filed.count || 0,
					textTranslations.totalOrders,
					counter.orders.count || 0,
				]);

				const footerTwo = worksheet.addRow([
					`${textTranslations.amount}, ${currency}:`,
					counter.success.amount || 0,
					`${textTranslations.amount}, ${currency}:`,
					counter.filed.amount || 0,
					`${textTranslations.total}, ${currency}:`,
					counter.orders.amount || 0,
				]);

				footerOne.eachCell((cell) => {
					cell.border = {
						top: { style: "thin" },
						left: { style: "thin" },
						bottom: { style: "thin" },
						right: { style: "thin" },
					};
					cell.alignment = {
						horizontal: "center",
						vertical: "middle",
					};
					cell.font = { name: "Calibri", size: 11 };
					cell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "F9F9F9" },
					};
				});

				footerTwo.eachCell((cell) => {
					cell.border = {
						top: { style: "thin" },
						left: { style: "thin" },
						bottom: { style: "thin" },
						right: { style: "thin" },
					};
					cell.alignment = {
						horizontal: "center",
						vertical: "middle",
					};
					cell.font = { name: "Calibri", size: 11 };
					cell.fill = {
						type: "pattern",
						pattern: "solid",
						fgColor: { argb: "F9F9F9" },
					};
				});

				worksheet.addRow([]);
				worksheet.addRow([]);
			});

			const buffer = await workbook.xlsx.writeBuffer();

			if (isZip) {
				zip.file(
					`${data[0]?.taxiService?.counterpartyName ?? "Report"} (${
						data[0]?.taxiService?.edrpou ?? ""
					}).xlsx`,
					buffer,
				);
			} else {
				const blob = new Blob([buffer], {
					type: "application/octet-stream",
				});
				saveAs(blob, nameFileExcelOnly);
			}
		};

		const nameFileZip = `${namefile}.zip`;
		const nameFileExcelOnly = `${namefile}.xlsx`;

		if (isZip) {
			for (let index = 0; index < _data.length; index++) {
				clearWorksheets();
				await createReport([_data[index]]);
			}

			const zipContent = await zip.generateAsync({ type: "blob" });
			saveAs(zipContent, nameFileZip);
		} else {
			createReport(_data);
		}
	};
}

declare namespace CashlessOrdersReport {
	type Model = CashlessOrdersReport.Model.Item;

	interface GetCashlessOrdersReportResponse
		extends IResponseWithItems<Model> {}

	interface SearchOptions {
		successes?: boolean;
		general?: boolean;
		taxiServiceIds?: number[];
		counterpartyIds?: number[];
		cardIds?: number[];
		dateRange: DateRange;
		lang: Language;
	}

	namespace Model {
		interface AmountCount {
			count: number;
			amount: number;
		}
		interface Counter {
			success: AmountCount;
			filed: AmountCount;
			orders: AmountCount;
		}

		interface Order {
			id: number;
			orderNumber?: number;
			phone?: string;
			checkCard: string;
			fullName: string;

			addressFrom: string;
			addressTo: string;

			orderNotes: string;
			executorNotes: string;

			createdAt: string | Date;
			arrivalTime: number;
			closedAt: string | Date;

			executorCallSign: string;

			registrationNumber: string;
			carType: string;
			carColor: string;

			distance: number;

			delay: number;
			executingTime: number;

			price: number;
			currency: СurrencyDefinitions.Value;
			status: boolean;

			counterpartyName?: string;
		}

		interface Item {
			dateRange: {
				from: Date;
				to: Date;
			};
			taxiService: {
				id: number;
				name: string;
				company: {
					id: number;
					name: string;
				};
				counterpartyName?: string;
				edrpou?: string;
			};
			orders: Order[];
			counter: Counter;
		}
	}
}

export default CashlessOrdersReport;
