import React, { useMemo } from "react";
import moment from "moment";
import { theme } from "uikit";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const OrderDateCellContent: React.FC<OrderDateCellContent.Props> = ({
	item,
}) => {
	const isPreliminary = useMemo(
		() =>
			item.isPreliminary ||
			item?.additionalFields?.transfer?.lastReceivedOrder?.isPreliminary,
		[
			item?.additionalFields?.transfer?.lastReceivedOrder?.isPreliminary,
			item.isPreliminary,
		],
	);

	const content = useMemo(
		() =>
			isPreliminary ? moment(item.orderDate).format("DD-MM-YYYY") : "",
		[isPreliminary, item.orderDate],
	);

	const backgroundColor = useMemo(
		() => (isPreliminary ? "#7B0078" : undefined),
		[isPreliminary],
	);

	const textColor = useMemo(
		() => (isPreliminary ? theme.colors.white : undefined),
		[isPreliminary],
	);

	return (
		<CellContentRoot
			align="center"
			backgroundColor={backgroundColor}
			textColor={textColor}
			maxedHeight
			maxedWidth
		>
			{content}
		</CellContentRoot>
	);
};

declare namespace OrderDateCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default OrderDateCellContent;
