import "rc-dock/dist/rc-dock.css";

import { useTranslation } from "react-i18next";
import React, {
	useCallback,
	useEffect,
	useMemo,
	Suspense,
	useState,
} from "react";
import { Float, Icon } from "uikit";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/uk";
import "moment/locale/az";
import "moment/locale/tr";
import "moment/locale/ro";
import {
	LocalizationProvider,
	enUS,
	ruRU,
	ukUA,
	trTR,
	roRO,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { SIPToDispatcher } from "./services";
import "./assets/icons";
import { Language, languagePackages } from "./assets/languages/langs";
import getCurrentIP from "./utils/getCurrentIP";
import getApplicationConfig from "./utils/getApplicationConfig";
import { callUp, getAndCallDown, sipConnect } from "./utils/jsSip";
import createRPCQuery from "./utils/createRPCQuery.util";
import { RouterSwitch } from "./routes";
import { useTypedDispatch, useTypedSelector } from "./redux/store";
import Session from "./redux/services/Session";
import serverSettings from "./redux/reducers/applicationConfig";
import currentIP from "./redux/reducers/currentIP";
import sipToDispatcher from "./redux/reducers/sipToDispatcher";
import softphoneWidget from "./redux/reducers/softphoneWidget";
import getClasses from "./redux/services/Preferences/CarClass/getClasses";
import getServices from "./redux/services/Preferences/Services/getServices";
import useKeyBindInit from "./hooks/useKeyBindInit";
import useSettingsInit from "./hooks/useSettingsInit";
import useKeyBind from "./hooks/useKeyBind";
import useGlobalSettings from "./hooks/useGlobalSettings";
import useOrderSettings from "./hooks/useOrderSettings";
import usePersistRoot from "./hooks/usePersistRoot";
import { getPRPC } from "./hooks/usePRPC";
import { SuspenseLoader } from "./components/common";
import { KEYBOARD_CODES } from "./constants/business";
import KeyBindLayer from "./components/KeyBindLayer";
import CallProvider from "./components/CallProvider";
import AdditionalTariffLoader from "./components/AdditionalTariffLoader";
import OrderSettingsLoader from "./components/OrderSettingsLoader";

function getMUILocale(language: Language) {
	switch (language) {
		// eslint-disable-next-line default-case-last
		default:
			return enUS;
		case "en":
			return enUS;
		case "ru":
			return ruRU;
		case "uk":
			return ukUA;
		case "tr":
			return trTR;
		case "ro":
			return roRO;
	}
}

const fetchTranslations = async (lang) => {
	const prpcow = getPRPC();

	if (!prpcow?.theirsModel?.language?.getLanguage) return null;

	const res = await createRPCQuery(
		() => prpcow?.theirsModel.language.getLanguage({ lang }),
		{ silent: false, error: true },
	);

	return res;
};

const App: React.FC = () => {
	const prpcow = getPRPC();

	const dispatch = useTypedDispatch();
	const { i18n } = useTranslation();
	const token = useTypedSelector((state) => state.session.token);
	const language = useTypedSelector((state) => state.session.language);
	const languageUser = useTypedSelector(
		(state) => state.account.user?.settings?.language,
	);

	const [translateMap, setTranslateMap] = useState<
		Map<Language, Record<string, any>>
	>(new Map());

	const connected = useTypedSelector((state) => state.prpc.connected);
	const authorized = useTypedSelector((state) => state.prpc.authorized);
	const id = useTypedSelector((state) => state.account.user?.id);
	const {
		id: dispatcherId,
		uaConfig,
		sipValue,
	} = useTypedSelector((state) => state.sipToDispatcher);

	useEffect(() => {
		if (dispatch) {
			dispatch(getServices());
			dispatch(getClasses());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const prpcowGetLanguageHash = useMemo(
		() => !!prpcow?.theirsModel?.language?.getLanguage,
		[prpcow?.theirsModel?.language?.getLanguage],
	);

	const updateTranslate = useCallback(
		(data, languageUser) => {
			if (!data || data?.error) return;

			setTranslateMap((prev) => prev.set(languageUser, data));

			i18n.addResources(languageUser, "translation", data).init({
				lng: languageUser,
				fallbackLng: "dev",
				debug: false,
				resources: {
					[languageUser]: {
						translation: data,
					},
					dev: {
						translation: languagePackages.uk.translation,
					},
				},
				preload: false,
				react: {
					useSuspense: false,
				},
				initImmediate: false,
				interpolation: {
					escapeValue: false,
				},
			});

			i18n.changeLanguage(languageUser);
		},
		[i18n],
	);

	// Initialize with default language
	useEffect(() => {
		if (prpcowGetLanguageHash) {
			if (languageUser) {
				if (translateMap.size) {
					const exist = translateMap.get(languageUser);

					if (exist) {
						i18n.init({
							lng: languageUser,
							fallbackLng: "dev",
							debug: false,
							resources: {
								[languageUser]: { translation: exist },
								dev: {
									translation:
										languagePackages.uk.translation,
								},
							},
							preload: false,
							react: {
								useSuspense: false,
							},
							initImmediate: false,
							interpolation: {
								escapeValue: false,
							},
						});

						i18n.changeLanguage(languageUser);

						return;
					}
				}

				fetchTranslations(languageUser).then((data) => {
					updateTranslate(data, languageUser);
				});
			} else {
				fetchTranslations(language).then((data) => {
					updateTranslate(data, language);
				});
			}
		}
	}, [
		i18n,
		languageUser,
		language,
		prpcowGetLanguageHash,
		translateMap,
		updateTranslate,
	]);

	useEffect(() => {
		dispatch(Session.create(token?.value));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);

	useEffect(() => {
		(async () => {
			if (connected) {
				dispatch(currentIP.actions.setValue(await getCurrentIP()));
			}
		})();
	}, [dispatch, connected]);

	useEffect(() => {
		(async () => {
			if (!connected) return;

			const applicationConfig = await getApplicationConfig();

			if (!applicationConfig) return;

			dispatch(serverSettings.actions.setConfig(applicationConfig));
		})();
	}, [dispatch, connected]);

	usePersistRoot(prpcow);
	useGlobalSettings();
	useOrderSettings();
	useSettingsInit();
	useKeyBindInit();

	useEffect(() => {
		moment.locale(language);
	}, [language]);

	const localeText = useMemo(() => {
		const locale = getMUILocale(language);
		return locale.components.MuiLocalizationProvider.defaultProps
			.localeText;
	}, [language]);

	const initSipSession = useCallback(async () => {
		const sipData = sessionStorage.getItem("sipData");
		if (sipData) {
			const parsedSipData = JSON.parse(sipData);
			const { lineType, sipToDispatcherId, sipValue } = parsedSipData;

			const data = await SIPToDispatcher.initSession({
				sipToDispatcherId,
			});

			if (data?.success) {
				dispatch(
					sipToDispatcher.actions.setSip({
						id: sipToDispatcherId,
						sipValue,
					}),
				);
				dispatch(sipToDispatcher.actions.setLineType(lineType));
			}
		}
	}, [dispatch]);

	useEffect(() => {
		if (id) {
			if (sessionStorage.getItem("sipData") && connected) {
				initSipSession();
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [connected, id]);

	const clearAndUpdateSipToDispatcher = useCallback(async () => {
		if (dispatcherId) {
			if (uaConfig) {
				dispatch(softphoneWidget.actions.clearCalls());
				dispatch(softphoneWidget.actions.setSelectedCaller(null));
				uaConfig?.ua?.stop();
				dispatch(sipToDispatcher.actions.setUaConfig(null));
			}

			const data = await SIPToDispatcher.getById(dispatcherId);
			await sipConnect(data?.sip);
		}
	}, [dispatch, dispatcherId, uaConfig]);

	const initDispatcherSip = useCallback(async () => {
		clearAndUpdateSipToDispatcher();
	}, [clearAndUpdateSipToDispatcher]);

	useEffect(() => {
		initDispatcherSip();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatcherId]);

	useKeyBind([KEYBOARD_CODES.PAGE_UP], (e) => {
		e.preventDefault();
		e.stopPropagation();

		callUp(true);
	});
	useKeyBind([KEYBOARD_CODES.PAGE_DOWN], (e) => {
		e.preventDefault();
		e.stopPropagation();

		getAndCallDown();
	});

	return (
		<KeyBindLayer>
			<LocalizationProvider
				dateAdapter={AdapterMoment}
				localeText={localeText}
			>
				<Icon.Provider />
				{connected && sipValue && <CallProvider />}
				{connected && authorized && <AdditionalTariffLoader />}
				{connected && authorized && <OrderSettingsLoader />}

				<Float.Container id="root">
					<Float.Tracker id="root">
						<Suspense fallback={<SuspenseLoader />}>
							<RouterSwitch />
						</Suspense>
					</Float.Tracker>
				</Float.Container>
			</LocalizationProvider>
		</KeyBindLayer>
	);
};

export default App;
