import { react } from "uikit";
import { Dispatch } from "react";

import { PaymentCurrency } from "../../../../../../../../services/PaymentSystem";

import CompaniesAndTaxiServices from ".";

interface Context {
	value: CompaniesAndTaxiServices.Value;
	setError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isValid =
			this.context?.value.assetPaymentsCurrency !== PaymentCurrency.NULL;

		if (!isValid) {
			this.context?.setError(true);
			return isValid;
		}
		this.context?.setError(false);
		return isValid;
	}
}
