import React, { useEffect, useCallback, memo, useMemo, useState } from "react";
import { Key } from "uikit";

import { useTypedSelector } from "../../../redux/store";
import {
	Language,
	languageOptions,
	languages,
} from "../../../assets/languages/langs";
import { useAppContext } from "../../../context";
import { Select2 } from "..";

const LanguageSelect: React.FC<LanguageSelect.Props> = ({
	onSelect,
	value,
}) => {
	const { getTaxiServiceLanguages } = useAppContext();

	const defaultTaxiServiceId = useTypedSelector(
		(state) => state.session.defaultTaxiServiceId,
	);
	const language = useTypedSelector((state) => state.session.language);

	const [languageExclude, setLanguageExclude] = useState<Language[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const _value = useMemo(() => value || language, [language, value]);

	useEffect(() => {
		if (!defaultTaxiServiceId) return;

		const updateLanguages = async () => {
			const existLanguages =
				getTaxiServiceLanguages(defaultTaxiServiceId);

			if (existLanguages.length > 0) {
				const excluded = languages.filter(
					(lang) => !existLanguages.includes(lang),
				);
				setLanguageExclude(excluded);
				setIsLoading(false);
			}
		};

		updateLanguages();

		const intervalId = setInterval(updateLanguages, 200);

		// eslint-disable-next-line consistent-return
		return () => clearInterval(intervalId);
	}, [defaultTaxiServiceId, getTaxiServiceLanguages]);

	const selectOptions = useMemo<LanguageSelect.LanguageSelectElements>(
		() =>
			languageOptions.filter(
				(item) => !languageExclude.includes(item.value),
			),
		[languageExclude],
	);

	const onChangeSelect = useCallback(
		(value: LanguageSelect.LanguageSelectElement) => {
			onSelect(value);
		},
		[onSelect],
	);

	useEffect(() => {
		if (selectOptions.length > 0) {
			const matchingOption = selectOptions.find(
				(option) => option.value === _value,
			);
			if (matchingOption) {
				onChangeSelect(matchingOption);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectOptions, _value]);

	return (
		<Select2
			value={_value}
			options={selectOptions}
			onSelect={onChangeSelect}
			w="130px"
			isLoading={isLoading}
		/>
	);
};

declare namespace LanguageSelect {
	interface Props {
		onSelect: (value: LanguageSelectElement) => void;
		value?: Language;
	}

	interface LanguageSelectElement {
		key: Key;
		label: string;
		value: Language;
	}

	type LanguageSelectElements = LanguageSelectElement[];
}

export const LanguageSelectMemo = memo(LanguageSelect);
export default LanguageSelect;
