import * as ModelEvent from "@node-elion/syncron";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import {
	NonEditableProperties,
	NonEditablePropertyNames,
} from "../../types/NonEditableProperties";
import { IName } from "../../types/IName";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import { ModelId } from "../../types/ModelId";
import Subscription from "../../types/Subscription";
import ServicesTab from "../../pages/Settings/pages/Tariffs/tabs/Main/components/Modal/components/Content/tabs/ServicesTab";
import Content from "../../pages/Settings/pages/Tariffs/tabs/Main/components/Modal/components/Content";
import { TaxiServiceOld as TaxiService, Language, Base, Card } from "..";

const logger = createLogger({ name: "Tariff" });

class Tariff extends Base {
	private static _Card: Card | null = null;

	public static get Card() {
		if (this._Card) return this._Card;
		this._Card = new Card((prpc) => prpc.theirsModel.rate.card);
		return this._Card;
	}

	public static fromResponse(data: any): Tariff.Model {
		const taxiServices = data.rateToTaxiServices.flatMap(
			(item) => item.taxiService,
		);

		const { units } = data.additionalFields.general.hourlyServiceInterval;
		const externalValue =
			data.additionalFields.general.hourlyServiceInterval.value;
		const value =
			units === "hours"
				? externalValue / 1000 / 3600
				: externalValue / 1000 / 60;

		return {
			id: data.id,

			name: data.name,
			active: data.active,
			default: data.default,
			root: data.root,

			companyIds: [],

			carClassIds: data?.rateToCarClasses?.map(
				(rateToCarClass) => rateToCarClass?.carClass?.id,
			),
			additionalFields: {
				...data.additionalFields,
				general: {
					...data.additionalFields.general,
					hourlyServiceInterval: {
						...data.additionalFields.general.hourlyServiceInterval,
						value,
					},
				},
			},
			services: data.rateToServices.map((item) => {
				if (item.additionalFields) {
					return {
						id: item.service.id,
						additionalFields: item.additionalFields,
					};
				}
				return {
					id: item.service.id,
				};
			}),
			taxiServices,
			taxiServiceIds: taxiServices.map(({ id }) => id),

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	public static toRequest(model: Tariff.New | Tariff.Modified): any {
		let { additionalFields } = model;
		const general = additionalFields?.general;

		if (additionalFields && general) {
			const { units } = general.hourlyServiceInterval;
			const externalValue = general.hourlyServiceInterval.value;
			const value =
				units === "hours"
					? externalValue * 1000 * 3600
					: externalValue * 1000 * 60;

			additionalFields = {
				...additionalFields,
				general: {
					...general,
					hourlyServiceInterval: {
						...general.hourlyServiceInterval,
						value,
					},
				},
			};
		}

		const retval = {
			name: model.name,
			active: model.active,
			default: model.default,
			additionalFields,
			carClassIds: model.carClassIds,
			taxiServiceIds: model.taxiServiceIds,
			services: model.services,
		};

		return retval;
	}

	public static async store(object: Tariff.New, force = false) {
		try {
			logger.info("[Tariff] store", { object, force });
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.rate.create(
						Tariff.toRequest(object),
						force,
					),
				{ silent: false, error: true },
			);

			return res;
		} catch (err: any) {
			if (err.message.includes("force")) return false;
			return true;
		}
	}

	public static async copy(id) {
		try {
			await this.request((prpc) => prpc.theirsModel.rate.copy(id));
		} catch (err: any) {
			throw new Error("Error in copy method:", err);
		}
	}

	public static async update(object: Tariff.Modified, force = false) {
		try {
			logger.info("[Tariff] update", { object, force });
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.rate.update(
						object.id,
						Tariff.toRequest(object),
						force,
					),
				{ silent: false, error: true },
			);

			return res;
		} catch (err: any) {
			if (err.message.includes("force")) return false;
			return true;
		}
	}

	public static async destroy(id: number[] | number) {
		this.request((prpc) => prpc.theirsModel.rate.delete(id));
	}

	public static async subscribe(
		options: Tariff.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<Tariff.Model>,
	): Promise<Subscription<Tariff.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				logger.info("[Tariff] subscribe", state);
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add((prpc) =>
			prpc.theirsModel.rate.subscribe({
				params: this.optionsToRequest(options),
				ping: () => true,
				onEvent: (events) => {
					modelEventConstructor.onEvent(events);
				},
				onError: (error) => {
					logger.error(error);
				},
			}),
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: Tariff.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<Tariff.SubscribeOptions>;
	}

	private static optionsToRequest(options: Tariff.SubscribeOptions) {
		return {
			lang: options.language,
			active: options.active,
			default: options.default,
			taxiServiceIds: options.taxiServiceIds,

			limit: options.limit,
			offset: options.offset,
			order: options.order,
			query: options.query,
		};
	}
}

declare namespace Tariff {
	interface Model extends NonEditableProperties {
		active: boolean;
		default: boolean;
		readonly root: boolean;
		name: IName;
		additionalFields: Content.AdditionalFields;
		taxiServices: TaxiService.Model[];
		taxiServiceIds: number[];
		carClassIds: number[];
		companyIds: number[];
		services: ServicesTab.ServiceType[];
	}

	type New = Omit<Model, NonEditablePropertyNames | "taxiServices">;
	type Modified = Partial<New> & { id: ModelId };

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<Tariff.Model> {
		active?: boolean;
		default?: boolean;
		language?: Language;
		taxiServiceIds?: number[];
	}
}

export default Tariff;
