import { ColumnId } from "../../../components/OrderPageWidgets/OrderTable/components/ModelTable/columns";
import makeTable from "../makeTable";

import { initialSortMethod, initialWidths } from "./data";

const allIds = Object.values(ColumnId);

const hiddenColumns = [ColumnId.TransportationType];

const defaultIds = allIds.filter((id) => !hiddenColumns.includes(id));

const orderAll = makeTable({
	allIds,
	defaultIds,
	i18tPath: (id) => `tableColumns.orders.${id}`,

	initialSortMethod,
	initialWidths,
});

export default orderAll;
