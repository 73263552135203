import { react } from "uikit";
import { Dispatch } from "react";

import CompaniesAndTaxiServices from ".";

interface Context {
	value: CompaniesAndTaxiServices.Value;
	required: boolean;

	setCompanyIdsError: Dispatch<boolean>;
	setTaxiServiceIdsError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const required = this.context?.required;

		if (!required) return true;

		const isTaxiServiceIdsValid =
			!!this.context?.value.taxiServiceIds.length;
		const isCompanyIdsValid = !!this.context?.value.companyIds.length;

		if (!isTaxiServiceIdsValid) this.context?.setTaxiServiceIdsError(true);
		if (!isCompanyIdsValid) this.context?.setCompanyIdsError(true);

		return isCompanyIdsValid && isTaxiServiceIdsValid;
	}
}
