import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Order from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";

const CloseReasonCellContent: React.FC<CloseReasonCellContent.Props> = ({
	item,
}) => {
	const { t } = useTranslation();

	const lastReceivedOrder = useMemo(() => {
		const value = item?.additionalFields?.transfer?.lastReceivedOrder;
		return value;
	}, [item]);

	const comment = useMemo(() => {
		const toValue = item.closedOrderComments?.[0];

		if (toValue?.status === "hide_by_transfer") {
			const fromTransferValue =
				lastReceivedOrder?.closedOrderComments?.[0];
			return fromTransferValue;
		}

		return toValue;
	}, [item.closedOrderComments, lastReceivedOrder?.closedOrderComments]);

	const reason = useMemo(
		() => (comment ? t(`order_closed_status.${comment.status}`) : ""),
		[comment, t],
	);

	const by = useMemo(() => {
		if (!comment) return "";

		if (comment.dispatcher) {
			return t(`order_closed_by.dispatcher`);
		}
		if (comment.executor) {
			return t(`order_closed_by.executor`);
		}
		if (comment.customer) {
			return t(`order_closed_by.customer`);
		}

		return "";
	}, [comment, t]);

	const content = useMemo(
		() => `${reason} ${by ? `(${by})` : ""}`.trim(),
		[by, reason],
	);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace CloseReasonCellContent {
	interface Props {
		item: Order.Model;
	}
}

export default CloseReasonCellContent;
