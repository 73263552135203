import React, {
	Dispatch,
	RefAttributes,
	useCallback,
	useMemo,
	useState,
} from "react";
import { react } from "uikit";
import { useTranslation } from "react-i18next";

import LabeledField from "../../../../../../../../../../components/LabeledField";
import { MultiSelectCarClassWithModal } from "../../../../../../../../../../components/common";
import { EntityEditModal } from "../..";

import InternalController from "./Controller";

const CarClassSelect = react.withController<
	CarClassSelect.Props,
	CarClassSelect.Controller
>(
	({
		controller,

		value,
		onChange,

		disabled,
		valueIds,
	}) => {
		const { t } = useTranslation();

		const [error, setError] = useState(false);

		const subscribeOptions = useMemo(() => {
			const retval = {
				...(valueIds.carBodyTypeIds?.length > 0
					? { carBodyTypeIds: valueIds.carBodyTypeIds }
					: {}),
				...(valueIds.carModelIds?.length > 0
					? { carModelIds: valueIds.carModelIds }
					: {}),
			};
			return retval;
		}, [valueIds.carBodyTypeIds, valueIds.carModelIds]);

		const selectOnChange = useCallback(
			(newValue: CarClassSelect.Value) => {
				setError(false);

				onChange(newValue as CarClassSelect.Value);
			},
			[onChange],
		);

		controller.setContext({ value, setError });

		return (
			<LabeledField
				label={
					t(
						`mainPage.customers.clients.main.additionalData.vehicleClass`,
					) || "car class"
				}
			>
				<MultiSelectCarClassWithModal
					// value={value ? [value] : []}
					value={value ?? []}
					disabled={disabled}
					error={disabled ? false : error}
					// onChange={(values) => selectOnChange(values.at(0))}
					onChange={(values) => selectOnChange(values)}
					titleText={
						t(
							"pages.mainPage.pages.accounts.tabs.car.editModal.content.mainTab.mainData.carClassIId.str0",
						) ?? ""
					}
					// required
					showSelectAll={false}
					subscribeOptions={subscribeOptions}
					sort={{ sortType: "asc" }}
				/>
			</LabeledField>
		);
	},
	InternalController,
);

declare namespace CarClassSelect {
	type Ref = InternalController | null;

	type Controller = InternalController;

	type Value = number[];

	interface PropsBase {
		value: Value;
		onChange: Dispatch<Value>;

		valueIds: EntityEditModal.ValueIds;
		disabled?: boolean;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default CarClassSelect;
