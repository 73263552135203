import L, { LatLngBounds, LatLngTuple } from "leaflet";
import { react } from "uikit";

import Map from "../../../../../services/Map";

interface Context {
	map?: L.Map;

	object?: Map.Search.Object | null;
}

export default class Controller extends react.ControllerBase<Context> {
	focus() {
		const { map, object } = this.context ?? {};

		if (!map || !object) {
			return false;
		}

		const { geometry } = object;

		if (geometry.type === "Point") {
			map.setView(geometry.coordinates, 16, { animate: false });
		} else if (geometry.type === "LineString") {
			const bounds = new LatLngBounds([]);

			geometry.coordinates.forEach((coordinate) => {
				bounds.extend([coordinate.lat, coordinate.lng] as LatLngTuple);
			});

			map.fitBounds(bounds, {
				padding: [40, 40],
				maxZoom: 19,
				animate: false,
			});
		} else if (
			geometry.type === "MultiLineString" ||
			geometry.type === "Polygon"
		) {
			const bounds = new LatLngBounds([]);

			geometry.coordinates.forEach((coordinates) => {
				coordinates.forEach((coordinate) => {
					bounds.extend([
						coordinate.lat,
						coordinate.lng,
					] as LatLngTuple);
				});
			});

			map.fitBounds(bounds, {
				padding: [40, 40],
				maxZoom: 19,
				animate: false,
			});
		}

		return true;
	}
}
