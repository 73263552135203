// In back-end - src\microservices\migration.microservice\src\migration\rates.migration.ts

export const CURRENCIES = {
	UAH: {
		code: "UAH",
		symbol: "₴",
		name: "грн",
		excelCode: "[$₴-uk-UA]",
	},
	PLN: {
		code: "PLN",
		symbol: "zł",
		name: "zł",
		excelCode: "[$zł-pl-PL]",
	},
	AZN: {
		code: "AZN",
		symbol: "₼",
		name: "₼",
		excelCode: "[$₼-az-AZ]",
	},
	MDL: {
		code: "MDL",
		symbol: "L",
		name: "lei",
		excelCode: "[$L-ro-MD]",
	},
} as const;

declare namespace СurrencyDefinitions {
	type Code = (typeof CURRENCIES)[keyof typeof CURRENCIES]["code"];
	type Symbol = (typeof CURRENCIES)[keyof typeof CURRENCIES]["symbol"];
	type Name = (typeof CURRENCIES)[keyof typeof CURRENCIES]["name"];
	type ExcelCode = (typeof CURRENCIES)[keyof typeof CURRENCIES]["excelCode"];

	type Key = keyof typeof CURRENCIES;

	type Value = {
		[K in Key]: {
			code: (typeof CURRENCIES)[K]["code"];
			symbol: (typeof CURRENCIES)[K]["symbol"];
			name: (typeof CURRENCIES)[K]["name"];
			excelCode?: (typeof CURRENCIES)[K]["excelCode"];
		};
	}[Key];
}

export default СurrencyDefinitions;
