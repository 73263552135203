/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-shadow */

import React, {
	Dispatch,
	RefAttributes,
	memo,
	useCallback,
	useMemo,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Column, Row, Select, TextBox, react } from "uikit";
import Language from "../../../../../../../../../../../../services/Language";
import CustomerRate from "../../../../../../../../../../../../services/CustomerRate";
import useObjectEditor from "../../../../../../../../../../../../hooks/useObjectEditor";
import LabeledField from "../../../../../../../../../../../../components/LabeledField";
import FieldsContainer from "../../../../../../../../../../../../components/FieldsContainer";

import InternalController from "./Controller";
import TabRoot from "../TabRoot";

import Access from "./components/Access";
import { modeOptions, typeOptions } from "./constants";
import Discount from "./components/Discount";

import FixedMode from "./components/FixedMode";
import CumulativeMode from "./components/CumulativeMode";
import BonusTripMode from "./components/BonusTripMode";
import BasicMode from "./components/BasicMode";
import FlexibleMode from "./components/FlexibleMode";

const MainTab = memo(
	react.withController<MainTab.PropsBase, MainTab.Controller>(
		({ value, disabled, visible, language, onChange }) => {
			const { t } = useTranslation();
			const valueEditor = useObjectEditor(value, onChange);

			const assignValue = valueEditor.useAssigner();

			const nameValue = valueEditor.useGetter("name");

			const [nameError, setNameError] = useState(false);

			const nameOnChange = useCallback(
				(name: string) => {
					setNameError(false);

					valueEditor.set("name", name);
				},
				// eslint-disable-next-line react-hooks/exhaustive-deps
				[valueEditor.set],
			);

			const accessValue = valueEditor.usePicker(["companyIds"]);

			const typeValue = valueEditor.useGetter("type");
			const typeOnChange = valueEditor.useSetter("type");

			const modeValue = valueEditor.useGetter("mode");
			const modeOnChange = valueEditor.useSetter("mode");

			const modeDataEditor = valueEditor.usePropertyEditor("modeData");

			const fixedModeValue = modeDataEditor.useGetter("fixed");
			const fixedModeOnChange = modeDataEditor.useSetter("fixed");

			const cumulativeModeValue = modeDataEditor.useGetter("cumulative");
			const cumulativeModeOnChange =
				modeDataEditor.useSetter("cumulative");

			const bonusTripModeValue = modeDataEditor.useGetter("bonusTrip");
			const bonusTripModeOnChange = modeDataEditor.useSetter("bonusTrip");

			const basicModeValue = modeDataEditor.useGetter("basic");
			const basicModeOnChange = modeDataEditor.useSetter("basic");

			const flexibleModeValue = modeDataEditor.useGetter("flexible");
			const flexibleModeOnChange = modeDataEditor.useSetter("flexible");

			const firstRow = useMemo(
				() => (
					<Row gaps="8px*" sizes="1fr*">
						<LabeledField
							label={
								t(
									"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.str0",
								) ?? ""
							}
						>
							<TextBox.TextBox
								value={nameValue}
								disabled={disabled}
								style={{
									minHeight: "32px",
									flex: "1 0 0",
								}}
								autoComplete="one-time-code"
								placeholder={
									t(
										"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.str0",
									) ?? ""
								}
								error={nameError}
								onChange={nameOnChange}
							/>
						</LabeledField>
						<Access
							value={accessValue}
							disabled={disabled}
							language={"uk"}
							onChange={assignValue}
						/>
						<LabeledField
							label={
								t(
									"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.str2",
								) ?? ""
							}
						>
							<Select
								value={typeValue}
								disabled={disabled}
								style={{
									minHeight: "32px",
									flex: "1 0 0",
								}}
								placeholder={
									t(
										"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.str2",
									) ?? ""
								}
								options={typeOptions}
								onSelect={(option) =>
									typeOnChange(option.value)
								}
							/>
						</LabeledField>
						<LabeledField
							label={
								t(
									"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.str4",
								) ?? ""
							}
						>
							<Select
								value={modeValue}
								disabled={disabled}
								style={{
									minHeight: "32px",
									flex: "1 0 0",
								}}
								placeholder={
									t(
										"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.str4",
									) ?? ""
								}
								options={modeOptions}
								onSelect={(option) =>
									modeOnChange(option.value)
								}
							/>
						</LabeledField>
					</Row>
				),
				[
					accessValue,
					assignValue,
					disabled,
					modeOnChange,
					modeValue,
					nameError,
					nameOnChange,
					nameValue,
					t,
					typeOnChange,
					typeValue,
				],
			);

			const generalValue = valueEditor.useGetter("generalData");
			const generalOnChange = valueEditor.useSetter("generalData");

			return (
				<TabRoot hasPaddings visible={visible}>
					<Column
						sizes={
							modeValue === "basic" || modeValue === "flexible"
								? "auto!* 1fr"
								: "auto!*"
						}
						gaps="19px*"
						maxedWidth
						maxedHeight
					>
						<FieldsContainer
							label={
								t(
									"pages.settings.pages.finances.tabs.customerTariffPlans.editModal.content.mainTab.str6",
								) ?? ""
							}
						>
							<Column gaps="8px*">{firstRow}</Column>
						</FieldsContainer>
						<Discount
							value={generalValue}
							disabled={disabled}
							onChange={generalOnChange}
						/>
						{modeValue === "fixed" ? (
							<FixedMode
								value={fixedModeValue}
								disabled={false}
								onChange={fixedModeOnChange}
							/>
						) : null}
						{modeValue === "cumulative" ? (
							<CumulativeMode
								value={cumulativeModeValue}
								disabled={disabled}
								onChange={cumulativeModeOnChange}
							/>
						) : null}
						{modeValue === "bonusTrip" ? (
							<BonusTripMode
								value={bonusTripModeValue}
								disabled={disabled}
								onChange={bonusTripModeOnChange}
							/>
						) : null}
						{modeValue === "basic" ? (
							<BasicMode
								value={basicModeValue}
								disabled={disabled}
								onChange={basicModeOnChange}
							/>
						) : null}
						{modeValue === "flexible" ? (
							<FlexibleMode
								value={flexibleModeValue}
								disabled={disabled}
								onChange={flexibleModeOnChange}
							/>
						) : null}
					</Column>
				</TabRoot>
			);
		},
		InternalController,
	),
);

declare namespace MainTab {
	type Ref = InternalController | null;

	type Controller = InternalController;

	interface Value extends Access.Value {
		name: string;

		type: CustomerRate.Model.Type;
		mode: CustomerRate.Model.Mode;
		modeData: CustomerRate.Model.ModeData;

		generalData: Discount.Value;
	}

	interface PropsBase {
		value: Value;

		disabled: boolean;
		visible: boolean;
		language: Language;

		onChange: Dispatch<Value>;
	}

	type Props = PropsBase & RefAttributes<Ref>;
}

export default MainTab;
