import React, { memo, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Tooltip } from "react-leaflet";

import { formatNumber } from "../../../../utils";

export const getPersonFullName = (data) =>
	`${data?.person?.lastName ? data.person.lastName : ""} ${
		data?.person?.firstName ? data.person.firstName : ""
	} ${data?.person?.fatherName ? data.person.fatherName : ""}`;

export const getBalance = (data) => {
	const main =
		data?.paymentAccounts?.find((a) => a.type === "main")?.amount ?? 0;

	return formatNumber(main) || "";
};

export const getPersonPhones = (data) => {
	const phones =
		data?.person?.phones.map((phone) => phone.number).join(", ") || "";
	return phones;
};

export const getCar = (data, lang) => {
	const carShiftJournals = data?.executorShiftJournals?.length
		? data?.executorShiftJournals?.[0]?.car
		: null;

	const executorToCrews = data?.executorToCrews || [];

	const crew = carShiftJournals
		? executorToCrews.find(
				({ crew }) => crew.car.id === carShiftJournals.id,
		  )?.crew
		: data?.executorToCrews?.[0]?.crew;

	const car = crew ? crew?.car : data?.executorToCrews?.[0]?.crew?.car;

	const registrationNumber = car?.additionalFields?.registrationNumber || "";
	const color = car?.color?.name?.[lang] || "";
	const callSign = car?.callSign || "";

	return {
		carData: `${
			car?.modelToBodyType?.model?.baseTypeToBrand?.brand?.name || ""
		} ${car?.modelToBodyType?.model?.name || ""} ${color || ""} ${
			registrationNumber ? `(${registrationNumber})` : ""
		} ${callSign ? `(${callSign})` : ""}`,
		carType: `${car?.class?.name?.[lang] || ""}`,
	};
};

export const getPriority = (data) => data?.priority || 0;
export const getRating = (data) => {
	const rating = data?.rating || "";
	// const rating = await ExecutorFeedback.getRating(value.id);
	return rating;
};

export const getLastOrder = (data) => {
	const max = data?.executorToOrders?.length
		? Math.max(
				...data.executorToOrders.map((a) =>
					new Date(a?.order?.closedAt).getTime(),
				),
		  )
		: "";
	return max ? moment(max).format("HH:mm DD/MM/yyyy") : "";
};

const ExecutorInfoBase: React.FC<ExecutorInfoBase.Props> = ({ executor }) => {
	const { t } = useTranslation();

	return (
		<Tooltip direction="bottom">
			<div>
				<div>
					{t("orderPageWidgets.parking.parkingTable.str209") ?? ""}:{" "}
					{executor?.alias}
				</div>
				<div>
					{t("orderPageWidgets.parking.parkingTable.str210") ?? ""}:{" "}
					{executor.fullName}
				</div>
				<div>
					{t("orderPageWidgets.parking.parkingTable.str211") ?? ""}:{" "}
					{executor.status || ""}
				</div>
				<div>
					{t("orderPageWidgets.parking.parkingTable.str212") ?? ""}:{" "}
					{executor.phones}
				</div>
				<div>
					{t("utils.getTabsModeOne.cars") ?? ""}: {executor.carData}
				</div>
				<div>
					{t("class") ?? ""}: {executor.carType}
				</div>
				<div>
					{t("orderPageWidgets.parking.parkingTable.str214") ?? ""}:{" "}
					{executor.online}
				</div>

				<div>
					{t("balances.main") ?? ""}: {executor.balance}
				</div>
				<div>
					{t("orderPageWidgets.parking.parkingTable.str219") ?? ""}:{" "}
					{executor.order}
				</div>

				<div>
					{t("orderPageWidgets.parking.parkingTable.str220") ?? ""}:{" "}
					{executor.speed}
				</div>

				<div>
					{t(
						"pages.settings.pages.orders.tabs.ordersDistribution.modal.options.priority",
					) ?? ""}
					: {executor.priority}
				</div>

				<div>
					{t("utils.getColumnsModeOne.rating") ?? ""}:{" "}
					{executor.rating}
				</div>
			</div>
		</Tooltip>
	);
};

declare namespace ExecutorInfoBase {
	interface Props extends PropsWithChildren {
		executor: {
			alias: string;
			online: string;
			status: string;
			priority: number;
			speed: string;
			carType: string;
			carData: string;
			phones: string;
			balance: string;
			fullName: string;
			order: string;
			rating: number;
		};
	}
}

export const ExecutorInfo = memo(ExecutorInfoBase);

export default ExecutorInfoBase;
