import * as ModelEvent from "@node-elion/syncron";
import { omit } from "lodash";
import { v4 as uuidv4 } from "uuid";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import { NonEditableProperties } from "../../types/NonEditableProperties";
import Subscription from "../../types/Subscription";
import { Language, Base, Card, TaxiService, Sector, CarClass } from "..";

const logger = createLogger({ name: "OrderDistributionSettings" });

class OrderDistributionSettings extends Base {
	private static _Card: Card | null = null;

	public static get Card() {
		if (this._Card) return this._Card;

		this._Card = new Card(
			(prpc) => prpc.theirsModel.settings.orderDistribution.card,
		);

		return this._Card;
	}

	public static fromResponse(data: any): OrderDistributionSettings.Model {
		const { taxiServices } = data;

		const gpsRulesModify = ({
			gpsRules,
		}: OrderDistributionSettings.Model) => {
			if (!gpsRules?.length) return [];
			return gpsRules.map((item) => {
				const sectorIds = Base.getIds(item?.sectors);
				const carClassIds = Base.getIds(item?.carClasses);
				const distributionRules =
					item.settings?.distributionRules?.map((rule) => ({
						...rule,
						id: uuidv4(),
					})) || [];

				return {
					...(item || {}),
					carClassIds,
					sectorIds,
					settings: {
						...(item?.settings || {}),
						distributionRules,
					},
				};
			});
		};

		return {
			id: data.id,
			name: data.name,
			active: data.active,
			root: data.root || false,

			mode: data.mode,

			taxiServices,
			taxiServiceIds: Base.getIds(taxiServices),

			companyIds: [],

			settings: data.settings,
			gpsRules: gpsRulesModify(data),

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	public static toRequest(
		model:
			| OrderDistributionSettings.New
			| OrderDistributionSettings.Modified,
		fnType: "create" | "update",
	) {
		const gpsRules = model.gpsRules
			?.filter((item) => item.type)
			?.map((gpsRule) => {
				const distributionRules =
					[...(gpsRule.settings?.distributionRules || [])].map(
						(rule) => omit(rule, ["id"]),
					) || [];

				const item = {
					...gpsRule,
					settings: {
						...(gpsRule.settings || {}),
						distributionRules,
					},
				};

				if (fnType === "update") {
					const itemModify = omit(item, [
						"carClasses",
						"sectors",
						"createdAt",
						"updatedAt",
					]);

					const updateItem = {
						id: itemModify.id,
						type: itemModify.type,
						rule: omit(itemModify, ["id", "type"]),
					};

					return updateItem;
				}

				return omit(item, [
					"id",
					"carClasses",
					"sectors",
					"type",
					"createdAt",
					"updatedAt",
				]);
			});

		const payload = {
			name: model.name,
			active: model.active,
			mode: model.mode,

			taxiServiceIds: model.root ? [] : model.taxiServiceIds,

			settings: model.settings,

			gpsRules,
		};
		logger.info("toRequest", {
			model,
			payload,
		});
		return payload;
	}

	public static async getAll(
		options?: OrderDistributionSettings.SubscribeOptions,
	) {
		const response = await this.request((prpc) =>
			prpc.theirsModel.settings.orderDistribution.getAll(
				this.optionsToRequest(options ?? {}),
			),
		);
		return (response?.items as any[])?.map(this.fromResponse);
	}

	public static async store(
		object: OrderDistributionSettings.New,
		force = false,
	) {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.settings.orderDistribution.create(
						force
							? { force, ...this.toRequest(object, "create") }
							: this.toRequest(object, "create"),
					),
				{ silent: false, error: true },
			);

			logger.info("[OrderDistributionSettings] store", {
				force,
				object,
				res,
			});

			return res;
		} catch (err: any) {
			return null;
		}
	}

	public static async update(
		object: OrderDistributionSettings.Modified,
		force = false,
	) {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.settings.orderDistribution.update(
						object.id,
						force
							? { force, ...this.toRequest(object, "update") }
							: this.toRequest(object, "update"),
					),
				{ silent: false, error: true },
			);

			logger.info("[OrderDistributionSettings] update", {
				force,
				object,
				res,
			});

			return res;
		} catch (err: any) {
			return null;
		}
	}

	public static async destroy(ids: number[] | number) {
		this.request((prpc) =>
			prpc.theirsModel.settings.orderDistribution.delete(+ids),
		);
	}

	public static async subscribe(
		params: OrderDistributionSettings.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<OrderDistributionSettings.Model>,
	): Promise<Subscription<OrderDistributionSettings.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				logger.info("subscribe", state);
				onUpdate({
					...state,
					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.settings.orderDistribution.subscribe({
					params,
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						logger.error(error);
					},
				}),
			{ name: "OrderDistributionSettings", metadata: params },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: OrderDistributionSettings.SubscribeOptions) =>
				subscription.update(options),
		} as Subscription<OrderDistributionSettings.SubscribeOptions>;
	}

	private static optionsToRequest(
		options: OrderDistributionSettings.SubscribeOptions,
	) {
		return {
			query: options.query,
			offset: options.offset,
			limit: options.limit,

			includeTaxiServices: options.includeTaxiServices,
		};
	}
}
export enum OrderDistributionMode {
	PARKING = "parking",
	GPS = "gps",
	PARKING_GPS = "parking_gps",
	GPS_PARKING = "gps_parking",
}

export enum UpdateOrderDistributionGpsRulesTypeEnum {
	CREATE = "create",
	UPDATE = "update",
	DELETE = "delete",
}

export enum RouteCalculationMode {
	DIRECT = "direct",
	ROADS = "roads",
}

export enum OrderDistributionTimeMissingOrderMode {
	AFTER_PREVIOUS = "after_previous",
	AFTER_SESSION = "after_session",
	AFTER_DAY = "after_day",
	AFTER_HOURS = "after_hours",
}

export enum OfferAsMode {
	Required = "required",
	Optional = "optional",
}

export enum OrderDistributionAutoDistributionIntervalSource {
	APP = "app",
	PHONE = "phone",
	WEBSITE = "website",
	DISPATCHER = "dispatcher",
}

declare namespace OrderDistributionSettings {
	interface Model extends NonEditableProperties {
		name: Record<Language, string>;
		active: boolean;
		root: boolean;

		mode: OrderDistributionMode;

		/** only to front */
		taxiServices: TaxiService.Model[];
		taxiServiceIds: number[];
		/** only to front */
		companyIds: number[];

		settings: Model.Settings;
		gpsRules: OrderDistributionSettings.Model.GPSRule[];
	}

	interface ActiveValue {
		active: boolean;
		value: number;
	}

	namespace Model {
		interface GPSRule {
			/** This type is required to take action on service */
			type?: UpdateOrderDistributionGpsRulesTypeEnum;
			id: number;
			active: boolean;
			name: string;
			asMissingSector: boolean;
			searchInAllRules: boolean;
			/** only to front */
			carClasses: CarClass.Model[];
			carClassIds: number[];

			/** only to front */
			sectors: Sector.Model[];
			sectorIds: number[];

			settings: {
				distributionRules: GPSRule.DistributionRule[];
			};

			readonly createdAt?: string;
			readonly updatedAt?: string;
		}

		namespace GPSRule {
			interface DistributionRule {
				/** only to front */
				id: string;
				active: boolean;
				radius: { to: ActiveValue };
				time: {
					active: boolean;
					dow: number[];
					from: number;
					to: number;
				};
				routeCalculationMode: RouteCalculationMode;
				offerAsMode: OfferAsMode;
				timeMissingOrder: ActiveValue & {
					mode: OrderDistributionTimeMissingOrderMode;
					config: {
						timeFrom: number;
					};
				};
				rating: ActiveValue;
				priority: ActiveValue;
				ratingByOrderChain: ActiveValue;
				priorityByOrderChain: ActiveValue;
				executorBalance: ActiveValue;
			}
		}

		interface Parking {
			/** Возвращать на стоянку при отказе от обязательного заказа */
			returnToParkingOnMandatoryOrderRefusal: boolean;

			/** Возвращать на стоянку при отказе от принятого заказа со свободного эфира */
			returnToParkingOnFreeWaveOrderRefusal: boolean;

			/** Оставлять на стоянке при опоздании на обязательный заказ */
			returnToParkingOnMandatoryOrderAfterExpire: boolean;

			/** Оставлять на стоянке при снятии диспетчером с обязательного заказа */
			returnToParkingOnDispatcherRemovalFromMandatoryOrder: boolean;

			/** Возвращать на стоянку при отмене заказа */
			returnToParkingOnOrderCancellation: boolean;

			/** Разрешить постановку исполнителя на стоянку */
			allowExecutorParking: boolean;

			/** Разрешить постановку исполнителя на стоянку только по геопозиции */
			allowExecutorParkingByGeolocation: boolean;

			/** Разрешить становиться на стоянку со статусом "Долг" */
			allowParkingWithDebtStatus: boolean;

			/** Разрешить становиться на стоянку со статусом "Обед" */
			allowParkingWithLunchStatus: boolean;

			/** Разрешить становиться на стоянку со статусом "Домой" */
			allowParkingWithHomeStatus: boolean;

			/** Разрешить становиться на стоянку со статусом "Занят" */
			allowParkingWithBusyStatus: boolean;

			/** Исключить  "Превдварительный заказ" */
			excludePreliminaryOrder: boolean;

			/** Исключить  "Свой заказ" */
			excludeOwnOrder: boolean;

			/** Ставить на стоянку назначения после закрытия заказа */
			autoAssignToParkingAfterOrderClosure: boolean;

			/** Предлагать стать на стоянку назначения после закрытия заказа */
			suggestParkingAfterOrderClosure: boolean;

			/** Предлагать стать на стоянку по GPS координатам после выхода на смену */
			suggestParkingByGPSAfterShiftStart: boolean;

			/** Автоматически ставить на стоянку при входе в пределы зоны стоянки */
			autoParkWhenInParkingZone: boolean;

			/** Автоматически покидать стоянку при выходе за пределы зоны стоянки */
			autoLeaveParkingWhenOutOfZone: boolean;

			/** Запретить становиться на стоянку до закрытия всех своих заказов */
			restrictParkingUntilAllOrdersClosed: boolean;

			/** Показывать СВОИ заказы в свободном эфире только водителям на стоянке */
			showOwnOrdersInBroadcastForParkingOnly: boolean;

			/** Показывать СВОИ предварительные заказы в свободном эфире только водителям на стоянке */
			showOwnPreOrdersInBroadcastForParkingOnly: boolean;

			/** Показывать заказы с БИРЖИ в свободном эфире только водителям на стоянке */
			showMarketOrdersInBroadcastForParkingOnly: boolean;

			/** Показывать предварительные заказы с БИРЖИ в свободном эфире только водителям на стоянке */
			showMarketPreOrdersInBroadcastForParkingOnly: boolean;
		}

		interface Settings extends Record<string, any>, Parking {
			enableReOffer: boolean;
			displayOffersInLiveTab: boolean;

			displayRequiredOffersInLiveTabAfterCancel: boolean;
			displayRequiredOffersInLiveTabAfterExpire: boolean;
			displayFreeWayOffersInLiveTabAfterCancel: boolean;
			displayFreeWayOffersInLiveTabAfterExpire: boolean;

			/** Показывать заказы в эфире исполнителям со статусом "На заказе" */
			showOrdersInBroadcastForOnOrderStatus: boolean;

			/** Показывать заказы в эфире исполнителям со статусом "Свой заказ" */
			showOrdersInBroadcastForOwnOrderStatus: boolean;

			/** Показывать заказы в эфире исполнителям со статусом "Долг" */
			showOrdersInBroadcastForDebtStatus: boolean;

			/** Показывать заказы в эфире исполнителям со статусом "Обед" */
			showOrdersInBroadcastForLunchStatus: boolean;

			/** Показывать заказы в эфире исполнителям со статусом "Домой" */
			showOrdersInBroadcastForHomeStatus: boolean;

			/** Показывать заказы в эфире исполнителям со статусом "Занят" */
			showOrdersInBroadcastForBusyStatus: boolean;

			/** Показывать исполнителям предварительные заказы */
			showExecutorsPreOrdersInBroadcast: boolean;

			/** Разрешить брать заказы из свободного эфира исполнителям со статусом "На заказе" */
			allowTakingOrdersWithOnOrderStatus: boolean;

			/** Разрешить брать заказы из свободного эфира исполнителям со статусом "Свой заказ" */
			allowTakingOrdersWithOwnOrderStatus: boolean;

			/** Разрешить брать заказы из свободного эфира исполнителям со статусом "Долг" */
			allowTakingOrdersWithDebtStatus: boolean;

			/** Разрешить брать заказы из свободного эфира исполнителям со статусом "Обед" */
			allowTakingOrdersWithLunchStatus: boolean;

			/** Разрешить брать заказы из свободного эфира исполнителям со статусом "Домой" */
			allowTakingOrdersWithHomeStatus: boolean;

			/** Разрешить брать заказы из свободного эфира исполнителям со статусом "Занят" */
			allowTakingOrdersWithBusyStatus: boolean;

			/** Разрешить брать заказы из свободного эфира предварительные заказы */
			allowTakingPreOrdersInBroadcast: boolean;

			hideOrderWhenDoPostExecutorOffer: boolean;
			manualAssignment: Settings.ManualAssignment;
			autoDistributionInterval: Settings.AutoDistributionInterval;
			modesConfig: Settings.ModesConfig;

			externalOrderDistribution: Settings.ExternalOrderDistribution;
		}

		namespace Settings {
			interface ExternalOrderDistribution {
				receive: {
					sendToDistribution: boolean;
				};
				send: {
					enabled: boolean;
					global: { enabled: boolean; timeout: number };
					local: { enabled: boolean; timeout: number };
					outside: { enabled: boolean; timeout: number };
				};
			}

			interface AutoDistributionInterval {
				sources: AutoDistributionInterval.Sources;
			}

			interface GPSRuleCoefficient {
				rating: {
					percent: number;
				};
				balance: {
					max: number;
					loosePriorityByPoint: number;
				};
				priority: {
					percent: number;
				};
				chainRating: {
					percent: number;
				};
				chainPriority: {
					percent: number;
				};
				timeMissingOrder: {
					max: number;
					priorityByHour: number;
				};
			}
			interface GPS extends Record<string, any> {
				orderOfferTime: number;
				/** offerOrderFromFreeWaveInRadius */
				offerOrderFromFreeWave: boolean;
				/** offerOrderFromFreeWaveInOrderChain */
				offerOrderFromFreeWaveInOrderChain: boolean;
				limitOrderVisibilityByRadius: ActiveValue;

				gpsRuleCoefficient: GPSRuleCoefficient;
			}

			interface GPSParking extends Record<string, any> {}

			interface Parking extends Record<string, any> {
				orderOfferTime: number;
				/** offerOrderFromFreeWaveInParking */
				offerOrderFromFreeWave: boolean;
				offerToAllParkingInSector: {
					active: boolean;
				};

				offerToParkingInNearestSector: ActiveValue; // шт.
			}

			interface ParkingGPS extends Record<string, any> {}

			interface ModesConfig {
				[OrderDistributionMode.GPS]: GPS;
				[OrderDistributionMode.GPS_PARKING]: GPSParking;
				[OrderDistributionMode.PARKING]: Parking;
				[OrderDistributionMode.PARKING_GPS]: ParkingGPS;
			}

			interface ManualAssignment extends Record<string, any> {
				orderOfferTime: number;
			}

			namespace AutoDistributionInterval {
				interface Sources {
					app: ActiveValue;
					phone: ActiveValue;
					website: ActiveValue;
					dispatcher: ActiveValue;
				}
			}
		}
	}

	interface New extends Omit<Model, "taxiServices"> {}

	type Modified = Partial<New> & { id: number };

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<OrderDistributionSettings.Model> {
		includeTaxiServices?: boolean;
	}
}

export default OrderDistributionSettings;
