import companies from "../../redux/reducers/company";
import ModelService from "../../redux/services/ModelService";
import { getPRPC } from "../../hooks/usePRPC";
import createRPCQuery from "../../utils/createRPCQuery.util";
import { createObjectLanguageNamesFromObject } from "../../assets/languages/langs";
import { Language } from "..";

class Company extends ModelService<
	Company.SubscribeOptions,
	Company.Model,
	"companies"
>(companies, (state) => state.company) {
	static fromResponse(data: any): Company.Model {
		return {
			id: data.id,
			name: createObjectLanguageNamesFromObject(data.name),
			// name: {
			// 	uk: data.name.uk,
			// 	tr: data.name.tr,
			// 	az: data.name.az,
			// 	en: data.name.en,
			// 	ru: data.name.ru,
			// },
			login: data.login,

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	static Global = {
		async index() {
			const prpc = getPRPC();

			if (!prpc) return null;

			const result = await createRPCQuery(() =>
				prpc.theirsModel.company.getAll(),
			);

			const cache = (result.items as any[])
				.map(Company.fromResponse)
				.sort((company1, company2) => company1.id - company2.id);

			return {
				cache,
				offset: 0,
				limit: cache.length,
				total: cache.length,
				deprecated: false,
			};
		},
	};
}

declare namespace Company {
	interface Model {
		id: number;

		name: Record<Language, string>;
		login: string;

		createdAt: Date;
		updatedAt: Date;
		deletedAt: Date;
	}

	interface SubscribeOptions {}
}

export default Company;
