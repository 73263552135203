import * as ModelEvent from "@node-elion/syncron";
import Color from "color";
import { compact, isBoolean, uniq } from "lodash";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import { createObjectLanguageNamesFromObject } from "../../assets/languages/langs";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import { SortingOrder } from "../../types/SortingOrder";
import { Base, TaxiService, Card, Service, Language } from "..";

const logger = createLogger({ name: "CarClass" });

class CarClass extends Base {
	// Its needed due to typescript bundler conflict
	private static _Card: Card | null = null;

	public static get Card() {
		if (this._Card) return this._Card;

		this._Card = new Card((prpc) => prpc.theirsModel.carClass.card);

		return this._Card;
	}

	static fromResponse(data: any): CarClass.Model {
		const {
			taxiServiceToCarClasses,
			carClassToAvailableServices,
			carClassToDefaultServices,
		} = data;

		const taxiServices =
			taxiServiceToCarClasses?.map((taxiServiceToCarClass) =>
				TaxiService.fromResponse(taxiServiceToCarClass?.taxiService),
			) || [];

		const compatibleCarClasses =
			data?.carClassToCompatibleCarClasses?.map((item) =>
				CarClass.fromResponse(item?.compatibleCarClass),
			) || [];

		const compatibleCarClassesToBroadcastable =
			data?.carClassToCompatibleCarClassesToBroadcastable?.map((item) =>
				CarClass.fromResponse(item?.compatibleCarClass),
			) || [];

		const companyIds =
			uniq<number>(taxiServices?.map(({ company }) => company?.id)) || [];

		const serviceAvailable =
			carClassToAvailableServices?.map((item) =>
				Service.fromResponse(item?.service),
			) || [];

		const serviceAvailableIds = Base.getIds(serviceAvailable);

		const serviceDefault =
			carClassToDefaultServices?.map((item) =>
				Service.fromResponse(item?.service),
			) || [];

		const serviceDefaultIds = Base.getIds(serviceDefault);

		const payload = {
			id: data.id,

			companyIds,
			taxiServices,
			taxiServiceIds: Base.getIds(taxiServices),
			compatibleCarClasses,
			compatibleCarClassIds: Base.getIds(compatibleCarClasses),
			compatibleCarClassesToBroadcastable,
			compatibleCarClassIdsToBroadcastable: Base.getIds(
				compatibleCarClassesToBroadcastable,
			),

			serviceAvailable,
			serviceAvailableIds,

			serviceDefault,
			serviceDefaultIds,

			name: createObjectLanguageNamesFromObject(data?.name),

			shortName: data?.shortName,
			position: data?.position,
			root: data?.root,
			active: data?.active,
			default: data?.default,

			useBackgroundColor: data?.useBackgroundColor,
			backgroundColor: new Color(data?.backgroundColor),

			useTextColor: data.useTextColor,
			textColor: new Color(data?.textColor),

			distributable: data?.distributable,
			broadcastable: data?.broadcastable,

			priority: data?.priority || 1,

			distributionCompatibleMode: data?.distributionCompatibleMode,
			broadcastingCompatibleMode: data?.broadcastingCompatibleMode,

			availableForOnlineOrdering: data?.availableForOnlineOrdering,

			updatedAt: data?.updatedAt,
			createdAt: data?.createdAt,
			deletedAt: data?.deletedAt,
		};

		return payload;
	}

	static toRequest(model: CarClass.Model.New | CarClass.Model.Modified): any {
		return {
			taxiServiceIds: model.taxiServiceIds,
			compatibleCarClassIds: model.compatibleCarClassIds,
			compatibleCarClassIdsToBroadcastable:
				model.compatibleCarClassIdsToBroadcastable,
			serviceAvailableIds: model.serviceAvailableIds,
			serviceDefaultIds: model.serviceDefaultIds,

			name: createObjectLanguageNamesFromObject(model.name),

			position: model.position,
			shortName: model.shortName,
			active: model.active,
			default: model.default,

			useBackgroundColor: model.useBackgroundColor,
			backgroundColor: model.backgroundColor?.rgbNumber(),

			useTextColor: model.useTextColor,
			textColor: model.textColor?.rgbNumber(),

			distributable: model.distributable,
			broadcastable: model.broadcastable,

			priority: model.priority,

			availableForOnlineOrdering: model.availableForOnlineOrdering,

			distributionCompatibleMode: model.distributionCompatibleMode,
			broadcastingCompatibleMode: model.broadcastingCompatibleMode,
		};
	}

	public static async store(object: CarClass.Model.New, force = false) {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.carClass.create(
						CarClass.toRequest(object),
						{ force },
					),
				{ silent: false, error: true },
			);

			logger.info("[CarClass] store", { object, res, force });

			return res;
		} catch (err: any) {
			return null;
		}
	}

	public static async update(object: CarClass.Model.Modified, force = false) {
		try {
			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.carClass.update(
						object.id,
						CarClass.toRequest(object),
						{ force },
					),
				{ silent: false, error: true },
			);

			logger.info("[CarClass] update", { object, res, force });

			return res;
		} catch (err: any) {
			return null;
		}
	}

	public static async updateActiveStatus(
		object: Pick<CarClass.Model.Modified, "id" | "active">,
	) {
		try {
			if (!object.id) return null;
			if (!isBoolean(object.active)) return null;

			const params = {
				active: object.active,
			};

			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.carClass.updateActiveStatus(
						object.id,
						params,
					),
				{ silent: false, error: true },
			);

			logger.info("[CarClass] updateActiveStatus", { params, res });

			return res;
		} catch (err: any) {
			return null;
		}
	}

	public static async updatePosition(
		object: Pick<CarClass.Model.Modified, "id" | "position" | "name">,
	) {
		try {
			if (!object.id) return null;
			if (!object.position) return null;

			const params = {
				name: createObjectLanguageNamesFromObject(object.name),
				position: object.position,
			};

			const res = await this.request(
				(prpc) =>
					prpc.theirsModel.carClass.updatePosition(object.id, params),
				{ silent: false, error: true },
			);

			logger.info("[CarClass] updatePosition", { params, res });

			return res;
		} catch (err: any) {
			return null;
		}
	}

	public static async linkToModelAndBodyType(
		modelId: number,
		bodyTypeId: number,
		ids: number | number[],
	) {
		this.request((prpc) =>
			prpc.theirsModel.carClass.linkToModelAndBodyType(
				modelId,
				bodyTypeId,
				ids,
			),
		);
	}

	public static async relinkToModelAndBodyType(
		modelId: number,
		bodyTypeId: number,
		ids: number | number[],
	) {
		this.request((prpc) =>
			prpc.theirsModel.carClass.relinkToModelAndBodyType(
				modelId,
				bodyTypeId,
				ids,
			),
		);
	}

	public static async unlinkToModelAndBodyType(
		modelId: number,
		bodyTypeId: number,
		ids: number | number[],
	) {
		this.request((prpc) =>
			prpc.theirsModel.carClass.unlinkToModelAndBodyType(
				modelId,
				bodyTypeId,
				ids,
			),
		);
	}

	public static async destroy(id: number[] | number, force = false) {
		if (Array.isArray(id))
			await Promise.all(id.map((id) => this.destroyOne(id, force)));
		else await this.destroyOne(id, force);
	}

	private static async destroyOne(id: number, force = false) {
		this.request((prpc) => prpc.theirsModel.carClass.delete(id, { force }));
	}

	public static async subscribe(
		options: CarClass.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<CarClass.Model>,
	): Promise<Subscription<CarClass.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				logger.info("[CarClass]", state);
				const items = compact(state?.models || []);

				onUpdate({
					...state,

					models: items.map(this.fromResponse),
				});
			},
		});
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.carClass.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						logger.error(error);
					},
				}),
			{ name: "CarClass.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: CarClass.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<CarClass.SubscribeOptions>;
	}

	private static optionsToRequest(options: CarClass.SubscribeOptions) {
		return {
			offset: options.offset,
			limit: options.limit,

			taxiServiceIds: options.taxiServiceIds,
			carBodyTypeIds: options.carBodyTypeIds,
			carModelIds: options.carModelIds,

			query: options.query,
			active: options.active,
			// lang: options.language,
		};
	}
}

namespace CarClass {
	export enum CompatibleMode {
		/**
		 * Car class is compatible only with itself
		 */
		WITH_ITSELF = "with_itself",

		/**
		 * Car class compatibility depends on the compatibility table
		 */
		AUTO = "auto",

		/**
		 * Car class is compatible with all car classes
		 */
		WITH_ALL = "with_all",
	}

	export interface Model {
		id: number;

		companyIds?: number[];
		taxiServices?: TaxiService.Model[];
		taxiServiceIds?: number[];
		compatibleCarClasses?: CarClass.Model[];
		compatibleCarClassIds?: number[];
		compatibleCarClassesToBroadcastable?: CarClass.Model[];
		compatibleCarClassIdsToBroadcastable?: number[];

		serviceAvailable: Service.Model[];
		serviceAvailableIds: number[];

		serviceDefault: Service.Model[];
		serviceDefaultIds: number[];

		name: Record<Language, string>;

		shortName: string;
		position?: Date | number;
		root?: boolean;
		active: boolean;
		default: boolean;

		useBackgroundColor: boolean;
		backgroundColor: Color;

		useTextColor: boolean;
		textColor: Color;

		priority: number;

		/**
		 * If the value is true, then the order with this class is available for distribution.
		 */
		distributable: boolean;

		/**
		 * If the value is true, then the order with this class is available for broadcasting (executors an take orders themselves at will).
		 */
		broadcastable: boolean;

		/**
		 * If the value is true, then class can be used in online ordering.
		 */
		availableForOnlineOrdering: boolean;

		distributionCompatibleMode: CompatibleMode;
		broadcastingCompatibleMode: CompatibleMode;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	export interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<CarClass.Model> {
		taxiServiceIds?: number[];
		carBodyTypeIds?: number[];
		carModelIds?: number[];
		withDeleted?: boolean;
		language?: Language;
		default?: boolean;
		active?: boolean;
		order?: Record<
			| "id"
			| "name"
			| "company"
			| "taxiService"
			| "active"
			| "default"
			| "position",
			SortingOrder
		>;
	}

	export namespace Model {
		export type NonEditablePropertyName =
			| "id"
			| "createdAt"
			| "updatedAt"
			| "deletedAt";

		export type ModifiedPropertyName = "taxiServices";

		export interface ModifiedProperties {
			taxiServiceIds: number[];
		}

		export interface New {
			companyIds?: number[];
			taxiServices?: TaxiService.Model[];
			taxiServiceIds?: number[];
			compatibleCarClassIds?: number[];
			compatibleCarClassIdsToBroadcastable?: number[];
			serviceAvailableIds: number[];
			serviceDefaultIds: number[];

			name: Record<Language, string>;

			shortName?: string;
			position?: Date | number;
			root?: boolean;
			active: boolean;
			default?: boolean;

			useBackgroundColor?: boolean;
			backgroundColor?: Color;

			useTextColor?: boolean;
			textColor?: Color;

			priority: number;

			distributable?: boolean;
			broadcastable?: boolean;

			availableForOnlineOrdering?: boolean;

			distributionCompatibleMode?: CompatibleMode;
			broadcastingCompatibleMode?: CompatibleMode;
		}

		export type Modified = Partial<New> & { id: number };
	}
}

export default CarClass;
