import React from "react";

import ExecutorCallSignCellContent from "../components/ExecutorCallSignCellContent";

import base from "./base";

import { Column, ColumnId } from ".";

function executorCallSign({ width, onResize, language }: Column.Options) {
	return base(
		ColumnId.ExecutorCallSign,
		(item) => (
			<ExecutorCallSignCellContent item={item} language={language} />
		),
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default executorCallSign;
