/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import * as ModelEvent from "@node-elion/syncron";
import { isString } from "lodash";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import { createObjectLanguageNames } from "../../assets/languages/langs";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import { SortingOrder } from "../../types/SortingOrder";
import {
	CarModel,
	CarBodyType,
	Language,
	TaxiServiceOld as TaxiService,
	Card,
	Base,
} from "..";

const logger = createLogger({ name: "CarBaseType" });

class CarBaseType extends Base {
	// Its needed due to typescript bundler conflict
	private static _Card: Card | null = null;

	public static get Card() {
		if (this._Card) return this._Card;

		this._Card = new Card((prpc) => prpc.theirsModel.carBaseType.card);

		return this._Card;
	}

	static fromResponse(data: any): CarBaseType.Model {
		return {
			id: data.id,

			taxiServices: data.baseTypeToTaxiServices?.map(
				(carBaseTypeToTaxiService) =>
					TaxiService.fromResponse(
						carBaseTypeToTaxiService.taxiService,
					),
			),
			carBodyTypes: data.baseTypeToBodyTypes?.map(
				(carBaseTypeToCarBodyType) =>
					CarBodyType.fromResponse(carBaseTypeToCarBodyType.bodyType),
			),
			carModels: data.models?.map((carModel) =>
				CarModel.fromResponse(carModel),
			),

			name: data.name.uk,
			position: data.position,

			native: data.native,
			active: data.active,
			default: data.default,

			additionalFields: data.additionalFields,

			updatedAt: data.updatedAt,
			createdAt: data.createdAt,
			deletedAt: data.deletedAt,
		};
	}

	static toRequest(
		model: CarBaseType.Model.New | CarBaseType.Model.Modified,
	): any {
		const result: any = {
			taxiServiceIds: model.taxiServiceIds,
			carBodyTypeIds: model.carBodyTypeIds,

			position: model.position,

			active: model.active,
			default: model.default,

			additionalFields: model.additionalFields,
		};

		if (isString(model.name)) {
			result.name = createObjectLanguageNames(model.name);

			// result.name = {
			// 	uk: model.name,
			// 	az: model.name,
			// 	tr: model.name,
			// 	en: model.name,
			// 	ru: model.name,
			// };
		}

		return result;
	}

	public static async store(object: CarBaseType.Model.New) {
		this.request((prpc) =>
			prpc.theirsModel.carBaseType.create(CarBaseType.toRequest(object)),
		);
	}

	public static async update(object: CarBaseType.Model.Modified) {
		this.request((prpc) =>
			prpc.theirsModel.carBaseType.update(
				object.id,
				CarBaseType.toRequest(object),
			),
		);
	}

	public static async destroy(id: number[] | number) {
		if (Array.isArray(id))
			await Promise.all(id.map((id) => this.destroyOne(id)));
		else await this.destroyOne(id);
	}

	public static async subscribe(
		options: CarBaseType.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<CarBaseType.Model>,
	): Promise<Subscription<CarBaseType.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.carBaseType.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						logger.error(error);
					},
				}),
			{ name: "CarBaseType.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: CarBaseType.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<CarBaseType.SubscribeOptions>;
	}

	private static async destroyOne(id: number) {
		this.request((prpc) => prpc.theirsModel.carBaseType.delete(id));
	}

	private static optionsToRequest(options: CarBaseType.SubscribeOptions) {
		return {
			query: options.query,
			offset: options.offset,
			limit: options.limit,

			ids: options.ids,
			taxiServiceIds: options.taxiServiceIds,

			active: options.active,
			native: options.native,
			default: options.default,
			lang: options.language,
		};
	}
}

declare namespace CarBaseType {
	export interface Model {
		id: number;

		taxiServices?: TaxiService.Model;
		carBodyTypes?: CarBodyType.Model;
		carModels?: CarModel.Model;

		name: string;
		position: unknown;

		native: boolean;
		active: boolean;
		default: boolean;

		additionalFields: Record<string, unknown> | null;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<CarBaseType.Model> {
		ids?: number[];
		taxiServiceIds?: number[];

		language?: Language;
		native?: boolean;
		active?: boolean;
		default?: boolean;
		order?: Partial<
			Record<
				| "id"
				| "name"
				| "company"
				| "taxiService"
				| "active"
				| "native"
				| "position"
				| "carBodyType",
				SortingOrder
			>
		>;
	}

	namespace Model {
		export interface New {
			taxiServiceIds?: number[];
			carBodyTypeIds?: number[];

			name: string;
			position?: Date | number;

			active: boolean;
			default?: boolean;

			additionalFields: Record<string, unknown> | null;
		}

		type Modified = Partial<New> & Pick<Model, "id">;
	}
}

export default CarBaseType;
