import React, { Suspense, useMemo, lazy } from "react";
import { useTranslation } from "react-i18next";

import { useTypedSelector } from "../../../../redux/store";
import useActiveTab from "../../../../hooks/useActiveTab";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import {
	StyledRow,
	SuspenseLoader,
	SideTab,
} from "../../../../components/common";

import { TabKeys, TabAccessNames } from "./constants/access";

const LazyMainTariffs = lazy<React.FC<TariffsSettingsPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Main" /* webpackChunkName: "setting-tariffs-page-main" */
	);
	return { default: elem.default };
});

const LazyAutoTariffs = lazy<React.FC<TariffsSettingsPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Auto" /* webpackChunkName: "setting-tariffs-page-auto" */
	);
	return { default: elem.default };
});

const LazyGeneralTariffs = lazy<React.FC<TariffsSettingsPage.Props>>(
	async () => {
		const elem = await import(
			"./tabs/General" /* webpackChunkName: "setting-tariffs-page-general" */
		);
		return { default: elem.default };
	},
);

const LazyAdditionalTariffs = lazy<React.FC<TariffsSettingsPage.Props>>(
	async () => {
		const elem = await import(
			"./tabs/Additional" /* webpackChunkName: "setting-tariffs-page-additional" */
		);
		return { default: elem.default };
	},
);

const TariffsSettingsPage: React.FC = () => {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: TabKeys.MAIN_TARIFFS,
					label: t("settings.tariffs.main.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyMainTariffs />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.MAIN_TARIFFS],
				},
				{
					key: TabKeys.AUTOMATIC_TARIFFS,
					label: t("settings.tariffs.auto.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyAutoTariffs />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.AUTOMATIC_TARIFFS],
				},
				{
					key: TabKeys.GENERAL_TARIFFS,
					label: t("settings.tariffs.general.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyGeneralTariffs />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.GENERAL_TARIFFS],
				},
				{
					key: TabKeys.ADDITIONAL,
					label: t("settings.tariffs.additional.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyAdditionalTariffs />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.ADDITIONAL],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<SideTab
					value={activeKey}
					onChange={setActiveKey}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

declare namespace TariffsSettingsPage {
	interface Props {}
}

export default TariffsSettingsPage;
