import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button, Icon, InputGroup, MultiSelect, theme } from "uikit";

import { useTypedSelector } from "../../../../../../../../redux/store";
import SearchIconBorders from "../../../../../../../../components/SearchIconBorders";
import SearchTextBox from "../../../../../../../../components/SearchTextBox";
import {
	StyledRow,
	LanguageSelect,
} from "../../../../../../../../components/common";
import DefaultPageHeader from "../../../../../../../../components/DefaultPageHeader";
import {
	TabKeys,
	ARR_ACCESS_PART_KEY,
} from "../../../../../../constants/access";
import Filters from "../Filters";

const StyledInputGroup = styled(InputGroup.InputGroup)`
	width: 280px !important;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomSelect = ({ title, value, onSelect, onUnselect, options }) => (
	<MultiSelect
		style={{ width: "200px" }}
		value={value}
		options={options}
		onUnselect={(option) => onUnselect(option?.value)}
		onSelect={(option) => {
			onSelect(option?.value);
		}}
	/>
);

export default function Header({
	filter,
	setFilter,
	setLanguageFilter,
	languageFilter,
	query,
	onChangeQuery,
	setSelected,
	selected,
	selectedIndex,
	models,

	canEdit,
	canDelete,
	onAdd,
	onEdit,
	onDelete,
}) {
	const { t } = useTranslation();
	const companies = useTypedSelector(
		(state) => state.ordersPageReducer.companies,
	);
	const lang = useTypedSelector((state) => state.session.language);
	const companyWrap = useCallback(
		(company) => ({
			key: company?.id,
			value: company?.id,
			label: company?.name?.[lang],
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[companies, lang],
	);

	const companiesOptions = useMemo(
		() => companies.items.map((company) => companyWrap(company)),
		[companyWrap, companies],
	);
	const taxiServiceWrap = useCallback(
		(service) => ({
			key: service?.id,
			value: service?.id,
			label: service?.settlement?.[lang],
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[companies],
	);
	const taxiServiceOptions = useMemo(() => {
		const getData = (data) =>
			data
				.map((item) =>
					companies.items
						.find((company) => item === company.id)
						?.taxiServices.map((service) =>
							taxiServiceWrap(service),
						),
				)
				.flat();

		return filter.companyIds[0] === -1
			? getData(companiesOptions.map((item) => item.value))
			: getData(filter.companyIds);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter.companyIds, companies]);

	const taxiServiceIds = filter.taxiServices;

	useEffect(() => {
		setFilter({ ...filter, taxiServices: taxiServiceIds });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taxiServiceIds]);

	useEffect(() => {
		setFilter({
			...filter,
			allCompanies: companiesOptions.map((item) => item.value),
			allTaxiServices: taxiServiceOptions.map((item) => item.value),
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [companies, filter.companyIds]);

	return (
		<StyledRow
			p="0 10px 0 0"
			gap="5px"
			justify="space-between"
			alignItems="center"
		>
			<StyledRow gap="10px">
				<DefaultPageHeader
					canEdit={canEdit}
					canDelete={canDelete}
					onAdd={onAdd}
					onEdit={onEdit}
					onDelete={onDelete}
					accessName={ARR_ACCESS_PART_KEY[TabKeys.CAR_CLASSES]}
				/>

				<Filters
					models={models}
					selectedId={selected.length === 1 && selected[0]}
					selectedIndex={selectedIndex}
					setSelected={setSelected}
				/>
			</StyledRow>
			<StyledRow gap="10px">
				<StyledRow gap="10px" justify="space-between">
					<CustomSelect
						title={
							t(
								"pages.preferencesPages.screenDirectory.carClass.header.str0",
							) ?? ""
						}
						options={[
							{
								key: -1,
								value: -1,
								label:
									t(
										"pages.preferencesPages.screenDirectory.carClass.header.str200",
									) ?? "",
							},
							...companiesOptions,
						]}
						value={
							filter.companyIds.length ? filter.companyIds : [-1]
						}
						onUnselect={(value) => {
							const filteredData = filter.companyIds.filter(
								(v) => v !== value,
							);
							setFilter({
								...filter,
								companyIds: filteredData.length
									? filteredData
									: [-1],
							});
						}}
						onSelect={(value) => {
							if (value === -1) {
								setFilter({
									...filter,
									companyIds: [-1],
								});
							} else {
								setFilter({
									...filter,
									companyIds: [
										...filter.companyIds,
										value,
									].filter((item) => item !== -1),
								});
							}
						}}
					/>
					<CustomSelect
						title={
							t(
								"pages.preferencesPages.screenDirectory.carClass.header.str1",
							) ?? ""
						}
						options={[
							{
								key: -1,
								value: -1,
								label:
									t(
										"pages.preferencesPages.screenDirectory.carClass.header.str200",
									) ?? "",
							},
							...taxiServiceOptions,
						]}
						value={taxiServiceIds.length ? taxiServiceIds : [-1]}
						onUnselect={(value) => {
							const filteredData = taxiServiceIds.filter(
								(v) => v !== value,
							);
							setFilter({
								...filter,
								taxiServices: filteredData.length
									? filteredData
									: [-1],
							});
						}}
						onSelect={(value) => {
							if (value === -1) {
								setFilter({
									...filter,
									taxiServices: [-1],
								});
							} else {
								setFilter({
									...filter,
									taxiServices: [
										...taxiServiceIds,
										value,
									].filter((item) => item !== -1),
								});
							}
						}}
					/>
				</StyledRow>
				<LanguageSelect
					onSelect={(value) => setLanguageFilter(value.key)}
					value={languageFilter}
				/>

				<StyledInputGroup sizes="auto 280px">
					<SearchIconBorders>
						<Icon
							id="search"
							size={16}
							colors={[theme.colors.disabled_text]}
						/>
					</SearchIconBorders>
					<SearchTextBox
						value={query}
						onChange={(value) => {
							onChangeQuery(value);
						}}
						placeholder={`${
							t(
								"pages.preferencesPages.screenDirectory.carClass.header.str204",
							) ?? ""
						}...`}
					/>
				</StyledInputGroup>
				<Button.Button
					variant="secondary"
					icon={<Icon id="filters" size={20} />}
					onClick={() => {}}
				/>
			</StyledRow>
		</StyledRow>
	);
}
