/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-shadow */

import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useInternal, useRefWithSetter } from "uikit";
import { clone, isEqual } from "lodash";
import { LatLngTuple } from "leaflet";

import Service from "../../../../../../services/PriceZone";
import { useTypedSelector } from "../../../../../../redux/store";
import useModelSubscribe from "../../../../../../hooks/useModelSubscribe";
import DeleteModal from "../../../../../../components/DeleteModal";
import useNavigationPrompt from "../../../../../../hooks/useNavigationPrompt";
import useLanguage from "../../../../../../hooks/useLanguage";
import {
	createObjectLanguageNames,
	createObjectLanguageNamesFromObject,
	ValueLanguage,
} from "../../../../../../assets/languages/langs";

import useSubscribeOptions from "./hooks/useSubscribeOptions";
import Root from "./components/Root";
import Header from "./components/Header";
import Content from "./components/Content";
import EditModal from "./components/EditModal";

type PartialProperties = "vertices";
type A = Omit<Service.Model.New, PartialProperties> &
	Partial<Pick<Service.Model.New, PartialProperties>> &
	Pick<Service.Model, "id" | "bySector">;

const PriceZones: React.FC<PriceZones.Props> = () => {
	const settingsLanguage = useTypedSelector(
		(state) => state.session.language,
	);

	const [editModalRef, editModalRefSetter] =
		useRefWithSetter<EditModal.Controller | null>(null);

	const [saving, setSaving] = useState(false);

	const [subscribeOptions, filters, setFilters] = useSubscribeOptions();

	const fullModelData = useModelSubscribe({}, Service);

	const fullModelItems = useMemo(
		() => fullModelData?.cache ?? [],
		[fullModelData?.cache],
	);

	const filteredModelData = useModelSubscribe(subscribeOptions, Service);

	const filteredModelItems = useMemo(
		() => filteredModelData?.cache ?? [],
		[filteredModelData?.cache],
	);

	const modelItemIds = useMemo(
		() => filteredModelItems.map((item) => item.id),
		[filteredModelItems],
	);

	const loading = useMemo(
		() =>
			fullModelData?.deprecated ||
			filteredModelData?.deprecated ||
			saving,
		[fullModelData?.deprecated, filteredModelData?.deprecated, saving],
	);

	const [selectedItems, setSelectedItems] = useState<Record<number, boolean>>(
		{},
	);

	const [creatingItems, setCreatingItems] = useState<Record<number, boolean>>(
		{},
	);

	const baseModelItems = useMemo(
		() =>
			fullModelItems.map<A>((item) => ({
				id: item.id,
				taxiServiceId: item.taxiServiceId,

				position: item.position,
				name: item.name,
				vertices: item.vertices,

				active: item.active,
				bySector: item.bySector,
				suburban: item.suburban,
			})),
		[fullModelItems],
	);

	const baseModelItemById = useMemo(
		() =>
			baseModelItems.reduce((accumulator, item) => {
				accumulator[item.id] = item;

				return accumulator;
			}, {} as Record<number, Service.Model.Modified>),
		[baseModelItems],
	);

	const baseModelItemIds = useMemo(
		() => baseModelItems.map((item) => item.id),
		[baseModelItems],
	);

	const [editableModelItems, setEditableModelItems] =
		useInternal<A[]>(baseModelItems);

	const editableModelItemById = useMemo(
		() =>
			editableModelItems.reduce((accumulator, item) => {
				accumulator[item.id] = item;

				return accumulator;
			}, {} as Record<number, A>),
		[editableModelItems],
	);

	const editableModelItemIds = useMemo(
		() => editableModelItems.map((item) => item.id),
		[editableModelItems],
	);

	const newModelItemIds = useMemo<number[]>(
		() =>
			editableModelItemIds.filter((id) => !baseModelItemIds.includes(id)),
		[baseModelItemIds, editableModelItemIds],
	);

	const editedModelItemIds = useMemo<number[]>(
		() =>
			baseModelItemIds.filter(
				(id) =>
					editableModelItemIds.includes(id) &&
					!isEqual(baseModelItemById[id], editableModelItemById[id]),
			),
		[
			baseModelItemById,
			baseModelItemIds,
			editableModelItemById,
			editableModelItemIds,
		],
	);

	const editedPolygonModelItemIds = useMemo<number[]>(
		() =>
			baseModelItemIds.filter(
				(id) =>
					editableModelItemIds.includes(id) &&
					!isEqual(
						baseModelItemById[id].vertices,
						editableModelItemById[id].vertices,
					),
			),
		[
			baseModelItemById,
			baseModelItemIds,
			editableModelItemById,
			editableModelItemIds,
		],
	);

	const deletedModelItemIds = useMemo<number[]>(
		() =>
			baseModelItemIds.filter((id) => !editableModelItemIds.includes(id)),
		[baseModelItemIds, editableModelItemIds],
	);

	const invalidModelItemIds = useMemo(
		() =>
			editableModelItemIds.filter((id) => {
				const item = editableModelItemById[id];

				return !item.vertices || item.vertices.length < 3;
			}),
		[editableModelItemIds, editableModelItemById],
	);

	const hasChanges = useMemo(
		() =>
			newModelItemIds.length !== 0 ||
			editedModelItemIds.length !== 0 ||
			deletedModelItemIds.length !== 0,
		[
			deletedModelItemIds.length,
			editedModelItemIds.length,
			newModelItemIds.length,
		],
	);

	const areModelItemsValid = useMemo(
		() => invalidModelItemIds.length === 0,
		[invalidModelItemIds.length],
	);

	const currentContentValue = useMemo<Content.Value>(
		() =>
			editableModelItems.map((item) => ({
				id: item.id,

				taxiServiceId: item.taxiServiceId,

				position: item.position,
				name: item.name,
				vertices: item.vertices?.map(
					(vertex) => [vertex.lat, vertex.lng] as LatLngTuple,
				),

				active: item.active,
				suburban: item.suburban,
				bySector: item.bySector,

				selected: selectedItems[item.id] ?? false,
				creating: creatingItems[item.id] ?? false,
				modified:
					newModelItemIds.includes(item.id) ||
					editedPolygonModelItemIds.includes(item.id),
				valid: !invalidModelItemIds.includes(item.id),
				disabled:
					!modelItemIds.includes(item.id) &&
					!newModelItemIds.includes(item.id),
			})),
		[
			editableModelItems,
			selectedItems,
			creatingItems,
			newModelItemIds,
			editedPolygonModelItemIds,
			invalidModelItemIds,
			modelItemIds,
		],
	);

	const validContentValueRef = useRef(currentContentValue);

	if (!filteredModelData?.deprecated) {
		validContentValueRef.current = currentContentValue;
	}

	const contentValue = validContentValueRef.current;

	const contentCanCancel = useMemo(
		() => hasChanges && !loading,
		[hasChanges, loading],
	);

	const contentCanSave = useMemo(
		() => areModelItemsValid && hasChanges && !loading,
		[hasChanges, areModelItemsValid, loading],
	);

	const contentSelectedIds = useMemo(
		() =>
			contentValue.filter((item) => item.selected).map((item) => item.id),
		[contentValue],
	);

	const canEdit = useMemo(
		() => contentValue.filter((item) => item.selected).length === 1,
		[contentValue],
	);

	const canDelete = useMemo(
		() => contentValue.some((item) => item.selected),
		[contentValue],
	);

	const edit = useCallback((id: number) => {
		setSelectedItems({ [id]: true });
	}, []);

	const cancel = useCallback(() => {
		setSelectedItems({});
		setEditableModelItems(baseModelItems);
	}, [baseModelItems, setEditableModelItems]);

	const headerOnAdd = useCallback(() => {
		setEditingModelItem({
			taxiServiceId:
				filters.taxiServiceIds?.length === 1
					? filters.taxiServiceIds[0]
					: undefined,

			active: true,
		});
	}, [filters.taxiServiceIds]);

	const headerOnEdit = useCallback(() => {
		const selectedId = Object.entries(selectedItems).filter(
			([, selected]) => selected,
		)[0][0];

		const modelItem = editableModelItems.find(
			(item) => String(item.id) === selectedId,
		)!;

		setEditingModelItem({
			id: modelItem.id,
			name: modelItem.name,
			active: modelItem.active,
			suburban: modelItem.suburban,
			taxiServiceId: modelItem.taxiServiceId,
		});
	}, [editableModelItems, selectedItems]);

	const headerOnDelete = useCallback(() => {
		setShowDeleteModal(true);
	}, []);

	const contentOnChange = useCallback(
		(value: Content.Value) => {
			setSelectedItems(
				value.reduce((accumulator, item) => {
					accumulator[item.id] = item.selected;

					return accumulator;
				}, {} as Record<number, boolean>),
			);

			setCreatingItems(
				value.reduce((accumulator, item) => {
					accumulator[item.id] = item.creating;

					return accumulator;
				}, {} as Record<number, boolean>),
			);

			setEditableModelItems(
				value.map((item) => {
					const itemBase = editableModelItems.find(
						(itemBase) => itemBase.id === item.id,
					)!;

					const newItem = clone(itemBase);

					newItem.id = item.id;

					newItem.position = item.position;
					newItem.name = item.name;
					newItem.vertices = item.vertices?.map((vertex) => ({
						lat: vertex[0],
						lng: vertex[1],
					}));

					newItem.active = item.active;

					return newItem;
				}),
			);
		},
		[editableModelItems, setEditableModelItems],
	);

	const contentOnCancel = useCallback(() => {
		cancel();
	}, [cancel]);

	const contentOnSave = useCallback(async () => {
		setSaving(true);

		const options = { deprecate: false };

		try {
			await Promise.all([
				...newModelItemIds.map((id) =>
					Service.store(
						editableModelItemById[id] as Service.Model.New,
						options,
					),
				),
				...editedModelItemIds.map((id) =>
					Service.update(
						editableModelItemById[id] as Service.Model.Modified,
						options,
					),
				),
				Service.destroy(deletedModelItemIds, options),
			]);
		} finally {
			Service.deprecateAll();

			setSaving(false);
		}
	}, [
		deletedModelItemIds,
		editableModelItemById,
		editedModelItemIds,
		newModelItemIds,
	]);

	const [editingModelItem, setEditingModelItem] =
		useState<EditModal.Value | null>(null);

	const editModalOnCancel = useCallback(() => setEditingModelItem(null), []);

	const editModalOnSave = useCallback(() => {
		if (!editModalRef.current?.validate()) return;

		const id = editingModelItem!.id ?? Date.now();

		const modelItemBaseIndex = editableModelItems.findIndex(
			(item) => item.id === id,
		);

		const modelItemBase = editableModelItems[modelItemBaseIndex];

		if (modelItemBaseIndex === -1) {
			const editingModelItemName = (editingModelItem!.name ||
				createObjectLanguageNames("")) as Record<ValueLanguage, string>;

			setEditableModelItems([
				{
					id,

					position: new Date(),
					taxiServiceId: editingModelItem!.taxiServiceId!,
					name: createObjectLanguageNamesFromObject(
						editingModelItemName,
					),
					// name: {
					// 	uk: editingModelItem!.name!.uk!,
					// 	en: editingModelItem!.name!.en!,
					// 	az: editingModelItem!.name!.az!,
					// 	tr: editingModelItem!.name!.tr!,
					// 	ru: editingModelItem!.name!.ru!,
					// },

					bySector: false,
					active: editingModelItem!.active,
					suburban: editingModelItem!.suburban,
				},
				...editableModelItems,
			]);
		} else {
			const newEditableModelItems = clone(editableModelItems);
			const newModelItem = clone(modelItemBase);
			const editingModelItemName = (editingModelItem!.name ||
				createObjectLanguageNames("")) as Record<ValueLanguage, string>;

			newModelItem.taxiServiceId = editingModelItem!.taxiServiceId!;
			newModelItem.name =
				createObjectLanguageNamesFromObject(editingModelItemName);
			// newModelItem.name = {
			// 	uk: editingModelItem!.name!.uk!,
			// 	en: editingModelItem!.name!.en!,
			// 	az: editingModelItem!.name!.az!,
			// 	tr: editingModelItem!.name!.tr!,
			// 	ru: editingModelItem!.name!.ru!,
			// };
			newModelItem.suburban = editingModelItem!.suburban;
			newModelItem.active = editingModelItem!.active;

			newEditableModelItems[modelItemBaseIndex] = newModelItem;
			setEditableModelItems(newEditableModelItems);
		}

		setEditingModelItem(null);
		edit(id);
	}, [
		edit,
		editModalRef,
		editableModelItems,
		editingModelItem,
		setEditableModelItems,
	]);

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const deleteModalOnCancel = useCallback(() => {
		setShowDeleteModal(false);
	}, []);

	const deleteModalOnSave = useCallback(() => {
		setEditableModelItems(
			editableModelItems.filter(
				(item) => !contentSelectedIds.includes(item.id),
			),
		);

		setShowDeleteModal(false);
	}, [editableModelItems, contentSelectedIds, setEditableModelItems]);

	const [
		showHasChangesPrompt,
		confirmHasChangesPromptNavigation,
		cancelHasChangesPromptNavigation,
	] = useNavigationPrompt(hasChanges);

	useEffect(() => {
		Service.deprecateAll();
	}, []);

	const { t } = useTranslation();
	return (
		<Root sizes="auto 1fr" gaps="16px" maxedWidth maxedHeight>
			<Header
				filters={filters}
				canEdit={canEdit}
				canDelete={canDelete}
				onChangeFilters={setFilters}
				onAdd={headerOnAdd}
				onEdit={headerOnEdit}
				onDelete={headerOnDelete}
			/>
			<Content
				value={contentValue}
				language={filters.language}
				canCancel={contentCanCancel}
				canSave={contentCanSave}
				onChange={contentOnChange}
				onSave={contentOnSave}
				onCancel={contentOnCancel}
			/>
			{editingModelItem && (
				<EditModal
					ref={editModalRefSetter}
					value={editingModelItem}
					language={settingsLanguage}
					onChange={setEditingModelItem}
					onCancel={editModalOnCancel}
					onSave={editModalOnSave}
				/>
			)}
			{showDeleteModal && (
				<DeleteModal
					label={
						t(
							"pages.preferencesPages.screenDirectory.priceZones.str200",
						) ?? ""
					}
					onCancel={deleteModalOnCancel}
					onConfirm={deleteModalOnSave}
				/>
			)}
			{showHasChangesPrompt && (
				<DeleteModal
					label={
						t(
							"pages.preferencesPages.screenDirectory.priceZones.str201",
						) ?? ""
					}
					onCancel={cancelHasChangesPromptNavigation}
					onConfirm={confirmHasChangesPromptNavigation}
				/>
			)}
		</Root>
	);
};

declare namespace PriceZones {
	interface Props {}
}

export default PriceZones;
