import React, { useMemo, memo, useLayoutEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import Language from "../../../services/Language";
import { useTypedSelector } from "../../../redux/store";
import { StyledP, StyledRow, Style } from "../styles";

const TaxiServicesByIdsBase: React.FC<TaxiServicesByIdsBase.Props> = ({
	taxiServiceIds = [],
	lang,
	onSize,
	wrap,
	addNameCompany = false,
	...props
}) => {
	const { t } = useTranslation();
	const { models } = useTypedSelector((state) => state.taxiServices);

	const ref = useRef<HTMLDivElement | null>(null);

	useLayoutEffect(() => {
		const height = ref?.current?.offsetHeight;
		if (height !== undefined) onSize?.(height);
	}, [onSize]);

	const items = useMemo(() => models || [], [models]);

	const data = useMemo(() => {
		if (!items.length) return [];

		const lengthArrCompany = addNameCompany
			? items
					?.filter((service) => taxiServiceIds.includes(service?.id))
					?.map(
						(taxiServiceId) =>
							taxiServiceId?.company?.name?.[lang] || "",
					)
					?.filter(
						(taxiServiceName, index, self) =>
							taxiServiceName &&
							self.indexOf(taxiServiceName) === index,
					)?.length || 0
			: 0;

		const retval =
			items
				?.filter((service) => taxiServiceIds.includes(service?.id))
				?.map((service) => {
					const isNeedNmeCompany = lengthArrCompany > 1;
					const nameCompany = service?.company?.name?.[lang];
					const nameFil = service?.settlement?.[lang];

					const _nameCompany =
						isNeedNmeCompany && nameCompany
							? ` (${nameCompany})`
							: "";

					const nameAll = nameFil ? `${nameFil}${_nameCompany}` : "";
					const nameOneFil = nameFil ?? "";

					return addNameCompany ? nameAll : nameOneFil;
				}) || [];

		return retval;
	}, [items, taxiServiceIds, lang, addNameCompany]);

	if (!data.length) return <StyledRow></StyledRow>;
	if (data.length === items.length) {
		return (
			<StyledRow gap="0 8px">
				<StyledP {...props}>
					{t(["all_taxi_service", "All branches"])}
				</StyledP>
			</StyledRow>
		);
	}
	return (
		<StyledRow
			ref={ref}
			gap="0 8px"
			flex={onSize || wrap ? { wrap: "wrap" } : undefined}
			w={onSize ? { min: "200px" } : ""}
		>
			{data.length === 1 && <StyledP {...props}>{data[0]}</StyledP>}

			{data.length > 1 &&
				data.map((item, i, arr) => (
					<StyledP key={item} {...props}>{`${item}${
						arr?.[i + 1] ? "," : ""
					}`}</StyledP>
				))}
		</StyledRow>
	);
};

export const TaxiServicesByIds = memo(TaxiServicesByIdsBase);
declare namespace TaxiServicesByIdsBase {
	interface Props extends Style.TextType {
		taxiServiceIds: number[];
		lang: Language;
		onSize?: (value: number) => void;
		wrap?: boolean;
		addNameCompany?: boolean;
	}
}

export default TaxiServicesByIdsBase;
