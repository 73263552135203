import Map from "../../redux/services/Map";

interface LanguageOption {
	key: Map.Language;
	label: string;
	value: Map.Language;
}

export const shortenedSelectOptions: LanguageOption[] = [
	{
		key: "uk",
		label: "УК",
		value: "uk",
	},
	{
		key: "en",
		label: "EN",
		value: "en",
	},
	{
		key: "tr",
		label: "TR",
		value: "tr",
	},
	{
		key: "az",
		label: "AZ",
		value: "az",
	},
	{
		key: "ru",
		label: "РУ",
		value: "ru",
	},
	{
		key: "ro",
		label: "RO",
		value: "ro",
	},
];

export default shortenedSelectOptions;
