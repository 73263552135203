import React, {
	ReactElement,
	useCallback,
	useEffect,
	useState,
	useMemo,
	memo,
} from "react";
import { useTranslation } from "react-i18next";
import { CheckBox } from "uikit";
import { SortType } from "rsuite-table";
import { Popover, Whisper } from "rsuite";
import moment from "moment";

import Executor from "../../../../../services/Executor";
import Language from "../../../../../services/Language";
import ExecutorLocations from "../../../../../services/ExecutorLocations";
import { useTypedSelector } from "../../../../../redux/store";
import useExecutorStatus from "../../../../../hooks/useExecutorStatus";
import CompactTable from "../../../../CompactTable";
import Circle from "../../../../Circle";
import { getExecutorStatuses } from "../../constants";
import { useModelSubscribe } from "../../../../../hooks";
import { formatNumber } from "../../../../../utils";
import { StyledRow } from "../../../../common";

import Row from "./components/Row";
import AliasRow from "./components/AliasRow";

interface Props {
	data: Executor.Model[];
	sort: Sort | undefined;
	onSort: (column, type) => void;
	tableRowClassName: (rowData) => string;
	onRowDoubleClick: (rowData) => void;
	onRowSelect: (rowData) => void;
	// columnWidths: {
	// 	company: number;
	// 	taxiService: number;
	// 	status: number;
	// 	isWorking: number;
	// };
	columnIds: string[];
	// eslint-disable-next-line react/require-default-props
	handleColumnResize?: (newWidth: number, status: string) => number;
	language: Language;
}

interface ColumnContext {
	language: Language;
}

interface Sort {
	column?: string;
	type?: SortType;
}

const getPersonPhones = (data) => {
	const phones =
		data?.person?.phones.map((phone) => phone.number).join(", ") || "";
	return phones;
};

const getCar = (data, lang) => {
	const carShiftJournals = data?.executorShiftJournals?.length
		? data?.executorShiftJournals?.[0]?.car
		: null;

	const executorToCrews = data?.executorToCrews || [];

	const crew = carShiftJournals
		? executorToCrews.find(
				({ crew }) => crew?.car?.id === carShiftJournals?.id,
		  )?.crew
		: data?.executorToCrews?.[0]?.crew;

	const car = crew ? crew?.car : data?.executorToCrews?.[0]?.crew?.car;

	const registrationNumber = car?.additionalFields?.registrationNumber || "";
	const color = car?.color?.name?.[lang] || "";
	const callSign = car?.callSign || "";

	return {
		carData: `${
			car?.modelToBodyType?.model?.baseTypeToBrand?.brand?.name || ""
		} ${car?.modelToBodyType?.model?.name || ""} ${color || ""} ${
			registrationNumber ? `(${registrationNumber})` : ""
		} ${callSign ? `(${callSign})` : ""}`,
		carType: `${car?.class?.name?.[lang] || ""}`,
	};
};

const getCarAlias = (data) => {
	const carShiftJournals = data?.executorShiftJournals?.length
		? data?.executorShiftJournals?.at(0)
		: null;

	const executorToCrews = data?.executorToCrews || [];

	const crew = carShiftJournals
		? executorToCrews.find(
				({ crew }) => crew?.car?.id === carShiftJournals?.car?.id,
		  )?.crew
		: data?.executorToCrews?.[0]?.crew;

	const car = crew ? crew?.car : data?.executorToCrews?.[0]?.crew?.car;

	return car?.callSign || "";
};

const getSpeed = (data) => {
	const time = data?.speed || 0;
	const value = Number(time * 3.6).toFixed(1);
	return value;
};

const getPriority = (data) => data?.priority || 0;
const getRating = (data) => {
	const rating = data?.rating || "";
	// const rating = await ExecutorFeedback.getRating(value.id);
	return rating;
};

const getPersonFullName = (data) =>
	`${data?.person?.lastName ? data.person.lastName : ""} ${
		data?.person?.firstName ? data.person.firstName : ""
	} ${data?.person?.fatherName ? data.person.fatherName : ""}`;

const getBalance = (data) => {
	const main =
		data?.paymentAccounts?.find((a) => a.type === "main")?.amount ?? 0;

	return formatNumber(main);
};

const getLastOrder = (data) => {
	const max = data?.executorToOrders?.length
		? Math.max(
				...data.executorToOrders.map((a) =>
					new Date(a.order?.closedAt).getTime(),
				),
		  )
		: "";
	return max ? moment(max).format("HH:mm DD/MM/yyyy") : "";
};

const CarCallSing = ({ active, data, onDoubleClick }) => {
	const name = useMemo(() => getCarAlias(data), [data]);

	return (
		<StyledRow
			colors={active ? "" : "lightgray"}
			justify="center"
			alignItems="center"
			onDoubleClick={() => {
				onDoubleClick(data);
			}}
		>
			{name}
		</StyledRow>
	);
};

const ExecutorInfo = ({ data, onDoubleClick, executor }) => {
	const { t } = useTranslation();
	const lang = useTypedSelector((state) => state.session.language);
	const { getStatus } = useExecutorStatus();

	const actualExecutorJournal = data?.executorShiftJournals?.find(
		(journal) => journal.isLatest,
	)?.car?.class;

	const textColor = actualExecutorJournal?.textColor;
	const backgroundColor = actualExecutorJournal?.backgroundColor;

	const textColorHex = `${textColor?.model}(${textColor?.color?.join(",")})`;
	const backgroundColorHex = `${
		backgroundColor?.model
	}(${backgroundColor?.color?.join(",")})`;

	const carClassData = {
		shortName: actualExecutorJournal?.shortName || "",
		backgroundColor: backgroundColorHex,
		textColor: textColorHex,
		useBackgroundColor: actualExecutorJournal?.useBackgroundColor,
		useTextColor: actualExecutorJournal?.useTextColor,
	};

	const getOnlineStatus = (data) =>
		data.online
			? t("orderPageWidgets.parking.parkingTable.str200") ?? ""
			: t("orderPageWidgets.parking.parkingTable.str201") ?? "";

	const car = useMemo(() => getCar(data, lang), [data, lang]);
	const kph = useMemo(() => t("units.kph"), [t]);

	return (
		<Whisper
			speaker={
				<Popover>
					<div>
						<div>
							{t(
								"orderPageWidgets.parking.parkingTable.str209",
							) ?? ""}
							: {data?.alias}
						</div>
						<div>
							{t(
								"orderPageWidgets.parking.parkingTable.str210",
							) ?? ""}
							: {getPersonFullName(data)}
						</div>
						<div>
							{t(
								"orderPageWidgets.parking.parkingTable.str211",
							) ?? ""}
							: {getStatus(data)}
						</div>
						<div>
							{t(
								"orderPageWidgets.parking.parkingTable.str212",
							) ?? ""}
							: {getPersonPhones(data)}
						</div>
						<div>
							{t("utils.getTabsModeOne.cars") ?? ""}:{" "}
							{car.carData}
						</div>
						<div>
							{t("class") ?? ""}: {car.carType}
						</div>
						<div>
							{t(
								"orderPageWidgets.parking.parkingTable.str214",
							) ?? ""}
							: {getOnlineStatus(data)}
						</div>

						<div>
							{t("balances.main") ?? ""}: {getBalance(data)}
						</div>
						<div>
							{t(
								"orderPageWidgets.parking.parkingTable.str219",
							) ?? ""}
							: {getLastOrder(data)}
						</div>

						<div>
							{t(
								"orderPageWidgets.parking.parkingTable.str220",
							) ?? ""}
							: {`${getSpeed(executor)} ${kph}`}
						</div>

						<div>
							{t(
								"pages.settings.pages.orders.tabs.ordersDistribution.modal.options.priority",
							) ?? ""}
							: {getPriority(data)}
						</div>

						<div>
							{t("utils.getColumnsModeOne.rating") ?? ""}:{" "}
							{getRating(data)}
						</div>
					</div>
				</Popover>
			}
			placement="bottom"
		>
			<AliasRow
				bgColor={carClassData.backgroundColor}
				textColor={carClassData.textColor}
				useBgColor={carClassData.useBackgroundColor}
				useTextColor={carClassData.useTextColor}
				onDoubleClick={onDoubleClick}
				style={{ justifyContent: "center" }}
			>
				{`${data?.alias} ${carClassData.shortName || ""}`}
			</AliasRow>
		</Whisper>
	);
};

const ExecutorAlias = memo(ExecutorInfo);

const ModelTable: React.FC<Props> = ({
	data,
	sort,
	onSort,
	tableRowClassName,
	onRowDoubleClick,
	onRowSelect,
	// columnWidths,
	columnIds,
	language,
}) => {
	const { t } = useTranslation();
	const TABLE_HEADER_HEIGHT = 26;
	const TABLE_ROW_HEIGHT = 32;
	const { getStatus } = useExecutorStatus();

	const executorLocationsData = useModelSubscribe(
		{ revealExecutor: true },
		ExecutorLocations,
	);

	const EXECUTOR_STATUSES = useMemo(() => getExecutorStatuses(t), [t]);

	const getStatusData = useCallback(
		(rowData) => {
			const isWorking = rowData?.isWorking ?? false;

			const onOwnOrder =
				rowData?.executorToOrders?.find(
					({ order }) =>
						order?.source === "executor" && !order?.closedAt,
				) ?? false;

			const hasDebt = rowData?.debt ?? false;

			let status = rowData?.status;

			if (isWorking) {
				if (hasDebt) {
					if (onOwnOrder) {
						status = "ownOrderDebt";
					} else {
						status = "availableDebt";
					}
				} else if (onOwnOrder) {
					status = "ownOrder";
				}
			} else {
				status = "notWorking";
			}

			const statusData = EXECUTOR_STATUSES[status];

			return statusData;
		},
		[EXECUTOR_STATUSES],
	);

	const getStatusBackgroundColor = useCallback(
		(rowData) => {
			const online = rowData?.online ?? false;

			const statusData = getStatusData(rowData);

			return online ? statusData?.color : statusData?.offlineColor;
		},
		[getStatusData],
	);

	const getStatusFontColor = useCallback(
		(rowData) => {
			const statusData = getStatusData(rowData);

			return statusData?.fontColor;
		},
		[getStatusData],
	);

	const [rerender, setRerender] = useState(false);
	const { layout }: any = useTypedSelector((state) => state.widgets);

	useEffect(() => {
		if (layout?.dockbox?.children[1]?.activeId === "executors") {
			setRerender((prev) => !prev);
		}
	}, [layout?.dockbox?.children]);

	const getExecutorById = useCallback(
		(id: number) => {
			const exist = executorLocationsData?.models?.find(
				(item) => item?.executorId === id,
			);
			return exist;
		},
		[executorLocationsData],
	);

	// const getStatus = useCallback(
	// 	(data) => {
	// 		if (!data?.status) return "";
	// 		const { debt, status } = data;

	// 		const executorStatuses = {
	// 			busy: t("orderPageWidgets.parking.parkingTable.str202") ?? "",
	// 			dinner: t("orderPageWidgets.parking.parkingTable.str203") ?? "",
	// 			home: t("orderPageWidgets.parking.parkingTable.str204") ?? "",
	// 			on_order:
	// 				t("orderPageWidgets.parking.parkingTable.str205") ?? "",
	// 			available:
	// 				t("orderPageWidgets.parking.parkingTable.str206") ?? "",
	// 			closed: t("orderPageWidgets.parking.parkingTable.str207") ?? "",
	// 			ownOrder:
	// 				t("orderPageWidgets.parking.parkingTable.str208") ?? "",
	// 			debt: t("orderPageWidgets.executors.str206") ?? "",
	// 		};

	// 		const onOwnOrder = data?.executorToOrders?.find(
	// 			({ order }) => order?.source === "executor" && !order?.closedAt,
	// 		);

	// 		const orderExist = !!data?.executorToOrders?.length;

	// 		if (onOwnOrder) {
	// 			const onOrder = `${
	// 				orderExist ? `${executorStatuses.on_order} - ` : ""
	// 			}${executorStatuses.ownOrder}`;

	// 			if (data?.status === ExecutorStatus.BUSY) {
	// 				return `${onOrder}(${executorStatuses.busy}${
	// 					debt ? `, ${executorStatuses.debt}` : ""
	// 				})`;
	// 			}
	// 			if (status === ExecutorStatus.DINNER) {
	// 				return `${onOrder} (${executorStatuses.dinner}${
	// 					debt ? `, ${executorStatuses.debt}` : ""
	// 				})`;
	// 			}
	// 			if (status === ExecutorStatus.HOME) {
	// 				return `${onOrder} (${executorStatuses.home}${
	// 					debt ? `, ${executorStatuses.debt}` : ""
	// 				})`;
	// 			}
	// 			if (status === ExecutorStatus.ON_ORDER) {
	// 				return `${onOrder} ${
	// 					debt ? `(${executorStatuses.debt})` : ""
	// 				}`;
	// 			}
	// 		}

	// 		if (debt) {
	// 			if (data?.status === ExecutorStatus.BUSY) {
	// 				return `${
	// 					orderExist
	// 						? `${executorStatuses.on_order}`
	// 						: executorStatuses.busy
	// 				} (${
	// 					orderExist
	// 						? `${executorStatuses.busy}, `
	// 						: `${executorStatuses.on_order}, `
	// 				}${executorStatuses.debt})`;
	// 			}
	// 			if (status === ExecutorStatus.DINNER) {
	// 				return `${
	// 					orderExist
	// 						? executorStatuses.on_order
	// 						: executorStatuses.dinner
	// 				} (${
	// 					orderExist
	// 						? `${executorStatuses.on_order}, `
	// 						: `${executorStatuses.dinner}, `
	// 				}${executorStatuses.debt})`;
	// 			}
	// 			if (status === ExecutorStatus.HOME) {
	// 				return `${
	// 					orderExist
	// 						? executorStatuses.on_order
	// 						: executorStatuses.home
	// 				} (${
	// 					orderExist
	// 						? `${executorStatuses.on_order}, `
	// 						: `${executorStatuses.home}, `
	// 				}${executorStatuses.debt})`;
	// 			}
	// 			if (status === ExecutorStatus.ON_ORDER) {
	// 				return `${executorStatuses.on_order} (${executorStatuses.debt})`;
	// 			}
	// 		}

	// 		if (data?.status === ExecutorStatus.BUSY) {
	// 			if (orderExist) {
	// 				return `${executorStatuses.on_order} (${executorStatuses.busy}) `;
	// 			}
	// 			return executorStatuses.busy;
	// 		}

	// 		if (status === ExecutorStatus.DINNER) {
	// 			if (orderExist) {
	// 				return `${executorStatuses.on_order} (${executorStatuses.dinner}) `;
	// 			}
	// 			return executorStatuses.dinner;
	// 		}

	// 		if (status === ExecutorStatus.HOME) {
	// 			if (orderExist) {
	// 				return `${executorStatuses.on_order} (${executorStatuses.home}) `;
	// 			}
	// 			return executorStatuses.home;
	// 		}

	// 		if (status === ExecutorStatus.ON_ORDER) {
	// 			return executorStatuses.on_order;
	// 		}

	// 		return executorStatuses[status] || "";
	// 	},
	// 	[t],
	// );

	const Columns: Record<string, (context: ColumnContext) => ReactElement> = {
		online: () => (
			<CompactTable.Column
				// resizable
				sortable
				width={30}
				minWidth={30}
				key="online"
				// onResize={(newWidth) => handleColumnResize(newWidth, "online")}
			>
				<CompactTable.HeaderCell verticalAlign="middle">
					{t("orderPageWidgets.executors.modelTable.str150") ?? ""}
				</CompactTable.HeaderCell>
				<CompactTable.Cell fullText align="center" dataKey="online">
					{(rowData) => (
						<Row
							active={rowData.active}
							onDoubleClick={() => onRowDoubleClick(rowData)}
						>
							<CheckBox hovered={false} value={rowData.online} />
						</Row>
					)}
				</CompactTable.Cell>
			</CompactTable.Column>
		),
		alias: () => (
			<CompactTable.Column
				// resizable
				sortable
				width={55}
				minWidth={55}
				key="alias"
				// onResize={(newWidth) =>
				// 	handleColumnResize(newWidth, "alias")
				// }
			>
				<CompactTable.HeaderCell verticalAlign="middle">
					{t("orderPageWidgets.executors.modelTable.str151") ?? ""}
				</CompactTable.HeaderCell>
				<CompactTable.Cell align="center" dataKey="alias" fullText>
					{(rowData) => (
						<ExecutorAlias
							data={rowData}
							onDoubleClick={() => onRowDoubleClick(rowData)}
							executor={getExecutorById(rowData.id)}
						/>
					)}
				</CompactTable.Cell>
			</CompactTable.Column>
		),

		carCallSigns: () => (
			<CompactTable.Column
				// resizable
				sortable
				width={55}
				minWidth={55}
				key="carCallSigns"
				// onResize={(newWidth) =>
				// 	handleColumnResize(newWidth, "alias")
				// }
			>
				<CompactTable.HeaderCell verticalAlign="middle">
					{t("orderPageWidgets.executors.modelTable.str152") ?? ""}
				</CompactTable.HeaderCell>
				<CompactTable.Cell
					align="center"
					dataKey="carCallSigns"
					fullText
				>
					{(rowData) => (
						<CarCallSing
							active={rowData.active}
							onDoubleClick={() => onRowDoubleClick(rowData)}
							data={rowData}
						/>
					)}
				</CompactTable.Cell>
			</CompactTable.Column>
		),
		executorGroup: () => (
			<CompactTable.Column
				// resizable
				sortable
				width={100}
				minWidth={100}
				key="executorGroup"
				// onResize={(newWidth) =>
				// 	handleColumnResize(newWidth, "alias")
				// }
			>
				<CompactTable.HeaderCell verticalAlign="middle">
					{t("orderPageWidgets.executors.modelTable.str153") ?? ""}
				</CompactTable.HeaderCell>
				<CompactTable.Cell
					align="center"
					dataKey="executorGroup"
					fullText
				>
					{(rowData) => (
						<Row
							active={rowData.active}
							onDoubleClick={() => onRowDoubleClick(rowData)}
							style={{ justifyContent: "center" }}
						>
							{rowData?.group?.name?.[language]}
						</Row>
					)}
				</CompactTable.Cell>
			</CompactTable.Column>
		),
		company: () => (
			<CompactTable.Column
				// resizable
				sortable
				// width={columnWidths.company}
				minWidth={50}
				flexGrow={1}
				key="company"
				// onResize={(newWidth) => handleColumnResize(newWidth, "company")}
			>
				<CompactTable.HeaderCell verticalAlign="middle">
					{t("orderPageWidgets.executors.modelTable.str154") ?? ""}
				</CompactTable.HeaderCell>
				<CompactTable.Cell align="center" dataKey="company" fullText>
					{(rowData) => (
						<Row
							active={rowData.active}
							onDoubleClick={() => onRowDoubleClick(rowData)}
						>
							{rowData.taxiService?.company?.name[language]}
						</Row>
					)}
				</CompactTable.Cell>
			</CompactTable.Column>
		),
		taxiService: () => (
			<CompactTable.Column
				// resizable
				sortable
				// width={columnWidths.taxiService}
				minWidth={50}
				flexGrow={0.7}
				key="taxiService"
				// onResize={(newWidth) =>
				// 	handleColumnResize(newWidth, "taxiService")
				// }
			>
				<CompactTable.HeaderCell verticalAlign="middle">
					{t("orderPageWidgets.executors.modelTable.str155") ?? ""}
				</CompactTable.HeaderCell>
				<CompactTable.Cell
					align="center"
					dataKey="taxiService"
					fullText
				>
					{(rowData) => (
						<Row
							active={rowData.active}
							onDoubleClick={() => onRowDoubleClick(rowData)}
						>
							{rowData.taxiService?.settlement?.[language]}
						</Row>
					)}
				</CompactTable.Cell>
			</CompactTable.Column>
		),
		status: () => (
			<CompactTable.Column
				// resizable
				sortable
				// width={columnWidths.status}
				minWidth={50}
				flexGrow={1}
				key="status"
				// onResize={(newWidth) => handleColumnResize(newWidth, "status")}
			>
				<CompactTable.HeaderCell verticalAlign="middle">
					{t("orderPageWidgets.executors.modelTable.str156") ?? ""}
				</CompactTable.HeaderCell>
				<CompactTable.Cell fullText dataKey="status" align="center">
					{(rowData) => (
						<Row
							background={getStatusBackgroundColor(rowData)}
							color={getStatusFontColor(rowData)}
							active={rowData.active}
							onDoubleClick={() => onRowDoubleClick(rowData)}
						>
							{
								<span>
									{getStatus(rowData as Executor.Model)}
								</span>
							}
						</Row>
					)}
				</CompactTable.Cell>
			</CompactTable.Column>
		),
		isWorking: () => (
			<CompactTable.Column
				// resizable
				sortable
				// width={columnWidths.isWorking}
				minWidth={50}
				flexGrow={1}
				key="isWorking"
				// onResize={(newWidth) =>
				// 	handleColumnResize(newWidth, "workingStatus")
				// }
			>
				<CompactTable.HeaderCell verticalAlign="middle">
					{t("orderPageWidgets.executors.modelTable.str157") ?? ""}
				</CompactTable.HeaderCell>
				<CompactTable.Cell fullText dataKey="isWorking" align="left">
					{(rowData) => (
						<Row
							active={rowData.active}
							onDoubleClick={() => onRowDoubleClick(rowData)}
						>
							{
								<div
									style={{
										display: "flex",
										alignItems: "center",
										padding: "0px",
									}}
								>
									{rowData?.isWorking ? (
										<Circle color="rgb(76, 175, 80)" />
									) : (
										<Circle />
									)}
									<span style={{ marginLeft: 8 }}>
										{rowData?.schedule?.name?.[language]}
									</span>
								</div>
							}
						</Row>
					)}
				</CompactTable.Cell>
			</CompactTable.Column>
		),
	};

	return (
		<div style={{ overflow: "hidden" }}>
			<CompactTable
				data={data}
				virtualized
				headerHeight={TABLE_HEADER_HEIGHT}
				rowHeight={TABLE_ROW_HEIGHT}
				sortColumn={sort?.column}
				sortType={sort?.type}
				fillHeight
				loading={false}
				onScroll={() => {}}
				shouldUpdateScroll={false}
				onSortColumn={onSort}
				rowClassName={tableRowClassName}
				onRowClick={onRowSelect}
			>
				{columnIds.map((columnId) => Columns[columnId]({ language }))}
			</CompactTable>
		</div>
	);
};

export default ModelTable;
