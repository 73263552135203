/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import { Base, Car } from "..";

class ExecutorShiftJournals extends Base {
	static fromResponse(data): ExecutorShiftJournals.Model {
		return {
			id: data.id,
			isCurrent: data.isCurrent,
			isLatest: data.isLatest,
			car: data.car ? Car.fromResponse(data.car) : undefined,
		};
	}
}
declare namespace ExecutorShiftJournals {
	interface Model {
		id: number;
		isCurrent: boolean;
		isLatest: boolean;
		car?: Car.Model;
	}
}

export default ExecutorShiftJournals;
