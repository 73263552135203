import React, { useMemo } from "react";
import { isBoolean } from "lodash";

import Order from "../../../../../../../services/Order";
import Language from "../../../../../../../services/Language";
import CellContentRoot from "../CellContentRoot";

const TransferToTaxiServiceCellContent: React.FC<
	TransferToTaxiServiceCellContent.Props
> = ({
	item,

	language,
}) => {
	const isOwn = useMemo(() => {
		const value = item?.isOwn;
		return isBoolean(value) ? value : true;
	}, [item?.isOwn]);

	const transfer = useMemo(() => {
		const value = item?.additionalFields?.transfer;
		return value;
	}, [item]);

	const fromTaxiService = useMemo(
		() => transfer?.lastReceivedOrder?.taxiService,
		[transfer],
	);

	const transferHasExecutor = useMemo(
		() => transfer?.lastReceivedOrder?.executorToOrder?.length,
		[transfer?.lastReceivedOrder?.executorToOrder?.length],
	);

	const executor = useMemo(() => {
		if (item?.executorToOrder && item?.executorToOrder?.length) {
			return item?.executorToOrder?.[0]?.executor;
		}
		if (
			item?.additionalFields?.transfer?.lastReceivedOrder
				?.executorToOrder &&
			item?.additionalFields?.transfer?.lastReceivedOrder?.executorToOrder
				?.length
		) {
			return item.additionalFields.transfer.lastReceivedOrder
				.executorToOrder?.[0]?.executor;
		}

		return item?.offer?.executor;
	}, [
		item?.additionalFields?.transfer?.lastReceivedOrder?.executorToOrder,
		item?.executorToOrder,
		item?.offer?.executor,
	]);

	const settlement = useMemo(
		() => executor?.taxiService?.settlement?.[language],
		[executor?.taxiService?.settlement, language],
	);

	const company = useMemo(
		() => executor?.taxiService?.company?.name?.[language],
		[executor?.taxiService?.company?.name, language],
	);

	const content = useMemo(() => {
		if (isOwn && !transferHasExecutor) return "";
		if (!fromTaxiService) return "";
		if (!executor) return "";

		if (!settlement && company) {
			return company;
		}

		return `${company} (${settlement})`;
	}, [
		isOwn,
		fromTaxiService,
		settlement,
		company,
		executor,
		transferHasExecutor,
	]);

	return (
		<CellContentRoot align="center" maxedHeight maxedWidth>
			{content}
		</CellContentRoot>
	);
};

declare namespace TransferToTaxiServiceCellContent {
	interface Props {
		item: Order.Model;

		language: Language;
	}
}

export default TransferToTaxiServiceCellContent;
