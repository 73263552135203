import СurrencyDefinitions, { CURRENCIES } from "../types/СurrencyDefinitions";

/**
 * Generates an Excel-compatible currency format string for a given currency.
 * @param currency - The currency symbol to format the Excel cell.
 * @returns A string representing the Excel currency format.
 */
function getExcelCurrencyFormat(currency?: СurrencyDefinitions.Symbol): string {
	const defaultCurrencyFormat: СurrencyDefinitions.ExcelCode = "[$₴-uk-UA]";

	const currencyKey = Object.keys(CURRENCIES).find(
		(key) => CURRENCIES[key as СurrencyDefinitions.Key].symbol === currency,
	) as СurrencyDefinitions.Key | undefined;

	const currencyFormat = currencyKey
		? CURRENCIES[currencyKey].excelCode
		: defaultCurrencyFormat;

	const excelCurrencyFormat = currency
		? `#,#0.00 ${currencyFormat} ; -#,##0.00 ${currencyFormat} ; "-"?? ${currencyFormat} ; @_`
		: "";

	return excelCurrencyFormat;
}

export default getExcelCurrencyFormat;
