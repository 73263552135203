/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import { LatLngLiteral } from "leaflet";
import { clone, defaults, isEqual } from "lodash";

import ModelService from "../../redux/services/ModelService";
import priceZones from "../../redux/reducers/priceZones";
import { getPRPC } from "../../hooks/usePRPC";
import createRPCQuery from "../../utils/createRPCQuery.util";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import { Language } from "..";

import { destroyOne } from "./utils";

class PriceZone extends ModelService<
	PriceZone.SubscribeOptions,
	PriceZone.Model,
	"priceZones"
>(priceZones, (state) => state.priceZones) {
	static defaultSharedOptions: PriceZone.SharedOptions = {
		deprecate: true,
	};

	static fromResponse(data: any): PriceZone.Model {
		return {
			id: data.id,

			taxiServiceId: data.taxiService.id,

			position: new Date(data.position),
			name: data.name,
			vertices: data.vertices[0].slice(0, -1),
			active: data.status,
			suburban: data.suburban,
			bySector: data.bySector,

			createdAt: "",
			updatedAt: "",
			deletedAt: null,
		};
	}

	static toRequest(
		model: PriceZone.Model.New | PriceZone.Model.Modified,
	): any {
		const vertices = clone(model.vertices);

		if (vertices && !isEqual(vertices[vertices.length - 1], vertices[0]))
			vertices.push(vertices[0]);

		return {
			taxiServiceId: model.taxiServiceId,

			position: model.position,
			name: model.name,
			vertices,

			status: model.active,
			suburban: model.suburban,
		};
	}

	static async store(
		object: PriceZone.Model.New,
		options?: PriceZone.StoreOptions,
	) {
		options = defaults(options, PriceZone.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() =>
			prpc.theirsModel.priceZone.create(PriceZone.toRequest(object)),
		);

		if (options.deprecate) PriceZone.deprecateAll();
	}

	static async update(
		object: PriceZone.Model.Modified,
		options?: PriceZone.UpdateOptions,
	) {
		options = defaults(options, PriceZone.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		await createRPCQuery(() =>
			prpc.theirsModel.priceZone.update(
				object.id,
				PriceZone.toRequest(object),
			),
		);

		if (options.deprecate) PriceZone.deprecateAll();
	}

	static async destroy(
		id: number[] | number,
		options?: PriceZone.DestroyOptions,
	) {
		options = defaults(options, PriceZone.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		if (Array.isArray(id))
			await Promise.all(id.map((id) => destroyOne(id)));
		else await destroyOne(id);

		if (options.deprecate) PriceZone.deprecateAll();
	}

	static Global = {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		async index(options: PriceZone.SubscribeOptions) {
			const prpc = getPRPC();

			if (!prpc) return null;

			const result = await createRPCQuery(() =>
				prpc.theirsModel.priceZone.getAll({
					limit: options.limit,
					offset: options.offset,
					query: options.query,

					taxiServiceIds: options.taxiServiceIds,

					status: options.active,
					suburban: options.suburban,
					bySector: options.bySector,

					lang: options.language,

					order: options.order,
				}),
			);

			return {
				cache: result.items.map(PriceZone.fromResponse),
				offset: result.pagination.offset,
				limit: result.pagination.count,
				total: result.pagination.count,
				deprecated: false,
			};
		},
	};
}

declare namespace PriceZone {
	interface Model {
		id: number;

		taxiServiceId: number;

		position: Date | number;
		name: Record<Language, string>;
		vertices: LatLngLiteral[];

		active: boolean;
		suburban?: boolean;
		bySector: boolean;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<PriceZone.Model> {
		language?: Language;
		taxiServiceIds?: number[];

		active?: boolean;
		suburban?: boolean;
		bySector?: boolean;
	}

	interface SharedOptions {
		deprecate?: boolean;
	}

	interface StoreOptions extends SharedOptions {}
	interface UpdateOptions extends SharedOptions {}
	interface DestroyOptions extends SharedOptions {}

	namespace Model {
		type NonMutableOptions =
			| "id"
			| "bySector"
			| "createdAt"
			| "updatedAt"
			| "deletedAt";

		type New = Omit<Model, NonMutableOptions>;
		type Modified = Partial<New> & Pick<Model, "id">;
	}
}

export default PriceZone;
