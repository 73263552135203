import React, { Dispatch, useCallback, useMemo } from "react";
import { CheckBox } from "uikit";
import { RowDataType } from "rsuite-table";

import CarClass from "../../../../../../../../services/CarClass";
import { Language } from "../../../../../../../../services";
import { useTypedDispatch } from "../../../../../../../../redux/store";
// import setClasses from "../../../../../../../../redux/actions/Preferences/Classes/setClass";
// import returnClasses from "../../../../../../../../redux/services/Preferences/CarClass/returnClass";
import makeLookupTable from "../../../../../../../../utils/makeLookupTable";
import {
	useColumns,
	useVisibleColumns,
	useWidths,
} from "../../../../../../../../hooks/useTableSettings";
import LightTable, {
	UseTableOptions,
	useTableOptions,
} from "../../../../../../../../components/LightTable";
import {
	StyledGrid,
	StyledGridItem,
} from "../../../../../../../../components/common";
import TableSettings from "../../../../../../../../components/TableSettings";
import {
	TabKeys,
	ARR_ACCESS_PART_KEY,
} from "../../../../../../constants/access";

import columnBase, {
	ColumnId as LocalColumnId,
	defaultColumnIds,
	disabledColumnsIds,
} from "./columns";

const Content: React.FC<Content.Props> = ({
	data,
	selected,
	setSelected,
	onEdit,
	languageFilter,
	onChangeTable,
	editorTable,
}) => {
	const dispatch = useTypedDispatch();
	const {
		sort,
		onSort,
		onScroll,
		tableRef,
		onKeyUp,
		onKeyDown,
		ctrlPressed,
	} = useTableOptions({
		heightRow: 44,
		itemLimit: 2,
		value: editorTable,
		setValue: onChangeTable,
	});

	const [loading, setLoading] = React.useState(false);

	// const handleSortColumn = useCallback(
	// 	(sortColumn, sortType) => {
	// 		const promise: any = dispatch(
	// 			returnClasses({ sortType, sortColumn, limit: data.length }),
	// 		);

	// 		promise.then((result) => {
	// 			setLoading(false);
	// 			dispatch(setClasses(result));
	// 		});
	// 	},
	// 	[data.length, dispatch],
	// );
	// const loadMore = useCallback(() => {
	// 	setLoading(true);
	// 	const promise: any = dispatch(
	// 		returnClasses({ offset: data.length, limit: 50 }),
	// 	);

	// 	promise.then((result) => {
	// 		dispatch(setClasses([...data, ...result]));
	// 		setLoading(false);
	// 	});
	// }, [data, dispatch]);

	const columns = useColumns("carClass");
	const { columnIds, setColumnIds } = useVisibleColumns(
		"preferences.carClasses",
		"carClass",
	);
	const { widths, setWidths } = useWidths(
		`preferences.carClasses`,
		"carClass",
	);

	const modelTableColumns = useMemo<Content.Column[]>(
		() =>
			columns.map((column) => ({
				id: column.id as LocalColumnId,
				width: widths[column.id],
				visible: columnIds.includes(column.id as LocalColumnId),
			})),
		[columns, widths, columnIds],
	);

	const modelTableOnChangeColumns = useCallback(
		(columns: Content.Column[]) => {
			setColumnIds(
				columns
					.filter((column) => column.visible)
					.map((column) => column.id),
			);

			setWidths(
				columns.reduce((accumulator, column) => {
					accumulator[column.id] = column.width;
					return accumulator;
				}, {} as Record<LocalColumnId, number>),
			);
		},
		[setColumnIds, setWidths],
	);

	const tableSettingsValue = useMemo(
		() =>
			modelTableColumns
				.filter((column) => column.visible)
				.map((column) => column.id),
		[modelTableColumns],
	);

	const columnLookup = useMemo(
		() => makeLookupTable(modelTableColumns, (column) => column.id, true),
		[modelTableColumns],
	);
	const tableSettingsOnChange = useCallback(
		(columnIds: string[]) => {
			const otherColumns = modelTableColumns
				.filter((column) => !columnIds.includes(column.id))
				.map((column) => ({ ...column, visible: false }));

			const newColumns = columnIds.map((columnId) => ({
				id: columnId as LocalColumnId,
				width: columnLookup[columnId]?.value.width,
				visible: true,
			}));

			const arr = [...newColumns, ...otherColumns];
			modelTableOnChangeColumns(arr);
		},
		[columnLookup, modelTableColumns, modelTableOnChangeColumns],
	);

	const updateSelected = useCallback(
		(item: RowDataType<CarClass.Model>, force = false) => {
			const model = item as CarClass.Model;
			if (ctrlPressed || force) {
				if (selected.includes(model.id)) {
					setSelected(selected.filter((id) => model.id !== id));
				} else {
					setSelected([...selected, model.id]);
				}
			} else {
				selected.includes(model.id)
					? setSelected([])
					: setSelected([model.id]);
			}
		},
		[ctrlPressed, selected, setSelected],
	);

	const getClassName = useCallback(
		(rowData: RowDataType<CarClass.Model>) => {
			if (rowData && selected.includes(rowData.id)) {
				if (!rowData.active) return "selected not-active";
				return "selected";
			}
			if (rowData && !rowData.active) return "not-active";
			return "";
		},
		[selected],
	);

	const renderedColumns = useMemo(
		() =>
			modelTableColumns
				.filter((column) => column.visible)
				.map((column) => {
					const node = columnBase[column.id]?.render({
						width: column.width,
						language: languageFilter,

						onResize: (width, columnId) => {
							const newColumns = modelTableColumns.map(
								(columns) =>
									columns.id === columnId
										? { ...columns, width: width ?? 0 }
										: columns,
							);

							modelTableOnChangeColumns(newColumns);
						},
					});

					return node;
				}),
		[modelTableColumns, languageFilter, modelTableOnChangeColumns],
	);

	const areas = useMemo(() => `'table' 'settings' `, []);
	return (
		<StyledGrid
			areas={areas}
			rows="1fr 32px"
			w="100%"
			h="100%"
			br="5px"
			over="hidden"
		>
			<StyledGridItem
				area="table"
				w="100%"
				h="100%"
				tabIndex={-1}
				onKeyDown={onKeyDown}
				onKeyUp={onKeyUp}
			>
				<LightTable
					ref={tableRef}
					data={data}
					virtualized
					fillHeight
					headerHeight={44}
					rowHeight={44}
					loading={loading}
					onRowDoubleClick={onEdit}
					accessName={ARR_ACCESS_PART_KEY[TabKeys.CAR_CLASSES]}
					shouldUpdateScroll={false}
					rowClassName={getClassName}
					onRowClick={updateSelected}
					sortColumn={sort?.dataKey}
					sortType={sort?.sortType}
					onSortColumn={onSort}
					onScroll={onScroll}
				>
					<LightTable.Column width={64}>
						<LightTable.HeaderCell verticalAlign="middle">
							<CheckBox
								value={
									data.length
										? selected.length === data.length
										: false
								}
								onChange={(value) =>
									setSelected(
										value
											? data.map((item) => item.id)
											: [],
									)
								}
							/>
						</LightTable.HeaderCell>
						<LightTable.Cell>
							{(rowData) => (
								<CheckBox
									value={selected.includes(rowData.id)}
									onChange={() => {
										updateSelected(rowData, true);
									}}
								/>
							)}
						</LightTable.Cell>
					</LightTable.Column>
					{renderedColumns}
				</LightTable>
			</StyledGridItem>
			<StyledGridItem area="settings" w="100%" h="100%" zi="1">
				<TableSettings
					value={tableSettingsValue}
					defaultValue={defaultColumnIds}
					columns={columns}
					disabledColumnsIds={disabledColumnsIds}
					onChange={tableSettingsOnChange}
				/>
			</StyledGridItem>
		</StyledGrid>
	);
};

declare namespace Content {
	interface Column {
		id: LocalColumnId;
		width: number;
		visible: boolean;
	}

	interface Props {
		data: CarClass.Model[];
		onEdit: (item: CarClass.Model) => void;

		languageFilter: Language;

		selected: number[];
		setSelected: Dispatch<number[]>;

		editorTable: UseTableOptions["editor"];
		onChangeTable: UseTableOptions["onChange"];
	}
}

export default Content;
