import { Dispatch } from "react";
import { react } from "uikit";

import CarBaseTypeSelect from ".";

interface Context {
	value: CarBaseTypeSelect.Value;

	setError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const value = this.context?.value;
		const isValueValid = typeof value !== "undefined" && value !== "";

		if (!isValueValid) this.context?.setError(true);

		return isValueValid;
	}
}
