import React, {
	memo,
	useCallback,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useDebouncedCallback } from "use-debounce";
import { Controller, ControllerRenderProps } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { isBoolean } from "lodash";
import styled from "styled-components";
import { Icon, InputBorders, useInternal } from "uikit";

import { OrderCodeInfo } from "../../../../../../../../redux/services/Order/getOrderCodeInfo";
import { Schema } from "../../../../../../../../redux/constants/OrdersPage/order";
import {
	useTypedDispatch,
	useTypedSelector,
} from "../../../../../../../../redux/store";
import getPhoneInfo, {
	DiscountPlan,
	PhoneBlacklistData,
	PhoneInfoCounter,
	getPlanFromPhone,
} from "../../../../../../../../redux/services/Order/getPhoneInfo";
import closeOrderCard from "../../../../../../../../redux/services/Order/card/closeOrderCard";
import {
	useKeyBindSettings,
	useKeyBindParams,
} from "../../../../../../../../hooks/useKeyBindSettings";
import {
	countryCodePhone,
	defaultCountryCodePhone,
	validatePhone,
} from "../../../../../../../../utils/validatePhone";
import { WalletIcon } from "../../../../../../../../icons/wallet";
import { Divider, StyledRow } from "../../../../../../../../components/common";
import {
	BasePassenger,
	Passenger,
	PhoneBlacklist,
} from "../../../../../../../../components/Orders";
import { useAppContext } from "../../../../../../../../context";
import { useOrderModalContext } from "../../../../../context";
import { useCreateClient } from "../../hooks/useCreateClient";
import { OrderFormProps } from "../../types/FormProps";
import {
	StyledBalance,
	StyledButton,
	StyledInputGroup,
	StyledTextBox,
} from "../styled";

import {
	BalancePaymentAccounts,
	useCustomerBalance,
} from "./hooks/useCustomerBalance";
import { useCounterpartyBalance } from "./hooks/useCounterpartyBalance";
import { useAgentBalance } from "./hooks/useAgentBalance";

const StyledCustomer = styled(StyledRow)`
	flex: 1 1 0;
`;

const Wrap = styled(StyledRow)`
	width: 100%;
`;
interface Props extends OrderFormProps {
	setTabAccept: () => void;
}

type CardCounterpartType = {
	field: ControllerRenderProps<Schema, "cardCounterpart">;
};

const Customer: React.FC<Props> = ({ form, disabled, tab, setTabAccept }) => {
	const dispatch = useTypedDispatch();
	const { t } = useTranslation();
	const { settings } = useOrderModalContext();
	const { getCompanyIdByTaxiServiceId } = useAppContext();
	const {
		getRateId,
		getServices,
		isChangTaxiService,
		setIsChangTaxiService,
		isOwn,
	} = useOrderModalContext();

	const language = useTypedSelector((state) => state.session.language);

	const { rates } = useTypedSelector(
		(state) => state.preferencesReducer.rates,
	);
	const carClassModels = useTypedSelector(
		(state) => state.preferencesReducer.carClasses.data.models,
	);

	const refInputPhone = useRef<HTMLInputElement | null>(null);
	const refInputLoyaltyCard = useRef<HTMLInputElement | null>(null);

	const [mainPhone, setMainPhone] = useInternal<string>("");

	const [openModal, setOpenModal] = useInternal<boolean>(false);
	const [isSwap, setIsSwap] = useInternal<boolean>(true);
	const [isSavePhone, setIsSavePhone] = useInternal<boolean>(false);
	const [errorPhone, setErrorPhone] = useState<boolean>(false);
	const [isCleanupCustomer, setIsCleanupCustomer] =
		useInternal<boolean>(false);
	const [latesTaxiService, setLatesTaxiService] = useState<boolean>(false);

	const [phoneBlacklistModal, setPhoneBlacklistModal] = useState<
		PhoneBlacklistData | undefined | null
	>(null);

	const {
		mainBalance,
		mainBalanceType,
		bonusBalance,
		bonusBalanceType,
		setPaymentAccounts,
		resetBalance,
		setCurrency,
		currency,
	} = useCustomerBalance({
		form,
		tab,
	});

	const { create } = useCreateClient();
	const {
		setPaymentAccounts: setCounterpartyPaymentAccounts,
		resetBalance: resetBalanceCounterparty,
		currency: counterpartCurrency,
	} = useCounterpartyBalance({
		form,
		tab,
	});

	const {
		setPaymentAccounts: setAgentPaymentAccounts,
		resetBalance: resetAgentBalance,
		currency: agentCurrency,
	} = useAgentBalance({
		form,
		tab,
	});

	useLayoutEffect(() => {
		const phone = tab.form?.phone;
		setMainPhone(String(phone));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tab.form.phone]);

	const mainPhonePlaceholder = useMemo(
		() => t(`orderPage.order_form.phone_number`) || "",
		[t],
	);
	const placeholderUserName = useMemo(() => t("client") || "", [t]);
	const clearPhone = useMemo(
		() => tab.form.clearPhone,
		[tab.form?.clearPhone],
	);
	const clearCounterparty = useMemo(
		() => tab.form.clearCounterparty,
		[tab.form?.clearCounterparty],
	);

	const isConterparty = useMemo(
		() => tab.form.counterparty && tab.form?.code,
		[tab.form?.counterparty, tab.form?.code],
	);

	const mainPhoneError = useMemo(() => errorPhone, [errorPhone]);
	const transfer = useMemo(() => tab.form?.transfer, [tab.form?.transfer]);

	const phoneFromTransfer = useMemo(() => {
		if (!transfer?.lastReceivedOrder?.phones?.length) return "";

		const phoneFrom = transfer?.lastReceivedOrder?.phones?.at(0)?.number;
		return phoneFrom;
	}, [transfer]);

	const showPhoneFromTransfer = useMemo(() => {
		if (!transfer?.lastReceivedOrder?.phones?.length && !transfer?.executor)
			return false;
		if (isOwn) return false;
		const phoneFrom = transfer?.lastReceivedOrder?.phones?.at(0)?.number;
		if (!isOwn && !phoneFrom) return false;

		return true;
	}, [transfer, isOwn]);

	useLayoutEffect(() => {
		setErrorPhone(!tab.validator.phone);
	}, [tab.validator.phone]);

	const userName = useMemo(() => tab.form.fullName, [tab.form.fullName]);
	const currencyName = useMemo(
		() => tab.form?.currency?.settings?.name || "",
		[tab.form?.currency],
	);

	const allCurrency = useMemo(
		() => currency || counterpartCurrency || agentCurrency || currencyName,
		[agentCurrency, counterpartCurrency, currency, currencyName],
	);

	const userBonusBalance = useMemo(() => {
		if (bonusBalance === null) return "";
		return `${bonusBalance.toFixed(2)} ${allCurrency}`;
	}, [bonusBalance, allCurrency]);

	const userMainBalance = useMemo(() => {
		if (mainBalance === null || mainBalance === 0) return "";
		return `${mainBalance.toFixed(2)} ${allCurrency}`;
	}, [mainBalance, allCurrency]);

	const colorMainBalance = useMemo(() => {
		if (mainBalance === null) return "#4CAF50";
		if (mainBalance < 0) return "#f83528";
		return "#4CAF50";
	}, [mainBalance]);

	const colorBonusBalance = useMemo(() => {
		if (bonusBalance === null) return "#f83528";
		if (bonusBalance < 0) return "#f83528";
		return "#03A9F4";
	}, [bonusBalance]);

	const userModalDisabled = useMemo(() => {
		let statePhone = !mainPhone;

		if (mainPhone) {
			statePhone = mainPhone.length < 11;
		}

		if (mainPhone?.length > 10) {
			const isValid = validatePhone(mainPhone);
			setErrorPhone(!isValid);
			statePhone = !isValid;
		}

		return statePhone || disabled;
	}, [disabled, mainPhone]);

	const dataPassenger = useMemo<BasePassenger.Value>(
		() => ({
			name: tab.form?.customerName,
			surname: tab.form?.customerSurname,
			fatherName: tab.form?.customerFatherName,
			notes: tab.form?.customerNotes,
			birthday: tab.form?.customerBirthday,
			carClassId: tab.form?.customerCarClassId,
			carClassName: tab.form?.customerCarClassName,
		}),
		[
			tab.form?.customerBirthday,
			tab.form?.customerCarClassId,
			tab.form?.customerCarClassName,
			tab.form?.customerFatherName,
			tab.form?.customerName,
			tab.form?.customerNotes,
			tab.form?.customerSurname,
		],
	);

	const companyID = useMemo(
		() => tab?.form?.companyID,
		[tab?.form?.companyID],
	);

	const taxiServiceId = useMemo(
		() => tab?.form?.taxiServiceId,
		[tab?.form?.taxiServiceId],
	);

	const handleSave = useCallback(() => {
		if (phoneBlacklistModal) return;
		form.setValue("orderSave", true);
		form.setValue("orderPointSave", false);
	}, [form, phoneBlacklistModal]);

	const handleSavePhone = useCallback(() => {
		if (!isSavePhone || phoneBlacklistModal) return;
		handleSave();
	}, [handleSave, isSavePhone, phoneBlacklistModal]);

	const closeCurrentCard = useCallback(() => {
		setPhoneBlacklistModal(null);
		if (!tab.form.id) {
			console.error(
				"Cannot close order card, because order id is not specified",
			);
			return;
		}
		dispatch(closeOrderCard(tab.form.id));
	}, [tab, dispatch]);

	const changedRateId = useCallback(
		(carClasses: { value: number; name: string }[]) => {
			const carClassIds = carClasses.map((item) => item.value);

			const foundRates = rates.filter((rate) => {
				const cars = carClassIds
					.map((item) => rate.carClassIds.includes(item))
					.every((item) => item);

				const taxi = rate.taxiServiceIds.includes(taxiServiceId);
				const company = rate.taxiServices.find((service) => {
					if (service.company?.id === companyID) return true;
					return false;
				});

				if (taxi && company && cars) return true;
				return false;
			});

			const rateId = foundRates?.at(0)?.id;

			form.setValue("rateId", rateId || 1);
		},
		[companyID, rates, form, taxiServiceId],
	);

	const changeCompanyAndTaxiService = useCallback(
		(data: { companyId?: number; taxiServiceId?: number }) => {
			if (!settings?.autoChangeOfFilialOfLastCustomerTrip) {
				return;
			}
			if (phoneBlacklistModal) return;
			if (disabled) return;
			const { companyId, taxiServiceId } = data;

			if (!companyId) return;
			if (!taxiServiceId) return;
			if (latesTaxiService && companyID === companyId) return;
			const existCompanyId = getCompanyIdByTaxiServiceId(taxiServiceId);

			if (existCompanyId !== companyID) return;

			setLatesTaxiService(true);

			const rateId = getRateId(companyId);
			const modifyServices = getServices(taxiServiceId);

			form.setValue("taxiServiceId", taxiServiceId);
			form.setValue("rateId", rateId);
			form.setValue("services", modifyServices);
		},
		[
			phoneBlacklistModal,
			latesTaxiService,
			companyID,
			getCompanyIdByTaxiServiceId,
			getRateId,
			getServices,
			form,
			disabled,
			settings?.autoChangeOfFilialOfLastCustomerTrip,
		],
	);

	const onAddPassenger = useCallback<BasePassenger.Props["onSave"]>(
		({
			name,
			surname,
			fatherName,
			notes,
			birthday,
			carClassId,
			carClassName = "",
		}) => {
			form.setValue("customerName", name);
			form.setValue("customerSurname", surname);
			form.setValue("customerFatherName", fatherName);
			form.setValue("customerNotes", notes);
			form.setValue("customerBirthday", birthday || null);
			form.setValue("customerCarClassId", carClassId || null);
			form.setValue(
				"fullName",
				`${surname || ""} ${name || ""} ${fatherName || ""}`.trim(),
			);

			const carClass = form.getValues("taxiClass");
			if (carClassId) {
				const taxiClass = { value: carClassId, name: carClassName };
				const exist = carClass?.find(
					(item) => item.value === carClassId,
				);
				if (exist) return;
				form.setValue("taxiClass", [taxiClass]);
				changedRateId([taxiClass]);
			} else {
				const carClassBase =
					carClassModels.find(
						(item) => item.active && item.root && item.default,
					) || carClassModels[0];

				const baseTaxi = {
					value: carClassBase?.id || 1,
					name: carClassBase?.name?.[language] || "",
				};
				const exist = carClass?.find(
					(item) => item.value === baseTaxi.value,
				);
				if (exist) return;
				if (carClass?.length === 1) {
					changedRateId([baseTaxi]);
					form.setValue("taxiClass", [baseTaxi]);
					return;
				}

				if (carClass) changedRateId(carClass);
				form.setValue("taxiClass", carClass);
			}
		},
		[carClassModels, changedRateId, form, language],
	);

	// clearing the phone and data associated with the phone if it is not fount.
	const cleanupCustomer = useCallback(
		(numberPhone?: string) => {
			const phone = tab.form?.phone;
			const allPhones = tab.form?.additionalPhones || [];
			const phones = [...allPhones].filter(
				(item) => typeof item === "string",
			);

			if (phone && isCleanupCustomer) return;

			if (
				phones.length === 1 &&
				!isSwap &&
				phones[0].length >= 11 &&
				phones[0].length < 13 &&
				!numberPhone
			) {
				form.setValue("phone", phones[0]);
			}

			form.setValue("phone", numberPhone);
			form.setValue("phoneType", undefined);
			form.setValue("fullName", "");
			form.setValue("totalRideCount", 0);
			form.setValue("rideNumber", 0);
			form.setValue("counterRideTotal", 0);
			form.setValue("counterRideCancelled", 0);
			form.setValue("counterRideFailed", 0);
			form.setValue("counterRideSuccess", 0);
			form.setValue("customerName", "");
			form.setValue("customerSurname", "");
			form.setValue("customerFatherName", "");
			form.setValue("customerNotes", "");
			form.setValue("customerBirthday", null);
			form.setValue("additionalPlan", undefined);
			form.setValue("mainPlan", undefined);
			resetBalance();

			if (!isSwap && phones.length > 0) return;

			const getTaxiClass = form.getValues("taxiClass");
			const customerCarClassId = form.getValues("customerCarClassId");

			if (getTaxiClass && getTaxiClass.length) {
				form.setValue(
					"taxiClass",
					getTaxiClass?.filter(
						(item) => item.value !== customerCarClassId,
					),
				);
			}

			form.setValue("customerCarClassId", null);
			if (isSwap) {
				setIsSwap(false);
			} else {
				setIsCleanupCustomer(true);
				if (tab.form.code) return;

				form.setValue("paymentType", "cash");
				if (phones.length > 0) return;
				form.setValue("additionalPhones", []);
			}
		},
		[
			form,
			isCleanupCustomer,
			isSwap,
			resetBalance,
			setIsCleanupCustomer,
			setIsSwap,
			tab.form,
		],
	);

	const debounceOpen = useDebouncedCallback((isOpen: boolean) => {
		if (settings.autoOpenNewCustomerModal?.active) {
			if (!mainPhone) {
				setOpenModal(false);
				return;
			}
			setOpenModal(isOpen);
		}
	}, 1000);

	const debounceFullName = useDebouncedCallback((value: string) => {
		const name = value.trim().split(" ");
		if (name.length === 1) {
			form.setValue("customerName", name[0]);
			form.setValue("customerSurname", "");
			form.setValue("customerFatherName", "");
			return;
		}

		form.setValue("customerSurname", name[0] || "");
		form.setValue("customerName", name[1] || "");
		form.setValue("customerFatherName", name[2] || "");
	}, 200);

	const addPlan = useCallback(
		(data: DiscountPlan, type: "additionalPlan" | "mainPlan") => {
			const payload = {
				active: data?.active,
				name: data?.name,
				type: data?.type,
				maxDiscount: data?.maxDiscount,
				discount: data?.discount,
				types: data?.types,
			};
			form.setValue(type, payload);
			return payload;
		},
		[form],
	);

	const debounceCustomerSearch = useDebouncedCallback((raw) => {
		const phone = raw?.trim() || "";
		const phoneNumber = phone
			.split("")
			.filter((a) => Number.isFinite(+a))
			.join("");

		cleanupCustomer(phoneNumber);

		if (phoneNumber.length >= 11 && phoneNumber.length < 13) {
			setIsSavePhone(true);
			const companyId = form.getValues("companyID") || 0;
			const addCounters = (counters: PhoneInfoCounter) => {
				if (counters) {
					const { total, success, failed, cancelled } = counters;
					form.setValue("totalRideCount", total);
					form.setValue("rideNumber", total);
					form.setValue("counterRideTotal", total || 0);
					form.setValue("counterRideCancelled", cancelled || 0);
					form.setValue("counterRideFailed", failed || 0);
					form.setValue("counterRideSuccess", success || 0);
				}
			};

			dispatch(
				getPhoneInfo({ companyId, phone: phoneNumber }, async (res) => {
					setIsCleanupCustomer(false);
					cleanupCustomer();

					if (!res) return;
					const {
						client,
						blacklist,
						paymentAccounts,
						detectedCheckCard,
					} = res;

					if (client || blacklist) debounceOpen(false);
					else debounceOpen(true);

					setPhoneBlacklistModal(blacklist);
					if (client) {
						setOpenModal(false);
						if (client.type === "customer") {
							const { data } = client;

							if (
								data?.defaultTaxiService?.settings?.sms
									?.template?.currency
							) {
								setCurrency(
									data.defaultTaxiService.settings.sms
										.template.currency || "",
								);
							}

							changeCompanyAndTaxiService({
								companyId:
									client?.data?.latestTaxiService?.company
										?.id,
								taxiServiceId:
									client?.data?.latestTaxiService?.id,
							});

							onAddPassenger({
								name: client?.data?.person?.name || "",
								surname: client?.data?.person?.surname || "",
								fatherName:
									client?.data?.person?.fatherName || "",
								notes: client?.data?.notes || "",
								birthday:
									client?.data?.person?.birthday || null,
								carClassId: client?.data?.carClass?.id || null,
								carClassName:
									client?.data?.carClass?.name?.[language],
							});
							addCounters(data.counters);
							setPaymentAccounts(
								paymentAccounts || data?.paymentAccounts,
							);
							tab.setState({
								...tab.state,
								customerId: data.id,
							});
							form.setValue("phone", phoneNumber);
							form.setValue("phoneType", client.type);

							if (client.data?.additionalPlan && !isConterparty) {
								const plan = getPlanFromPhone(
									client.data.additionalPlan,
									data.counters,
								);
								addPlan(plan, "additionalPlan");
							}

							if (client.data?.mainPlan && !isConterparty) {
								const plan = getPlanFromPhone(
									client.data.mainPlan,
									data.counters,
								);

								addPlan(plan, "mainPlan");
							}

							const driverNote =
								client?.data?.executorNotes || "";
							const clientNote = client?.data?.orderNotes || "";

							form.setValue("driverNote", driverNote);
							form.setValue("clientNote", clientNote);
						}

						if (client.type === "employee") {
							resetBalance();
							resetAgentBalance();
							const employee = client.data;
							const checkCard =
								detectedCheckCard || employee.checkCards?.[0];
							form.setValue("counterparty", {
								id: employee.counterparty?.id,
								name:
									employee.counterparty?.additionalFields
										?.name || "",
								checkCardId: checkCard?.id,
							});

							onAddPassenger({
								notes: employee.customer?.notes || "",
								name: employee.customer?.person?.name || "",
								surname:
									employee.customer?.person?.surname || "",
								fatherName:
									employee.customer?.person?.fatherName || "",
								birthday:
									employee.customer?.person?.birthday || null,
								carClassId:
									employee.customer?.carClass?.id || null,
								carClassName:
									employee?.customer?.carClass?.name?.[
										language
									],
							});

							const employeePaymentAccounts =
								paymentAccounts?.length
									? paymentAccounts
									: false ||
									  checkCard?.check?.paymentAccounts ||
									  employee?.counterparty?.paymentAccounts;

							setCounterpartyPaymentAccounts(
								employeePaymentAccounts,
							);

							addCounters(employee?.rideCounter);
							tab.setState({
								...tab.state,
								customerId: employee.customer.id,
							});
							form.setValue("phone", phoneNumber);

							form.setValue("phoneType", client.type);
							form.setValue("paymentType", "company_bill");
							const driverNote =
								(checkCard?.additionalFields?.executorNotes ||
									checkCard?.check?.additionalFields
										?.executorNotes ||
									checkCard?.check?.counterparty
										?.additionalFields?.executorNotes) ??
								"";
							const clientNote =
								(checkCard?.additionalFields?.orderNotes ||
									checkCard?.check?.additionalFields
										?.orderNotes ||
									checkCard?.check?.counterparty
										?.additionalFields?.orderNotes) ??
								"";

							if (checkCard.check?.additionalPlan) {
								const plan = getPlanFromPhone(
									checkCard.check?.additionalPlan,
									checkCard?.rideCounter,
								);
								addPlan(plan, "additionalPlan");
							}
							if (checkCard.check?.mainPlan) {
								const plan = getPlanFromPhone(
									checkCard.check?.mainPlan,
									checkCard?.rideCounter,
								);
								addPlan(plan, "mainPlan");
							}

							form.setValue("driverNote", driverNote);
							form.setValue("clientNote", clientNote);
							const code =
								employee?.code?.value ||
								checkCard?.code?.value ||
								"";
							form.setValue("code", code);
						}

						if (client.type === "agent") {
							resetBalanceCounterparty();
							const agent = client.data;
							const {
								id,
								name,
								agentToRewards,
								isRewardAllowed,
								isArbitraryRewardAllowed,
							} = agent;

							const rewards =
								agentToRewards?.map((item) => item.reward) ||
								[];

							const reward = rewards?.find(
								(item) => item?.default === true,
							);

							const agentPaymentAccounts =
								agent.paymentAccounts as OrderCodeInfo.PaymentAccount[];

							form.setValue("agent", {
								id,
								checkCardId: agent?.code?.id,
								name,
								reward: reward || null,
								rewards: rewards || [],
								paymentAccounts: agentPaymentAccounts || [],
								isRewardAllowed: isRewardAllowed || false,
								isArbitraryRewardAllowed:
									isArbitraryRewardAllowed || false,
							});

							setAgentPaymentAccounts(agentPaymentAccounts || []);

							onAddPassenger({
								name: "",
								surname: "",
								fatherName: "",
								notes: "",
								birthday: null,
								carClassId: null,
								carClassName: "",
							});

							form.setValue("counterparty", {
								id: undefined,
								name: name?.[language],
								// checkCardId: agent?.code?.id,
							});
							form.setValue("paymentType", "cash");
							form.setValue("phone", phoneNumber);
							form.setValue("phoneType", client.type);

							const driverNote =
								(agent?.checkCard?.additionalFields
									?.executorNotes ||
									agent?.checkCard?.check?.additionalFields
										?.executorNotes ||
									agent?.checkCard?.check?.counterparty
										?.additionalFields?.executorNotes ||
									agent?.counterparty?.additionalFields
										?.executorNotes ||
									agent.additionalFields?.executorNotes) ??
								"";
							const clientNote =
								(agent?.checkCard?.additionalFields
									?.orderNotes ||
									agent?.checkCard?.check?.additionalFields
										?.orderNotes ||
									agent?.checkCard?.check?.counterparty
										?.additionalFields?.orderNotes ||
									agent?.counterparty?.additionalFields
										?.orderNotes ||
									agent.additionalFields?.orderNotes) ??
								"";

							form.setValue("driverNote", driverNote);
							form.setValue("clientNote", clientNote);
							const code = agent?.code?.value || "";
							form.setValue("code", code);
						}
					}
					if (!client) {
						form.setValue("phone", phoneNumber);
						// TODO: clear up data about prev client
						const newCustomer = await create({
							...tab.form,
							phone: phoneNumber,
						});

						if (newCustomer) {
							form.setValue("phoneType", "customer");

							if (newCustomer?.additionalPlan) {
								addPlan(
									newCustomer?.additionalPlan,
									"additionalPlan",
								);
							}

							if (newCustomer?.mainPlan) {
								addPlan(newCustomer?.mainPlan, "mainPlan");
							}

							if (!tab.form.counterparty) {
								form.setValue("paymentType", "cash");
							}
							const {
								paymentAccounts,
								id,
								person,
								notes,
								carClassId,
							} = newCustomer;

							onAddPassenger({
								name: person?.firstName || "",
								surname: person?.lastName || "",
								fatherName: person?.fatherName || "",
								notes: notes || "",
								birthday: (person?.birthday as any) || null,
								carClassId: carClassId || null,
								carClassName: !isSwap
									? tab.form.customerCarClassName ||
									  tab.form.taxiClass?.[0]?.name ||
									  ""
									: tab.form.taxiClass?.[0]?.name || "",
							});

							setPaymentAccounts(
								paymentAccounts as BalancePaymentAccounts[],
							);

							tab.setState({
								...tab.state,
								customerId: id,
							});
						} else {
							form.setValue("phoneType", undefined);
							tab.setState({
								...tab.state,
								customerId: null,
							});
						}
					}
				}),
			);
		}
	}, 500);

	useLayoutEffect(() => {
		if (mainPhone?.length > 10) {
			const isValid = validatePhone(mainPhone);
			if (companyID && taxiServiceId && isValid && isChangTaxiService) {
				debounceCustomerSearch(mainPhone);
				setIsChangTaxiService(false);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taxiServiceId, isChangTaxiService]);

	// clearing the phone and data associated with the phone if it is not fount.
	// after canceling the last action in the order.
	useLayoutEffect(() => {
		if (isBoolean(clearPhone) && clearPhone) {
			console.log("[ OrderModal ] clearPhone");
			setIsCleanupCustomer(false);
			cleanupCustomer();
			resetBalanceCounterparty();
			resetAgentBalance();
			setMainPhone("");
			form.setValue("mainPaymentAccount", undefined);
			form.setValue("bonusPaymentAccount", undefined);
			form.setValue("clearPhone", undefined);
			form.setValue("clearCounterparty", undefined);
			form.setValue("paymentChangeType", undefined);
		}
	}, [
		cleanupCustomer,
		clearPhone,
		form,
		resetAgentBalance,
		resetBalance,
		resetBalanceCounterparty,
		setIsCleanupCustomer,
		setMainPhone,
	]);

	// Update paymentAccounts after canceling the last action in the order.
	useLayoutEffect(() => {
		if (
			tab.form?.phone &&
			tab.form?.phone?.length > 10 &&
			isBoolean(clearCounterparty) &&
			clearCounterparty &&
			isBoolean(clearPhone) &&
			!clearPhone
		) {
			const isValid = validatePhone(tab.form.phone || "");
			if (companyID && taxiServiceId && isValid) {
				console.log("[ OrderModal ] clearCounterparty");
				form.setValue("mainPaymentAccount", undefined);
				form.setValue("bonusPaymentAccount", undefined);
				form.setValue("paymentChangeType", undefined);
				debounceCustomerSearch(tab.form.phone);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [clearCounterparty]);

	const onRenderCardCounterpart = useCallback(
		({ field }: CardCounterpartType) => {
			const placeholder =
				t(
					"pages.mainPage.pages.orders.orderModal.personalInformation.str215",
				) || "";
			return (
				<StyledRow flex={{ wrap: "nowrap" }} w="150px">
					<StyledTextBox
						autoComplete="off"
						inputRef={refInputLoyaltyCard}
						tabIndex={1}
						type={"string"}
						disabled={disabled}
						placeholder={placeholder}
						value={String(field.value ?? "")}
						onChange={(value) => {
							field.onChange(value);
							// debounceCounterpartSearch(value);
						}}
					/>
				</StyledRow>
			);
		},
		[t, disabled],
	);

	const focusToStreet = useCallback(() => {
		const nextElem = document.getElementById("1street");
		if (nextElem) nextElem.focus();
		return nextElem;
	}, []);

	const onChangePhone = useCallback(
		(value: string) => {
			const modifyPhone = countryCodePhone(
				value,
				defaultCountryCodePhone,
			);
			const isValid = validatePhone(modifyPhone);

			if (errorPhone) setErrorPhone(false);
			setMainPhone(modifyPhone);
			if (isValid) {
				setOpenModal(false);
				debounceCustomerSearch(modifyPhone);
			}
		},
		[
			errorPhone,
			debounceCustomerSearch,
			setMainPhone,
			setOpenModal,
			setErrorPhone,
		],
	);

	const onChangeFullName = useCallback(
		(value: string) => {
			form.setValue("fullName", value);
			debounceFullName(value);
		},
		[form, debounceFullName],
	);

	const onConfirm = useCallback(() => {
		setPhoneBlacklistModal(null);
		focusToStreet();
		handleSave();
	}, [handleSave, focusToStreet]);

	const onToggleModal = useCallback(() => {
		setOpenModal((prev) => {
			if (prev) focusToStreet();
			return !prev;
		});
	}, [setOpenModal, focusToStreet]);

	const onSubmitPassenger = useCallback<BasePassenger.Props["onSave"]>(
		(data) => {
			onAddPassenger(data);
			onToggleModal();
			handleSave();
			focusToStreet();
		},
		[onAddPassenger, onToggleModal, handleSave, focusToStreet],
	);

	const swapPhones = useCallback(() => {
		if (!isOwn) return;
		const phones = tab.form?.additionalPhones;
		const allPhone = [mainPhone, ...phones]
			.filter((item, pos, arr) => !pos || item !== arr[pos - 1])
			.reverse();

		const firstPhone: string = allPhone?.at(0) || "";
		const secondPhone: string = allPhone?.at(1) || "";

		const elem = refInputPhone.current;
		if (elem) elem.focus();

		if (!firstPhone && !secondPhone) return;

		const changeFirstPhone =
			firstPhone === mainPhone ? secondPhone : firstPhone;
		const changeSecondPhone =
			secondPhone === changeFirstPhone ? firstPhone : secondPhone;

		onChangePhone(changeFirstPhone);
		setIsSwap(true);
		form.setValue("additionalPhones", [changeSecondPhone]);
	}, [
		tab.form?.additionalPhones,
		mainPhone,
		onChangePhone,
		setIsSwap,
		form,
		isOwn,
	]);

	// events
	const eventParams = useKeyBindParams("orderModalSwapPhones");
	const eventKeydown = useCallback(
		(event: KeyboardEvent) => {
			if (disabled) return;
			const keyEvent = event?.key;
			const target = event.target as HTMLInputElement;

			if (eventParams.isEvent(event)) {
				swapPhones();
				event.stopPropagation();
				event.preventDefault();
			}

			if (keyEvent === "Backspace") {
				debounceOpen(false);
				setOpenModal(false);
				setPhoneBlacklistModal(null);
			}

			if (
				keyEvent === "Tab" ||
				keyEvent === "Enter" ||
				keyEvent === "ArrowRight"
			) {
				const nextElem = focusToStreet();
				if (nextElem) target.blur();
				event.stopPropagation();
				event.preventDefault();
			}

			if (keyEvent === "ArrowLeft") {
				const nextElem = document.getElementById("order-save-id");
				if (nextElem) nextElem.focus();
				event.stopPropagation();
				event.preventDefault();
			}
		},
		[
			eventParams,
			swapPhones,
			debounceOpen,
			setOpenModal,
			focusToStreet,
			disabled,
		],
	);

	useLayoutEffect(() => {
		const elem = refInputPhone.current;
		if (elem) elem.setAttribute("id", "customer-phone-id");

		elem?.addEventListener("keydown", eventKeydown);
		return () => {
			elem?.removeEventListener("keydown", eventKeydown);
		};
	}, [eventKeydown, refInputPhone]);

	useKeyBindSettings("orderModalFocusLoyaltyCardInput", (event) => {
		if (disabled) return;
		const elem = refInputLoyaltyCard.current;
		if (elem) elem.focus();

		event.preventDefault();
		event.stopPropagation();
	});

	useKeyBindSettings("orderModalSwapPhones", (event) => {
		if (disabled) return;
		swapPhones();
		event.preventDefault();
		event.stopPropagation();
	});

	return (
		<StyledCustomer gap="8px" flex={{ flex: "1 0 100%" }}>
			<Wrap gap="0 8px">
				<StyledRow
					gap="8px"
					w={{ min: "144px" }}
					className="wrap"
					id={"main-phone-id"}
					tabIndex={0}
				>
					{showPhoneFromTransfer && (
						<StyledTextBox
							autoFocus
							autoComplete="off"
							inputRef={refInputPhone}
							tabIndex={0}
							min={0}
							type={"number"}
							disabled={disabled || !isOwn}
							error={mainPhoneError ? "error" : undefined}
							value={phoneFromTransfer}
							onBlur={() => {
								setTabAccept();
							}}
							onChange={() => {}}
						/>
					)}
					{!showPhoneFromTransfer && (
						<StyledTextBox
							autoFocus
							autoComplete="off"
							inputRef={refInputPhone}
							tabIndex={0}
							min={0}
							type={"number"}
							disabled={disabled || !isOwn}
							error={mainPhoneError ? "error" : undefined}
							placeholder={mainPhonePlaceholder}
							value={mainPhone}
							onBlur={() => {
								setTabAccept();
								handleSavePhone();
							}}
							onChange={(value) => {
								onChangePhone(value);
								if (latesTaxiService)
									setLatesTaxiService(false);
							}}
						/>
					)}
				</StyledRow>

				{showPhoneFromTransfer && (
					<StyledRow flex={{ flex: "0 1 100%" }} className="wrap">
						<StyledInputGroup
							disabled={disabled}
							onBlur={handleSave}
						>
							<StyledTextBox
								tabIndex={1}
								autoComplete="off"
								type={"string"}
								disabled={disabled}
								placeholder={placeholderUserName}
								value={""}
								onChange={() => {}}
							/>
							<InputBorders.InputBorders>
								{bonusBalanceType && (
									<Divider p="0 0 0 10px" gap="5px">
										<StyledBalance
											gap="0 5px"
											alignItems="center"
											flex={{ wrap: "nowrap" }}
										></StyledBalance>
									</Divider>
								)}
							</InputBorders.InputBorders>
						</StyledInputGroup>
					</StyledRow>
				)}
				{!showPhoneFromTransfer && (
					<StyledRow flex={{ flex: "0 1 100%" }} className="wrap">
						<StyledInputGroup
							disabled={disabled}
							onBlur={handleSave}
						>
							<StyledTextBox
								tabIndex={1}
								autoComplete="off"
								type={"string"}
								disabled={disabled}
								placeholder={placeholderUserName}
								value={userName}
								onChange={onChangeFullName}
							/>
							<InputBorders.InputBorders>
								{bonusBalanceType && (
									<Divider p="0 0 0 10px" gap="5px">
										<StyledBalance
											gap="0 5px"
											alignItems="center"
											flex={{ wrap: "nowrap" }}
											colors={colorBonusBalance}
										>
											<WalletIcon
												fill={colorBonusBalance}
											/>
											{userBonusBalance}
										</StyledBalance>
										{mainBalanceType && userMainBalance && (
											<Divider p="0 0 0 5px">
												<StyledBalance
													gap="0 5px"
													alignItems="center"
													flex={{ wrap: "nowrap" }}
													colors={colorMainBalance}
												>
													<WalletIcon
														fill={colorMainBalance}
													/>
													{userMainBalance}
												</StyledBalance>
											</Divider>
										)}
									</Divider>
								)}
							</InputBorders.InputBorders>
						</StyledInputGroup>
					</StyledRow>
				)}
				<StyledRow gap="8px" justify="end" w="150px" className="wrap">
					<Controller
						name="cardCounterpart"
						control={form.control}
						render={onRenderCardCounterpart}
					/>
				</StyledRow>
			</Wrap>
			<StyledButton
				disabled={userModalDisabled}
				icon={<Icon id="passenger" size={18} />}
				variant="primary"
				onClick={onToggleModal}
				width="clamp(27px, 3.7vh, 32px)"
				height="clamp(27px, 3.7vh, 32px)"
				minWidth="clamp(27px, 3.7vh, 32px)"
			/>
			{openModal && (
				<Passenger
					data={dataPassenger}
					onSave={onSubmitPassenger}
					onClose={onToggleModal}
				/>
			)}
			{phoneBlacklistModal && (
				<PhoneBlacklist
					createdAt={phoneBlacklistModal.createdAt}
					level={phoneBlacklistModal.level}
					description={phoneBlacklistModal.description}
					onSave={onConfirm}
					onClose={closeCurrentCard}
					footerDivider
				/>
			)}
		</StyledCustomer>
	);
};

export const CustomerMemo = memo(Customer);
export default Customer;
