import makeTable from "./makeTable";

const settingsTariffsMain = makeTable({
	allIds: [
		"name",
		"company",
		"taxiService",
		"tariffGroupType",
		"autoClasses",
	],
	defaultIds: [
		"name",
		"company",
		"taxiService",
		"tariffGroupType",
		"autoClasses",
	],
	i18tPath: (id) => `settings.tariffs.main.headerCells.${id}`,
	initialSortMethod: {
		column: "name",
		type: "desc",
	},
	initialWidths: {
		name: 200,
		company: 220,
		taxiService: 300,
		tariffGroupType: 220,
		autoClasses: 220,
	},
	initialResizable: {
		name: true,
		company: true,
		taxiService: true,
		tariffGroupType: true,
		autoClasses: true,
	},
	initialSortable: {
		name: true,
		company: true,
		taxiService: true,
		tariffGroupType: true,
		autoClasses: true,
	},
});

export default settingsTariffsMain;
