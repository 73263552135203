import React, { useMemo } from "react";

import Order, { OrderTransferType } from "../../../../../../../services/Order";
import Language from "../../../../../../../services/Language";
import CellContentRoot from "../CellContentRoot";

const backgroundColorByStatus = {
	[OrderTransferType.LOCAL]: "rgb(33, 150, 243)",
	[OrderTransferType.GLOBAL]: "rgb(76, 175, 80)",
	[OrderTransferType.OUTSIDE]: "rgb(252, 151, 57)",
};

const TransferFromTaxiServiceCellContent: React.FC<
	TransferFromTaxiServiceCellContent.Props
> = ({
	item,

	language,
}) => {
	const executorTaxiService = useMemo(
		() =>
			item.executorToOrder && item.executorToOrder?.length
				? item?.executorToOrder?.[0]?.executor?.taxiService?.id
				: undefined,
		[item],
	);

	const taxiService = useMemo(() => item.taxiService, [item]);
	const taxiServiceId = useMemo(() => taxiService?.id, [taxiService]);

	const isOwn = useMemo(() => item?.isOwn, [item]);

	const transfer = useMemo(() => item?.additionalFields?.transfer, [item]);

	const fromTaxiService = useMemo(
		() => transfer?.lastReceivedOrder?.taxiService,
		[transfer],
	);

	const currentTransferStage = useMemo(() => {
		const value = transfer?.sendProcess?.currentTransferStage;
		return value;
	}, [transfer]);

	const settlement = useMemo(() => {
		if (isOwn) return taxiService?.settlement?.[language];
		return fromTaxiService?.settlement?.[language];
	}, [fromTaxiService, language, isOwn, taxiService]);

	const company = useMemo(() => {
		if (isOwn) return taxiService?.company?.name?.[language];
		return fromTaxiService?.company?.name?.[language];
	}, [fromTaxiService, language, isOwn, taxiService]);

	const content = useMemo(() => {
		if (!fromTaxiService && !isOwn) return "";
		if (!settlement && company) {
			return company;
		}
		return `${company} (${settlement})`;
	}, [company, settlement, fromTaxiService, isOwn]);

	const backgroundColor = useMemo(() => {
		if (executorTaxiService === taxiServiceId && isOwn) return undefined;

		if (currentTransferStage === OrderTransferType.LOCAL) {
			return backgroundColorByStatus[OrderTransferType.LOCAL];
		}

		if (currentTransferStage === OrderTransferType.GLOBAL) {
			return backgroundColorByStatus[OrderTransferType.GLOBAL];
		}

		if (currentTransferStage === OrderTransferType.OUTSIDE) {
			return backgroundColorByStatus[OrderTransferType.OUTSIDE];
		}

		return undefined;
	}, [currentTransferStage, executorTaxiService, isOwn, taxiServiceId]);

	const textColor = useMemo(() => {
		if (executorTaxiService === taxiServiceId && isOwn) return undefined;

		return currentTransferStage &&
			currentTransferStage !== OrderTransferType.OUTSIDE
			? "#ffffff"
			: undefined;
	}, [currentTransferStage, executorTaxiService, isOwn, taxiServiceId]);

	return (
		<CellContentRoot
			align="center"
			maxedHeight
			maxedWidth
			backgroundColor={backgroundColor}
			textColor={textColor}
		>
			{content}
		</CellContentRoot>
	);
};

declare namespace TransferFromTaxiServiceCellContent {
	interface Props {
		item: Order.Model;

		language: Language;
	}
}

export default TransferFromTaxiServiceCellContent;
