import makeTable from "./makeTable";

const dispatcher = makeTable({
	allIds: [
		"name",
		"callSign",
		"phones",
		"role",
		"status",
		"createdAt",
		"company",
		"taxiService",
		"birthAt",
	],
	i18tPath: (id) => `mainPage.accounts.users.columns.${id}`,
	defaultIds: [
		"name",
		"callSign",
		"phones",
		"role",
		"status",
		"createdAt",
		"company",
		"taxiService",
		"birthAt",
	],
	initialSortMethod: {
		column: "status",
		type: "asc",
	},
	initialWidths: {
		name: 137,
		callSign: 100,
		phones: 150,
		role: 140,
		status: 140,
		createdAt: 155, // minimum for excel file format
		company: 140,
		taxiService: 220,
		birthAt: 155, // minimum for excel file format
	},
	initialResizable: {
		name: true,
		callSign: true,
		phones: true,
		role: true,
		status: true,
		createdAt: true,
		company: true,
		taxiService: true,
		birthAt: true,
	},
	initialSortable: {
		name: true,
		callSign: true,
		phones: true,
		role: true,
		status: true,
		createdAt: true,
		company: true,
		taxiService: true,
		birthAt: true,
	},
});

export default dispatcher;
