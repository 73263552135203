import stringToUndefined from "../../utils/stringToUndefined";
import Modal from "../../pages/MainPage/pages/Customers/tabs/Counterparties/components/Modal/components/Content/tabs/Accounts/Cards/components/Modal";
import { Employee } from "..";

export default function checkCardToRequest(
	card: Modal.Value,
): Record<string, any> {
	const toRequest: Record<string, any> = {
		id: card.id,
		active: card.active,
		code: {
			id: card.code.id,
			value: stringToUndefined(card.code.value),
			keyword: stringToUndefined(card.code.keyword),
		},
		isLoginOnlyByEmployee: card.isLoginOnlyByEmployee,
		additionalFields: {
			notes: stringToUndefined(card.additionalFields.notes),
			orderNotes: stringToUndefined(card.additionalFields.orderNotes),
			executorNotes: stringToUndefined(
				card.additionalFields.executorNotes,
			),
			tripLimit: card.additionalFields.tripLimit,
		},
		activationDate: card.activationDate,
		completionDate: card.completionDate,
		accessAllEmployeeToPersonalApp: card.accessAllEmployeeToPersonalApp,
		isAccessToCorporateAccount: card.isAccessToCorporateAccount,
		corporateLogin: card.corporateLogin ?? undefined,
		corporatePassword: card.corporatePassword ?? undefined,
		otherFileIds: card.otherFiles?.map(({ id }) => id) ?? [],
		personalFileIds: card.personalFiles?.map(({ id }) => id) ?? [],
		employees: card.employees.map(Employee.toRequest),
		employeeDeleteIds: card.employeeDeleteIds || [],
	};

	return toRequest;
}
