import React, { Dispatch, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Float, Icon, InputGroup, Tabs, theme } from "uikit";

import Map from "../../../../../../../../../../redux/services/Map";
import { useTypedSelector } from "../../../../../../../../../../redux/store";
import SearchIconBorders from "../../../../../../../../../../components/SearchIconBorders";
import SearchTextBox from "../../../../../../../../../../components/SearchTextBox";
import CompaniesAndTaxiServicesFilter from "../../../../../../../../../../components/CompaniesAndTaxiServicesFilter";
import {
	StyledGrid,
	LanguageSelect,
} from "../../../../../../../../../../components/common";
import Button from "../../../../../components/Button";
import {
	generateAccessName,
	AccessKey,
	hasAccess,
} from "../../../../../../../../../../access";
import {
	TabKeys,
	ARR_ACCESS_PART_KEY,
} from "../../../../../../../../constants/access";

import { types } from "./constants";
import InternalPopover from "./components/Popover";

const Filters: React.FC<Filters.Props> = ({
	companies,
	taxiServices,
	language,
	dataType,
	search,
	additionalFilters,

	onChangeCompanies,
	onChangeTaxiServices,
	onChangeLanguage,
	onChangeDataType,
	onChangeSearch,
	onChangeAdditionalFilters,

	onExport,
	onImport,
}) => {
	const { t } = useTranslation();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);

	const [openAdditionalFilters, setOpenAdditionalFilters] = useState(false);

	const AccessBtnGroup = useMemo(() => {
		const ACCESS_TAB = ARR_ACCESS_PART_KEY[TabKeys.OBJECTS];

		const retval = {
			import: hasAccess(
				generateAccessName(...ACCESS_TAB, AccessKey.IMPORT),
				personalRole,
			),

			export: hasAccess(
				generateAccessName(...ACCESS_TAB, AccessKey.EXPORT),
				personalRole,
			),
		};

		return retval;
	}, [personalRole]);

	return (
		<StyledGrid
			areas=""
			gap="10px"
			alignItems="center"
			columns="200px 200px 130px auto 270px auto auto auto"
		>
			<CompaniesAndTaxiServicesFilter
				companies={companies}
				taxiServices={taxiServices}
				language={language}
				onChangeCompanies={onChangeCompanies}
				onChangeTaxiServices={onChangeTaxiServices}
			/>

			<LanguageSelect
				onSelect={(option) => onChangeLanguage(option.value)}
				value={language}
			/>
			<Tabs
				variant="monoblock"
				options={types}
				value={dataType}
				onChangeSelectedOption={(option) =>
					onChangeDataType(option.value)
				}
			/>
			<InputGroup.InputGroup sizes="auto 1fr">
				<SearchIconBorders>
					<Icon
						id="search2"
						size={16}
						colors={[theme.colors.disabled_text]}
					/>
				</SearchIconBorders>
				<SearchTextBox
					placeholder={`${
						t(
							"pages.preferencesPages.screenDirectory.objects.header.filters.str200",
						) ?? ""
					}...`}
					value={search}
					onChange={onChangeSearch}
				/>
			</InputGroup.InputGroup>
			<Float.Tracker id="filters">
				<Button
					variant="secondary"
					icon={<Icon id="filters" size={20} />}
					onClick={() =>
						openAdditionalFilters
							? undefined
							: setOpenAdditionalFilters(true)
					}
				/>
			</Float.Tracker>
			{AccessBtnGroup.export && (
				<Button
					variant="secondary"
					icon={<Icon id="export-page" size={20} />}
					disabled={dataType === "object-groups"}
					onClick={onExport}
				/>
			)}

			{AccessBtnGroup.import && (
				<Button
					variant="secondary"
					icon={<Icon id="import-page" size={20} />}
					disabled={dataType === "object-groups"}
					onClick={onImport}
				/>
			)}
			<InternalPopover
				value={additionalFilters}
				open={openAdditionalFilters}
				onClose={() => setOpenAdditionalFilters(false)}
				onChange={onChangeAdditionalFilters}
			/>
		</StyledGrid>
	);
};

declare namespace Filters {
	interface Props extends CompaniesAndTaxiServicesFilter.Props {
		language: Map.Language;
		dataType: "objects" | "object-groups";
		search: string;
		additionalFilters: InternalPopover.Value;

		onChangeLanguage: Dispatch<Map.Language>;
		onChangeDataType: Dispatch<"objects" | "object-groups">;
		onChangeSearch: Dispatch<string>;
		onChangeAdditionalFilters: Dispatch<InternalPopover.Value>;

		onExport: () => void;
		onImport: () => void;
	}

	namespace Popover {
		type Value = InternalPopover.Value;
	}
}

export default Filters;
