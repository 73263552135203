/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import * as ModelEvent from "@node-elion/syncron";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import { createObjectLanguageNamesFromObject } from "../../assets/languages/langs";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import { Base, Card, TaxiServiceOld as TaxiService, Language } from "..";

const logger = createLogger({ name: "CarColor" });

class CarColor extends Base {
	// Its needed due to typescript bundler conflict
	private static _Card: Card | null = null;

	public static get Card() {
		if (this._Card) return this._Card;

		this._Card = new Card((prpc) => prpc.theirsModel.color.card);

		return this._Card;
	}

	static fromResponse(data: any): CarColor.Model {
		return {
			id: data?.id,

			taxiServices: data?.colorToTaxiServices?.map((colorToTaxiService) =>
				TaxiService.fromResponse(colorToTaxiService.taxiService),
			),
			name: createObjectLanguageNamesFromObject(data?.name),

			value: data?.value,

			active: data?.active,

			updatedAt: data?.updatedAt,
			createdAt: data?.createdAt,
			deletedAt: data?.deletedAt,
		};
	}

	static toRequest(model: CarColor.Model.New | CarColor.Model.Modified): any {
		return {
			taxiServiceIds: model.taxiServiceIds,
			name: createObjectLanguageNamesFromObject(model.name),

			value: model.value,
			active: model.active,
		};
	}

	public static async store(object: CarColor.Model.New) {
		this.request((prpc) =>
			prpc.theirsModel.color.create(CarColor.toRequest(object)),
		);
	}

	public static async update(object: CarColor.Model.Modified) {
		this.request((prpc) =>
			prpc.theirsModel.color.update(
				object.id,
				CarColor.toRequest(object),
			),
		);
	}

	public static async destroy(id: number[] | number) {
		if (Array.isArray(id))
			await Promise.all(id.map((id) => this.destroyOne(id)));
		else await this.destroyOne(id);
	}

	public static async subscribe(
		options: CarColor.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<CarColor.Model>,
	): Promise<Subscription<CarColor.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,

					models: state.models.map(this.fromResponse),
				});
			},
		});
		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.color.subscribe({
					params: this.optionsToRequest(options),
					ping: () => true,
					onEvent: (events) => {
						modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						logger.error(error);
					},
				}),
			{ name: "CarColor.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: CarColor.SubscribeOptions) =>
				subscription.update(this.optionsToRequest(options)),
		} as Subscription<CarColor.SubscribeOptions>;
	}

	private static async destroyOne(id: number) {
		this.request((prpc) => prpc.theirsModel.color.delete(id));
	}

	private static optionsToRequest(options: CarColor.SubscribeOptions) {
		return {
			offset: options.offset,
			limit: options.limit,
			order: options.order,

			taxiServiceIds: options.taxiServiceIds,

			query: options.query,
			active: options.active,
			lang: options.language,
		};
	}
}

declare namespace CarColor {
	export interface Model {
		id: number;

		taxiServices?: TaxiService.Model[];

		name: Record<Language, string>;
		value: number;

		active: boolean;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<CarColor.Model> {
		taxiServiceIds?: number[];
		language?: Language;
		active?: boolean;
	}

	namespace Model {
		type NonEditablePropertyName =
			| "id"
			| "createdAt"
			| "updatedAt"
			| "deletedAt";

		type ModifiedPropertyName = "taxiServices";

		interface ModifiedProperties {
			taxiServiceIds: number[];
		}

		type New = Omit<Model, NonEditablePropertyName | ModifiedPropertyName> &
			ModifiedProperties;

		type Modified = Partial<
			Omit<Model, NonEditablePropertyName | ModifiedPropertyName> &
				ModifiedProperties
		> &
			Pick<Model, "id">;
	}
}

export default CarColor;
