import { react } from "uikit";
import { Dispatch } from "react";

import CompaniesAndTaxiServices from ".";

interface Context {
	value: CompaniesAndTaxiServices.Value;
	setError: Dispatch<boolean>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isValid = !!this.context?.value.taxiServiceIds.length;

		if (!isValid) this.context?.setError(true);

		return isValid;
	}
}
