import React from "react";

import TaxiServiceCellContent from "../components/TransferToTaxiServiceCellContent";

import base from "./base";

import { Column, ColumnId } from ".";

function transferToTaxiService({ width, language, onResize }: Column.Options) {
	return base(
		ColumnId.TransferToTaxiService,
		(item) => <TaxiServiceCellContent item={item} language={language} />,
		{
			width,

			onResize: onResize as (
				columnWidth?: number | undefined,
				dataKey?: string | undefined,
			) => void,
		},
	);
}

export default transferToTaxiService;
