import { RefObject } from "react";
import { react } from "uikit";

import {
	CarBaseTypeSelect,
	CarBrandSelect,
	CarModelSelect,
	CarBodyTypeSelect,
} from "./components";

interface Context {
	carBaseTypeSelectRef: RefObject<CarBaseTypeSelect.Ref>;
	carBrandSelectRef: RefObject<CarBrandSelect.Ref>;
	carModelSelectRef: RefObject<CarModelSelect.Ref>;
	carBodyTypeSelectRef: RefObject<CarBodyTypeSelect.Ref>;
}

export default class Controller extends react.ControllerBase<Context> {
	validate() {
		const isCarBaseTypeSelectValue =
			this.context?.carBaseTypeSelectRef.current?.validate();
		const isCarBrandSelectValue =
			this.context?.carBrandSelectRef.current?.validate();
		const isCarModelSelectValue =
			this.context?.carModelSelectRef.current?.validate();
		const isCarBodyTypeSelectValue =
			this.context?.carBodyTypeSelectRef.current?.validate();

		return (
			isCarBaseTypeSelectValue &&
			isCarBrandSelectValue &&
			isCarModelSelectValue &&
			isCarBodyTypeSelectValue
		);
	}
}
