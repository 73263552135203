import React, {
	Dispatch,
	memo,
	useCallback,
	useMemo,
	useState,
	useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { CarColor } from "../../../../services";
import { useTypedSelector } from "../../../../redux/store";
import useModelSubscribe from "../../../../hooks/useModelSubscribe2";
import MultiSelectWithModalBase, {
	MultiSelectWithModal,
} from "../MultiSelectWithModal";
import { StyledP, StyledRow, StyledColumn } from "../..";

const EqualWidthColumn = styled(StyledColumn)`
	flex: 1;
`;

const MultiSelectCarColorWithModal: React.FC<
	MultiSelectCarColorWithModal.Props
> = ({
	value,
	onChange,
	disabled,
	titleText,
	required,
	modalSetting,
	sort,

	error = "",
	subscribeOptions = {},
}) => {
	const { t } = useTranslation();
	const language = useTypedSelector((state) => state.session.language);

	const _subscribeOptions = useMemo(
		() => subscribeOptions,
		[subscribeOptions],
	);

	const modelData = useModelSubscribe(_subscribeOptions, CarColor);

	const [errorInternal, setErrorInternal] = useState<string | boolean>("");
	useEffect(() => {
		setErrorInternal(error);
	}, [error]);

	const items = useMemo(() => modelData.models, [modelData.models]);

	const selectOptions = useMemo(
		() =>
			items.map((item) => ({
				key: item.id,
				label: item.name?.[language] ?? "Unknown",
				value: item.id,
				name: item.name?.[language] ?? "Unknown",
				data: item,
			})),
		[items, language],
	);

	const selected = useMemo<MultiSelectWithModalBase.Value>(
		() =>
			value ? selectOptions.filter((item) => value === item.value) : [],

		[selectOptions, value],
	);

	const selectOnChange = useCallback(
		(newValue) => {
			setErrorInternal("");
			const ids = newValue.map((item) => item.value);
			onChange(ids?.length > 0 ? ids[0] : undefined);
		},
		[onChange],
	);

	return (
		<StyledRow gap="7px" alignItems="center">
			<EqualWidthColumn gap="7px">
				<StyledP>{t(titleText) ?? ""}</StyledP>
				<MultiSelectWithModal
					value={selected}
					onChange={selectOnChange}
					options={selectOptions}
					error={errorInternal}
					disabled={disabled}
					title={t(titleText) ?? ""}
					selectOnlyOne={true}
					required={required}
					pullUpItemInsideArray
					hiddenButton={false}
					modalSetting={modalSetting}
					sort={{
						show: true,
						active: true,
						...(sort || {}),
					}}
				/>
			</EqualWidthColumn>
		</StyledRow>
	);
};

declare namespace MultiSelectCarColorWithModal {
	interface Props extends MultiSelectWithModalBase.Setting {
		titleText: string;
		modalSetting?: MultiSelectWithModalBase.ListSelectProps["modalSetting"];

		value: Value;
		onChange: Dispatch<Value>;

		disabled: boolean;
		error?: string | boolean;
		subscribeOptions?: CarColor.SubscribeOptions;
	}

	type Value = number | undefined;
}

export default memo(MultiSelectCarColorWithModal);
