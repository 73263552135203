/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import { defaults } from "lodash";

import ModelService from "../../redux/services/ModelService";
import schedules from "../../redux/reducers/schedules";
import { getPRPC } from "../../hooks/usePRPC";
import createRPCQuery from "../../utils/createRPCQuery.util";
import ServiceSubscribeOptionsBase from "../../types/ServiceSubscribeOptionsBase";

import { destroyOne } from "./utils";

class Schedule extends ModelService<
	Schedule.SubscribeOptions,
	Schedule.Model,
	"schedules"
>(schedules, (state) => state.schedules) {
	static defaultSharedOptions: Schedule.SharedOptions = {
		deprecate: true,
	};

	static fromResponse(data: any): Schedule.Model {
		return {
			id: data.id,

			scheduleId: data.scheduleId,

			monday: data.monday,
			tuesday: data.tuesday,
			wednesday: data.wednesday,
			thursday: data.thursday,
			friday: data.friday,
			saturday: data.saturday,
			sunday: data.sunday,

			startTime: data.startTime,
			endTime: data.endTime,

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	static toRequest(model: Schedule.Model.New | Schedule.Model.Modified): any {
		return {
			scheduleId: model.scheduleId,

			monday: model.monday,
			tuesday: model.tuesday,
			wednesday: model.wednesday,
			thursday: model.thursday,
			friday: model.friday,
			saturday: model.saturday,
			sunday: model.sunday,

			startTime: model.startTime,
			endTime: model.endTime,
		};
	}

	static async store(
		object: Schedule.Model.New,
		options?: Schedule.StoreOptions,
	) {
		options = defaults(options, Schedule.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return null;

		const response = await createRPCQuery(() =>
			prpc.theirsModel.schedule.week.create(Schedule.toRequest(object)),
		);

		if (options.deprecate) Schedule.deprecateAll();

		return Schedule.fromResponse(response);
	}

	static async update(
		object: Schedule.Model.Modified,
		options?: Schedule.UpdateOptions,
	) {
		options = defaults(options, Schedule.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return null;

		const response = await createRPCQuery(() =>
			prpc.theirsModel.schedule.week.update(
				object.id,
				Schedule.toRequest(object),
			),
		);

		if (options.deprecate) Schedule.deprecateAll();

		return Schedule.fromResponse(response);
	}

	static async destroy(
		id: number[] | number,
		options?: Schedule.DestroyOptions,
	) {
		options = defaults(options, Schedule.defaultSharedOptions);

		const prpc = getPRPC();

		if (!prpc) return;

		if (Array.isArray(id))
			await Promise.all(id.map((id) => destroyOne(id)));
		else await destroyOne(id);

		if (options.deprecate) Schedule.deprecateAll();
	}

	static Global = {
		async index(options: Schedule.SubscribeOptions) {
			const prpc = getPRPC();

			if (!prpc) return null;

			const result = await createRPCQuery(() =>
				prpc.theirsModel.schedule.week.getAll({
					limit: options.limit,
					offset: options.offset,
					query: options.query,
					order: options.order,

					scheduleIds: options.scheduleIds,
				}),
			);

			return {
				cache: result.items.map(Schedule.fromResponse),
				offset: result.pagination.offset,
				limit: result.pagination.count,
				total: result.pagination.count,
				deprecated: false,
			};
		},
	};
}

declare namespace Schedule {
	type OpenAndCloseType = "auto" | "manually";

	interface Model extends Record<Model.Day, boolean> {
		id: number;

		scheduleId?: number;

		startTime: number;
		endTime: number;

		createdAt: string;
		updatedAt: string;
		deletedAt: string | null;
	}

	interface SubscribeOptions
		extends ServiceSubscribeOptionsBase<Schedule.Model> {
		scheduleIds?: number[];
	}

	interface SharedOptions {
		deprecate?: boolean;
	}

	interface StoreOptions extends SharedOptions {}
	interface UpdateOptions extends SharedOptions {}
	interface DestroyOptions extends SharedOptions {}

	namespace Model {
		type Day =
			| "monday"
			| "tuesday"
			| "wednesday"
			| "thursday"
			| "friday"
			| "saturday"
			| "sunday";

		type NonEditablePropertyNames =
			| "id"
			| "createdAt"
			| "updatedAt"
			| "deletedAt";
		type New = Omit<Model, NonEditablePropertyNames>;
		type Modified = Partial<Omit<Model, NonEditablePropertyNames>> &
			Pick<Model, "id">;
	}
}

export default Schedule;
