import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Executor } from "../services";
import { ExecutorStatus } from "../services/Executor";

const useExecutorStatus = (): useExecutorStatus.Props => {
	const { t } = useTranslation();
	const executorStatuses = useMemo<
		useExecutorStatus.Props["executorStatuses"]
	>(
		() => ({
			busy: t("orderPageWidgets.parking.parkingTable.str202") ?? "",
			dinner: t("orderPageWidgets.parking.parkingTable.str203") ?? "",
			home: t("orderPageWidgets.parking.parkingTable.str204") ?? "",
			on_order: t("orderPageWidgets.parking.parkingTable.str205") ?? "",
			available: t("orderPageWidgets.parking.parkingTable.str206") ?? "",
			closed: t("orderPageWidgets.parking.parkingTable.str207") ?? "",
			ownOrder: t("orderPageWidgets.parking.parkingTable.str208") ?? "",
			debt: t("orderPageWidgets.executors.str206") ?? "",
		}),
		[t],
	);

	const getStatus = useCallback<useExecutorStatus.Props["getStatus"]>(
		(data) => {
			if (!data?.status) return "";
			const { debt, status } = data;

			const onOwnOrder = data?.executorToOrders?.find(
				({ order }) => order?.source === "executor" && !order?.closedAt,
			);

			const orderExist = !!data?.executorToOrders?.length;

			if (onOwnOrder) {
				const onOrder = `${
					orderExist ? `${executorStatuses.on_order} - ` : ""
				}${executorStatuses.ownOrder}`;

				if (data?.status === ExecutorStatus.BUSY) {
					return `${onOrder}(${executorStatuses.busy}${
						debt ? `, ${executorStatuses.debt}` : ""
					})`;
				}
				if (status === ExecutorStatus.DINNER) {
					return `${onOrder} (${executorStatuses.dinner}${
						debt ? `, ${executorStatuses.debt}` : ""
					})`;
				}
				if (status === ExecutorStatus.HOME) {
					return `${onOrder} (${executorStatuses.home}${
						debt ? `, ${executorStatuses.debt}` : ""
					})`;
				}
				if (status === ExecutorStatus.ON_ORDER) {
					return `${onOrder} ${
						debt ? `(${executorStatuses.debt})` : ""
					}`;
				}
			}

			if (debt) {
				if (data?.status === ExecutorStatus.BUSY) {
					return `${
						orderExist
							? `${executorStatuses.on_order}`
							: executorStatuses.busy
					} (${
						orderExist
							? `${executorStatuses.busy}, `
							: `${executorStatuses.on_order}, `
					}${executorStatuses.debt})`;
				}
				if (status === ExecutorStatus.DINNER) {
					return `${
						orderExist
							? executorStatuses.on_order
							: executorStatuses.dinner
					} (${
						orderExist
							? `${executorStatuses.on_order}, `
							: `${executorStatuses.dinner}, `
					}${executorStatuses.debt})`;
				}
				if (status === ExecutorStatus.HOME) {
					return `${
						orderExist
							? executorStatuses.on_order
							: executorStatuses.home
					} (${
						orderExist
							? `${executorStatuses.on_order}, `
							: `${executorStatuses.home}, `
					}${executorStatuses.debt})`;
				}
				if (status === ExecutorStatus.ON_ORDER) {
					return `${executorStatuses.on_order} (${executorStatuses.debt})`;
				}

				if (status === ExecutorStatus.AVAILABLE) {
					return `${executorStatuses.available} (${executorStatuses.debt})`;
				}
			}

			if (data?.status === ExecutorStatus.BUSY) {
				if (orderExist) {
					return `${executorStatuses.on_order} (${executorStatuses.busy}) `;
				}
				return executorStatuses.busy;
			}

			if (status === ExecutorStatus.DINNER) {
				if (orderExist) {
					return `${executorStatuses.on_order} (${executorStatuses.dinner}) `;
				}
				return executorStatuses.dinner;
			}

			if (status === ExecutorStatus.HOME) {
				if (orderExist) {
					return `${executorStatuses.on_order} (${executorStatuses.home}) `;
				}
				return executorStatuses.home;
			}

			if (status === ExecutorStatus.ON_ORDER) {
				return executorStatuses.on_order;
			}

			return executorStatuses[status] || "";
		},
		[executorStatuses],
	);

	return useMemo(
		() => ({ executorStatuses, getStatus }),
		[executorStatuses, getStatus],
	);
};

declare namespace useExecutorStatus {
	interface Props {
		executorStatuses: {
			busy: string;
			dinner: string;
			home: string;
			on_order: string;
			available: string;
			closed: string;
			ownOrder: string;
			debt: string;
		};

		getStatus: (data?: Executor.Model) => string;
	}
}

export default useExecutorStatus;
