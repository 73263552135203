import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { theme } from "uikit";

import { OrderFormProps } from "../../types/FormProps";
import { Primary, Green } from "../styled";
import { StyledRow } from "../../../../../../../../components/common";
import { useTypedSelector } from "../../../../../../../../redux/store";

const Car: React.FC<OrderFormProps> = ({ tab }) => {
	const { t } = useTranslation();
	const language = useTypedSelector((state) => state.session.language);

	const isOwn = useMemo(() => tab?.form?.isOwn, [tab?.form?.isOwn]);

	const sip = useMemo(() => tab.form?.executorSip, [tab.form?.executorSip]);
	const phone = useMemo(
		() => tab.form?.executorPhone,
		[tab.form?.executorPhone],
	);
	const executorCompany = useMemo(
		() => tab?.form?.executorCompany,
		[tab?.form?.executorCompany],
	);
	const executorTaxiService = useMemo(
		() => tab?.form?.executorTaxiService,
		[tab?.form?.executorTaxiService],
	);

	const executorTaxiServiceId = useMemo(
		() => tab?.form?.executorTaxiServiceId,
		[tab?.form?.executorTaxiServiceId],
	);

	const taxiServiceId = useMemo(
		() => tab?.form?.taxiServiceId,
		[tab?.form?.taxiServiceId],
	);

	const taxiServiceName = useMemo(
		() => tab?.form?.taxiServiceName,
		[tab?.form?.taxiServiceName],
	);

	const companyName = useMemo(
		() => tab?.form?.companyName,
		[tab?.form?.companyName],
	);

	const name = useMemo(
		() => tab.form?.executorName,
		[tab.form?.executorName],
	);

	const carName = useMemo(
		() => tab.form?.executorCarName,
		[tab.form?.executorCarName],
	);

	const transfer = useMemo(() => tab.form?.transfer, [tab.form?.transfer]);

	const text = useMemo(() => {
		if (!isOwn) {
			const list = [
				taxiServiceId === executorTaxiServiceId,
				taxiServiceName === executorTaxiService,
				companyName === executorCompany,
			];

			if (list.every((item) => item === true)) {
				return [sip, name, phone, carName].filter(Boolean).join(", ");
			}

			const company = `${executorCompany || ""} ${
				executorTaxiService ? `(${executorTaxiService})` : ""
			}`.trim();
			return [phone, carName, company].filter(Boolean).join(", ");
		}

		if (transfer?.executor && transfer && !sip && isOwn) {
			const number = transfer.executor?.carNumber || "";
			const model = transfer.executor?.carModel || "";
			const brand = transfer.executor?.carBrand || "";
			const color = transfer.executor?.carColor?.[language];
			const label = `${brand} ${model}${
				number ? `(${number})` : ""
			}, ${color}`;
			const executorTaxiServiceName =
				transfer?.executor?.executorTaxiServiceName?.[language] || "";
			const executorCompanyName =
				transfer?.executor?.executorCompanyName?.[language] || "";

			const company = `${executorCompanyName} (${executorTaxiServiceName})`;

			const executorPhone =
				transfer?.lastReceivedOrder?.executorToOrder?.at?.(0)?.executor
					?.person?.phones?.[0]?.number || "";

			return [executorPhone, label, company].filter(Boolean).join(", ");
		}

		return [sip, name, phone, carName].filter(Boolean).join(", ");
	}, [
		carName,
		companyName,
		executorCompany,
		executorTaxiService,
		executorTaxiServiceId,
		isOwn,
		language,
		name,
		phone,
		sip,
		taxiServiceId,
		taxiServiceName,
		transfer,
	]);

	return (
		<StyledRow
			p="0 12px"
			h="28px"
			bgC={`${theme.colors.success}26`}
			alignItems="center"
			gap="14px"
		>
			<Primary>{`${t([`orderPage.order_form.car`, "Car"])}:`}</Primary>
			<Green style={{ fontWeight: 600 }}>{text}</Green>
		</StyledRow>
	);
};

export default Car;
