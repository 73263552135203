import SubscriptionPool from "../../../redux/services/SubscriptionPool";
import createLogger from "../../../utils/logger.util";
import { Base } from "../..";

const logger = createLogger({ name: "SIPToDispatcherEvent" });

class SIPToDispatcherEvent extends Base {
	public static async subscribe({ sipToDispatcherId, onEvent, onError }) {
		try {
			const res = await SubscriptionPool.add(
				(prpc) =>
					prpc.theirsModel.sipToDispatcher.events.subscribe({
						onEvent,
						sipToDispatcherId,
						onError,
					}),
				{ name: "SIPToDispatcherEvent.subscribe" },
			);
			return res;
		} catch (error) {
			return logger.error(error);
		}
	}
}

export default SIPToDispatcherEvent;
