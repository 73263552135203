import React, { useMemo } from "react";
import { theme } from "uikit";

import Order, {
	OrderReminderStatus,
} from "../../../../../../../services/Order";
import CellContentRoot from "../CellContentRoot";
import { Language } from "../../../../../../../services";

const backgroundColorByStatus = {
	offer: "#51C3F7",
	executing: "rgb(76, 175, 80)",
	inactive: "#ebebeb",
	send: "#FFA500",
	dispatcherBGC: theme.colors.disabled_text,
	dispatcherText: theme.colors.white,
};

const ExecutorCallSignCellContent: React.FC<
	ExecutorCallSignCellContent.Props
> = ({ item }) => {
	const lastReceivedOrder = useMemo(() => {
		const value = item?.additionalFields?.transfer?.lastReceivedOrder;
		return value;
	}, [item]);

	const executorsFromTransfer = useMemo(() => {
		if (
			lastReceivedOrder?.executorToOrder &&
			lastReceivedOrder?.executorToOrder?.length
		) {
			return lastReceivedOrder?.executorToOrder;
		}
		return [];
	}, [lastReceivedOrder?.executorToOrder]);

	const executorFromTransfer = useMemo(() => {
		if (
			lastReceivedOrder?.executorToOrder &&
			lastReceivedOrder?.executorToOrder?.length
		) {
			return lastReceivedOrder?.executorToOrder?.[0]?.executor;
		}
		return lastReceivedOrder?.offer?.executor;
	}, [
		lastReceivedOrder?.executorToOrder,
		lastReceivedOrder?.offer?.executor,
	]);

	const executor = useMemo(() => {
		if (item?.executorToOrder && item?.executorToOrder?.length) {
			return item?.executorToOrder?.[0]?.executor;
		}
		return item.offer?.executor;
	}, [item?.executorToOrder, item.offer?.executor]);

	const isOwn = useMemo(() => item?.isOwn, [item]);

	const content = useMemo(() => {
		if (isOwn && executorFromTransfer) return "";
		return executor?.callSign || "";
	}, [executor?.callSign, executorFromTransfer, isOwn]);

	const isDispatcher = useMemo(
		() =>
			item?.executorToOrder?.find(
				(item) =>
					item?.executor.id === executor?.id &&
					item?.additionalFields?.acceptedByDispatcher?.active,
			),
		[executor?.id, item?.executorToOrder],
	);

	const isDispatcherFrom = useMemo(
		() =>
			executorsFromTransfer?.find(
				(item) =>
					item?.executor?.id === executorFromTransfer?.id &&
					item?.additionalFields?.acceptedByDispatcher?.active,
			),
		[executorsFromTransfer, executorFromTransfer?.id],
	);

	const backgroundColor = useMemo(() => {
		if (lastReceivedOrder && isOwn && executorFromTransfer) {
			// if (lastReceivedOrder?.executorReminderStatus) {
			// 	const { status } = lastReceivedOrder.executorReminderStatus;
			// 	if (status && OrderReminderStatus.SEND === status) {
			// 		return backgroundColorByStatus.send;
			// 	}
			// 	if (lastReceivedOrder?.executingStages?.accepted) {
			// 		return backgroundColorByStatus.executing;
			// 	}
			// }

			// if (
			// 	(isDispatcherFrom &&
			// 		lastReceivedOrder?.executingStages?.assigned &&
			// 		!lastReceivedOrder?.executingStages?.accepted) ||
			// 	(isDispatcherFrom &&
			// 		!lastReceivedOrder?.offer?.executor?.online &&
			// 		!lastReceivedOrder?.executingStages?.accepted)
			// ) {
			// 	return backgroundColorByStatus.dispatcherBGC;
			// }

			// if (lastReceivedOrder?.executingStages?.assigned) {
			// 	return backgroundColorByStatus.inactive;
			// }

			// if (lastReceivedOrder?.offer) {
			// 	return lastReceivedOrder?.offer?.executor?.online
			// 		? backgroundColorByStatus.offer
			// 		: backgroundColorByStatus.inactive;
			// }

			return undefined;
		}

		if (item?.executorReminderStatus) {
			const { status } = item.executorReminderStatus;
			if (status && OrderReminderStatus.SEND === status) {
				return backgroundColorByStatus.send;
			}
		}

		if (item.executingStages?.accepted) {
			return backgroundColorByStatus.executing;
		}

		if (
			(isDispatcher &&
				item.executingStages?.assigned &&
				!item.executingStages?.accepted) ||
			(isDispatcher &&
				!item?.offer?.executor?.online &&
				!item.executingStages?.accepted)
		) {
			return backgroundColorByStatus.dispatcherBGC;
		}

		if (item.executingStages?.assigned) {
			return backgroundColorByStatus.inactive;
		}

		if (item.offer) {
			return item.offer?.executor?.online
				? backgroundColorByStatus.offer
				: backgroundColorByStatus.inactive;
		}

		return undefined;
	}, [
		lastReceivedOrder,
		isOwn,
		executorFromTransfer,
		item.executorReminderStatus,
		item.executingStages?.accepted,
		item.executingStages?.assigned,
		item.offer,
		isDispatcher,
	]);

	const textColor = useMemo(() => {
		if (isDispatcherFrom && lastReceivedOrder && isOwn) {
			return undefined;
		}

		return (isDispatcher && item.executingStages?.assigned) ||
			(isDispatcher && !item?.offer?.executor?.online)
			? theme.colors.white
			: undefined;
	}, [
		isDispatcher,
		isDispatcherFrom,
		item.executingStages?.assigned,
		item?.offer?.executor?.online,
		lastReceivedOrder,
		isOwn,
	]);

	return (
		<CellContentRoot
			align="center"
			backgroundColor={backgroundColor}
			textColor={textColor}
			maxedHeight
			maxedWidth
		>
			{content}
		</CellContentRoot>
	);
};

declare namespace ExecutorCallSignCellContent {
	interface Props {
		item: Order.Model;
		language: Language;
	}
}

export default ExecutorCallSignCellContent;
