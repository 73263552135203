import { HeaderCell as HeaderCellBase } from "rsuite-table";
import styled from "styled-components";
import { theme } from "uikit";

const HeaderCell = styled(HeaderCellBase)`
	.rs-table-cell-content,
	.rs-table-cell {
		background: ${theme.colors.white} !important;

		font-family: "Lato";
		font-style: normal;
		font-weight: 700;
		font-size: 12px;
		line-height: 14px;
		color: ${theme.colors.primary};
	}

	.rs-table-cell-content {
		padding: 0 24px;
	}

	&:first-child {
		.rs-table-cell-content {
			padding-left: 24px;
		}
	}

	&:last-child {
		.rs-table-cell-content {
			padding-right: 24px;
		}
	}
`;

export default HeaderCell;
