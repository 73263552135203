/* eslint-disable no-shadow */

import React, { Dispatch, memo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Column as TableColumn } from "rsuite-table";
import { Column, theme, ToggleButton } from "uikit";
import { clone } from "lodash";
import styled from "styled-components";

import CarModel from "../../../../../../../../services/CarModel";
import TableSortMethod from "../../../../../../../../types/SortMethod";
import Cell from "../Cell";
import Table from "../Table";
import HeaderCell from "../HeaderCell";

interface StyledCellProps {
	active?: boolean;
}

const StyledCell = styled.div<StyledCellProps>`
	color: ${({ active }) =>
		active ? theme.colors.primary : "lightgray"} !important;
	${theme.longWord}
`;

// const tableHeight = 700;
// const triggerPadding = 10; // items

declare namespace Content {
	type SortMethod = TableSortMethod<keyof Pick<CarModel.Model, "name">>;
	export interface Props {
		disabled: boolean;
		models: CarModel.Model[];

		selected: number[];
		onChangeSelected: Dispatch<number[]>;

		sort: SortMethod;
		onSortChange: (value: SortMethod) => void;

		loading: boolean;
		// loadMore: (direction: "prev" | "next") => void;

		toggleModel: (id: number, active: boolean) => void;
		onEdit: (rowDataId: number) => void;
		// onDelete: (id) => void;
	}
}

const Content = memo(
	({
		disabled,
		models,

		selected,
		onChangeSelected,

		sort,
		onSortChange,

		loading,
		// loadMore,

		toggleModel,
		onEdit,
	}: // onDelete,
	Content.Props) => {
		const handleSelect = useCallback(
			(rowData) => {
				if (!rowData.native) {
					const newSelected = clone(selected);

					if (newSelected.includes(rowData.id)) {
						newSelected.splice(newSelected.indexOf(rowData.id), 1);
					} else {
						newSelected.push(rowData.id);
					}

					onChangeSelected(newSelected);
				}
			},
			[onChangeSelected, selected],
		);

		const { t } = useTranslation();
		return (
			<div>
				<Table<CarModel.Model, any>
					data={models}
					virtualized
					headerHeight={44}
					rowHeight={44}
					fillHeight
					sortColumn={sort.column}
					sortType={sort.type}
					onSortColumn={(column, type) => {
						onSortChange({
							column: column as typeof sort.column,
							type,
						});
					}}
					loading={loading}
					shouldUpdateScroll={false}
					rowClassName={(rowData) =>
						rowData && selected.includes(rowData.id)
							? "selected"
							: ""
					}
				>
					<TableColumn width={100}>
						<HeaderCell verticalAlign="middle" align="center">
							{t(
								"pages.preferencesPages.screenDirectory.carModelsAndBrands.content.str200",
							) ?? ""}
							.
						</HeaderCell>
						<Cell
							style={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							{(rowData) => (
								<Column maxedHeight justify="center">
									<ToggleButton.ToggleButton
										displayDisabled={disabled}
										key={rowData.id}
										value={rowData?.active}
										onChange={(value) => {
											toggleModel(rowData.id, value);
										}}
									/>
								</Column>
							)}
						</Cell>
					</TableColumn>

					<TableColumn flexGrow={1} sortable>
						<HeaderCell verticalAlign="middle">
							{t(
								"pages.preferencesPages.screenDirectory.carModelsAndBrands.content.str100",
							) ?? ""}
						</HeaderCell>
						<Cell
							handleEdit={(rowData) => {
								if (!rowData.native) onEdit(rowData.id);
							}}
							handleClick={(value) => {
								handleSelect(value);
							}}
							dataKey="name"
						>
							{(rowData) => (
								<StyledCell
									active={rowData.active && !disabled}
								>
									{rowData?.name}
								</StyledCell>
							)}
						</Cell>
					</TableColumn>

					<TableColumn flexGrow={1} sortable>
						<HeaderCell verticalAlign="middle">
							{t(
								"pages.preferencesPages.screenDirectory.carModelsAndBrands.content.str150",
							) ?? ""}
						</HeaderCell>
						<Cell
							handleEdit={(rowData) => {
								if (!rowData.native) onEdit(rowData.id);
							}}
							handleClick={(value) => {
								handleSelect(value);
							}}
							dataKey="bodyType"
						>
							{(rowData) => (
								<StyledCell
									active={rowData.active && !disabled}
								>
									{rowData?.bodyType?.name}
								</StyledCell>
							)}
						</Cell>
					</TableColumn>
				</Table>
			</div>
		);
	},
);

export default Content;
