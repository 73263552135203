import makeTable from "./makeTable";

const settingsOrderExchangeMain = makeTable({
	allIds: [
		"filFrom",
		"keyFrom",

		"filTo",
		"keyTo",
		"orderReceiving",
		"orderSending",
		"orderReceivingPartnerProgram",
		"orderSendingPartnerProgram",
	],
	defaultIds: [
		"filFrom",
		"keyFrom",

		"filTo",
		"keyTo",
		"orderReceiving",
		"orderSending",
		"orderReceivingPartnerProgram",
		"orderSendingPartnerProgram",
	],
	i18tPath: (id) => `settings.orderExchange.main.columns.${id}`,
	initialSortMethod: {
		column: "filTo",
		type: "asc",
	},
	initialWidths: {
		filFrom: 170,
		keyFrom: 120,

		filTo: 170,
		keyTo: 120,
		orderReceiving: 280,
		orderSending: 280,
		orderReceivingPartnerProgram: 280,
		orderSendingPartnerProgram: 280,
	},
	initialResizable: {
		filFrom: true,
		keyFrom: true,

		filTo: true,
		keyTo: true,
		orderReceiving: true,
		orderSending: true,
		orderReceivingPartnerProgram: true,
		orderSendingPartnerProgram: true,
	},
	initialSortable: {
		filFrom: true,
		keyFrom: true,

		filTo: true,
		keyTo: true,
		orderReceiving: true,
		orderSending: true,
		orderReceivingPartnerProgram: true,
		orderSendingPartnerProgram: true,
	},
});

export default settingsOrderExchangeMain;
