/* eslint-disable no-shadow */
import * as ModelEvent from "@node-elion/syncron";

import SubscriptionPool from "../../redux/services/SubscriptionPool";
import createLogger from "../../utils/logger.util";
import { NonEditableProperties } from "../../types/NonEditableProperties";
import OptionsBase from "../../types/ServiceSubscribeOptionsBase";
import Subscription from "../../types/Subscription";
import { TaxiServiceOld as TaxiService, Base, Language } from "..";

import {
	CheckSmsStatusMode,
	DestAddrNpi,
	DestAddrTon,
	SmsProviderType,
	SourceAddrNpi,
	SourceAddrTon,
} from "./types";

const logger = createLogger({ name: "SMSProvider" });

class SMSProvider extends Base {
	public static fromResponse(data: any): SMSProvider.Model {
		const taxiServices = data.smsProviderToTaxiServices.flatMap((item) =>
			TaxiService.fromResponse(item.taxiService),
		);

		return {
			id: data.id,

			name: data.name,
			active: data.active,
			addPlus: data.addPlus,
			password: data.password,
			maxSmsPerSecond: data.maxSmsPerSecond,
			isMaxSmsPerSecond: data.isMaxSmsPerSecond,
			activeQueryInterval: data.activeQueryInterval,
			checkSmsStatusMode: data.checkSmsStatusMode,
			sourceAddrTon: data.sourceAddrTon,
			sourceAddrNpi: data.sourceAddrNpi,
			isEnquireLink: data.isEnquireLink,
			isSystemType: data.isSystemType,
			systemType: data.systemType,
			enquireLink: data.enquireLink,
			destAddrTon: data.destAddrTon,
			destAddrNpi: data.destAddrNpi,
			alphaName: data.alphaName,
			login: data.login,
			port: data.port,
			host: data.host,
			type: data.type,
			taxiServices,

			createdAt: data.createdAt,
			updatedAt: data.updatedAt,
			deletedAt: data.deletedAt,
		};
	}

	public static toRequest(model: SMSProvider.Model) {
		return { id: model.id };
	}

	public static async getAll(): Promise<SMSProvider.Model[] | Error> {
		try {
			const res = await this.request((prpc) =>
				prpc.theirsModel.sms.provider.getAll(),
			);
			return res?.items?.map(this.fromResponse);
		} catch (error) {
			return error as Error;
		}
	}

	public static async update(object: SMSProvider.Model) {
		try {
			await this.request((prpc) =>
				prpc.theirsModel.sms.provider.update(
					object.id,
					this.toRequest(object),
				),
			);
		} catch (error) {
			return false;
		}
		return true;
	}

	public static async subscribe(
		options: SMSProvider.SubscribeOptions,
		onUpdate: Subscription.OnUpdate<SMSProvider.Model>,
	): Promise<Subscription<SMSProvider.SubscribeOptions> | null> {
		const modelEventConstructor = new ModelEvent.ModelEventConstructor({
			onUpdate: (state) => {
				onUpdate({
					...state,
					models: state.models.map(this.fromResponse),
				});
			},
		});

		const subscription = await SubscriptionPool.add(
			(prpc) =>
				prpc.theirsModel.sms.provider.subscribe({
					params: options,
					ping: () => true,
					onEvent: async (events) => {
						await modelEventConstructor.onEvent(events);
					},
					onError: (error) => {
						logger.error(error);
					},
				}),
			{ name: "SMSProvider.subscribe" },
		);

		return {
			unsubscribe: () => subscription.unsubscribe(),
			update: (options: SMSProvider.SubscribeOptions) =>
				subscription.update(options),
		};
	}
}

declare namespace SMSProvider {
	interface Model extends NonEditableProperties {
		name: string;
		active: boolean;
		addPlus: boolean;
		password: string;
		activeQueryInterval: number;
		taxiServices: TaxiService.Model[];
		type: SmsProviderType;
		host: string;
		port: number;
		alphaName: string;
		login: string;
		isSystemType: boolean;
		systemType: string;
		sourceAddrTon: SourceAddrTon;
		sourceAddrNpi: SourceAddrNpi;
		destAddrTon: DestAddrTon;
		destAddrNpi: DestAddrNpi;
		isEnquireLink: boolean;
		enquireLink: number;
		checkSmsStatusMode: CheckSmsStatusMode;
		isMaxSmsPerSecond: boolean;
		maxSmsPerSecond: number;
	}

	interface SubscribeOptions extends OptionsBase<SMSProvider.Model> {
		active?: boolean;
		lang?: Language;
		type?: SmsProviderType;
		taxiServiceIds?: number;
	}
}

export default SMSProvider;
