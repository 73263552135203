import React, { Suspense, useMemo, useEffect, lazy } from "react";
import { useTranslation } from "react-i18next";

import { Dispatcher2 } from "../../../../services";
import { useTypedSelector, useTypedDispatch } from "../../../../redux/store";
import account from "../../../../redux/reducers/account";
import { useActiveTab, useModelSubscribe } from "../../../../hooks";
import BasicPageLayout from "../../../../components/BasicPageLayout";
import { hasAccess } from "../../../../access";
import { TabOptions } from "../../../../types";
import {
	StyledRow,
	SuspenseLoader,
	SideTab,
} from "../../../../components/common";
import { ExecutorTeamsProvider } from "../context";

import { TabKeys, TabAccessNames } from "./constants/access";

const LazyTransport = lazy<React.FC<AccountsPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Transport" /* webpackChunkName: "accounting-page-transport" */
	);
	return { default: elem.default };
});

const LazyUsers = lazy<React.FC<AccountsPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Users" /* webpackChunkName: "accounting-page-users" */
	);
	return { default: elem.default };
});

const LazyRoles = lazy<React.FC<AccountsPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Roles" /* webpackChunkName: "accounting-page-roles" */
	);
	return { default: elem.default };
});

const LazyCarParks = lazy<React.FC<AccountsPage.Props>>(async () => {
	const elem = await import(
		"./tabs/CarParks" /* webpackChunkName: "accounting-page-car-parks" */
	);
	return { default: elem.default };
});

const LazyWorkShifts = lazy<React.FC<AccountsPage.Props>>(async () => {
	const elem = await import(
		"./tabs/WorkShifts" /* webpackChunkName: "accounting-page-work-shifts" */
	);
	return { default: elem.default };
});

const LazyExecutors = lazy<React.FC<AccountsPage.Props>>(async () => {
	const elem = await import(
		"./tabs/Executors" /* webpackChunkName: "accounting-page-executors" */
	);
	return { default: elem.default };
});

const LazyExecutorTeams = lazy<React.FC<AccountsPage.Props>>(async () => {
	const elem = await import(
		"./tabs/ExecutorTeams" /* webpackChunkName: "accounting-page-executor-teams" */
	);
	return { default: elem.default };
});

const AccountsPage: React.FC = () => {
	const { t } = useTranslation();
	const dispatch = useTypedDispatch();

	const personalRole = useTypedSelector(
		(state) => state.account.personalRole,
	);
	const lang = useTypedSelector((state) => state.session.language);
	const user = useTypedSelector((state) => state.account.user);
	const dispatcher = useModelSubscribe({ lang }, Dispatcher2);

	const dispatcherServices = useMemo(
		() => dispatcher.models?.find((item) => item.id === user?.id),
		[dispatcher.models, user?.id],
	);

	useEffect(() => {
		if (dispatcherServices && user?.id) {
			dispatch(account.actions.setUser(dispatcherServices));
		}
	}, [dispatcherServices, dispatch, user?.id]);

	const tabs: TabOptions.Array = useMemo(
		() =>
			[
				{
					key: TabKeys.EXECUTORS,
					label: t("mainPage.accounts.executors.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyExecutors />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.EXECUTORS],
				},

				{
					key: TabKeys.TRANSPORT,
					label: t("mainPage.accounts.transport.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyTransport />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.TRANSPORT],
				},

				{
					key: TabKeys.USERS,
					label: t("mainPage.accounts.users.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyUsers />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.USERS],
				},
				{
					key: TabKeys.ROLES,
					label: t("mainPage.accounts.roles.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyRoles />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.ROLES],
				},

				{
					key: TabKeys.CAR_PARKS,
					label: t("mainPage.accounts.carParks.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyCarParks />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.CAR_PARKS],
				},
				{
					key: TabKeys.SCHEDULES,
					label: t("mainPage.accounts.schedules.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<LazyWorkShifts />
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.SCHEDULES],
				},

				{
					key: TabKeys.EXECUTOR_TEAMS,
					label: t("mainPage.accounts.executorTeams.title"),
					value: {
						render: () => (
							<Suspense
								fallback={
									<StyledRow
										position="absolute"
										top="50%"
										left="50%"
									>
										<SuspenseLoader />
									</StyledRow>
								}
							>
								<ExecutorTeamsProvider>
									<LazyExecutorTeams />
								</ExecutorTeamsProvider>
							</Suspense>
						),
					},
					accessName: TabAccessNames[TabKeys.EXECUTOR_TEAMS],
				},
			].filter((btn) => hasAccess(btn.accessName, personalRole)),
		[t, personalRole],
	);

	const { activeKey, activeTab, setActiveKey } = useActiveTab(tabs);

	return (
		<BasicPageLayout
			navigation={
				<SideTab
					value={activeKey}
					onChange={setActiveKey}
					options={tabs}
					variant="vertical"
				/>
			}
			content={activeTab.value.render()}
		/>
	);
};

declare namespace AccountsPage {
	interface Props {}
}

export default AccountsPage;
