import { createObjectFromConstant } from "../../../../../assets/languages/langs";
import ExecutorGroup, {
	ShowCustomerPhoneNumbersType,
} from "../../../../../services/ExecutorGroup";

const defaultExecutorTeam: Omit<ExecutorGroup.Model, "id"> & { id?: number } = {
	name: createObjectFromConstant(),
	active: true,
	default: false,
	executorIds: [],
	taxiServiceIds: [],
	configuration: {
		enableReOffer: false,
		displayRequiredOffersInLiveTabAfterCancel: false,
		displayRequiredOffersInLiveTabAfterExpire: false,
		displayFreeWayOffersInLiveTabAfterCancel: false,
		displayFreeWayOffersInLiveTabAfterExpire: false,
		returnToParkingOnMandatoryOrderRefusal: false,
		returnToParkingOnFreeWaveOrderRefusal: false,
		returnToParkingOnMandatoryOrderAfterExpire: false,
		returnToParkingOnDispatcherRemovalFromMandatoryOrder: false,
		returnToParkingOnOrderCancellation: false,

		allowExecutorParking: true,
		allowExecutorParkingByGeolocation: false,
		allowParkingWithDebtStatus: false,
		allowParkingWithLunchStatus: false,
		allowParkingWithHomeStatus: false,
		allowParkingWithBusyStatus: false,

		autoAssignToParkingAfterOrderClosure: false,
		suggestParkingAfterOrderClosure: false,
		autoParkWhenInParkingZone: false,

		suggestParkingByGPSAfterShiftStart: false,
		autoLeaveParkingWhenOutOfZone: false,
		restrictParkingUntilAllOrdersClosed: false,
		excludePreliminaryOrder: false,
		excludeOwnOrder: false,

		showOwnOrdersInBroadcastForParkingOnly: false,
		showOwnPreOrdersInBroadcastForParkingOnly: false,
		showMarketOrdersInBroadcastForParkingOnly: false,
		showMarketPreOrdersInBroadcastForParkingOnly: false,

		showOrdersInBroadcastForOnOrderStatus: true,
		showOrdersInBroadcastForOwnOrderStatus: true,
		showOrdersInBroadcastForDebtStatus: true,
		showOrdersInBroadcastForLunchStatus: true,
		showOrdersInBroadcastForHomeStatus: true,
		showOrdersInBroadcastForBusyStatus: true,
		showExecutorsPreOrdersInBroadcast: true,

		allowTakingOrdersWithOnOrderStatus: true,
		allowTakingOrdersWithOwnOrderStatus: true,
		allowTakingOrdersWithDebtStatus: true,
		allowTakingOrdersWithLunchStatus: true,
		allowTakingOrdersWithHomeStatus: true,
		allowTakingOrdersWithBusyStatus: true,
		allowTakingPreOrdersInBroadcast: true,
	},
	showCustomerPhoneNumbers: {
		active: true,
		type: ShowCustomerPhoneNumbersType.Always,
		conditions: {
			showManually: false,
			afterArrivedMessage: false,
			afterWaitingCustomerMessage: false,
		},
		beforeArrivalTime: {
			active: false,
			threshold: 0,
		},
		afterAcceptingOrder: {
			active: false,
			threshold: 0,
		},
	},
	allowBeforeCompletion: {
		active: false,
		maxOrders: 0,
		ignorePreliminary: false,
		completionSources: [],
	},

	taxiServices: [],
	companyIds: [],
	id: undefined,
	executorAppId: 0,
	priority: 0,
	isAllowToSelectCarClassesInApp: false,
};

export default defaultExecutorTeam;
